import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import {auth} from 'Auth/_store/reducer';
import {business} from 'Business/_store/reducer';
import {profile} from 'Profile/_store/reducer';
import {admin} from 'Administration/Users/_store/reducer';
import {notification} from 'CommonUI/Notification/_store/reducer';
import {basket} from  'Basket/_store/reducer';
import {order} from  'Orders/_store/reducer';
import {breadcrumbs} from 'CommonUI/Breadcrumb/reducer';
const appReducer = combineReducers({
    auth,
    business,
    profile,
    notification,
    admin,
    basket,
    breadcrumbs,
    order,
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        state = undefined
    }
    return appReducer(state, action)
};

const newStore = (preLoadedState) => createStore(
    rootReducer,
    preLoadedState,
    applyMiddleware(thunk)
);

export default newStore;
