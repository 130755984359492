import React from "react";
import {Route, Switch} from "react-router";
import {connectedRoutes} from "Layout/Connected/constants";
import Categories from "./Categories";
import Catalogue from "./Catalogue";
import Product from "./Product";
import Cart from "./Cart";
import SearchResult from "./Product/SearchResult";

export default (
    <Switch>
        <Route path={`${connectedRoutes.BUSINESS}/:id/product/:productId`} component={Product} />
        <Route path={`${connectedRoutes.BUSINESS}/:id/catalogue/:subcategoryId`} component={Catalogue} />
        <Route path={`${connectedRoutes.BUSINESS}/:id/cart`} component={Cart} />
        <Route path={`${connectedRoutes.BUSINESS}/:id/search-products-result`} component={SearchResult} />
        <Route path={`${connectedRoutes.BUSINESS}/:id`} component={Categories} />
    </Switch>
);
