import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';

export default ({history}) => {
    const {t} = useTranslation('orders');
    const status = {
        valid: 'Validée',
        pending: 'En attente',
        partiallyClosed: 'Partiellement réceptionnée',
    }

    let getLabelByStatus = (item, label) => {
        return (label === status.partiallyClosed || label.includes(status.partiallyClosed)) && ` (${item.received_quantity} - ${item.received_product})`;
    }

    return (
        <div className="history">
            { history ?
                history.map((item) => {
                    return (
                        <div className="history-row" key={Math.random() * 101}>
                        <div>
                            <span>{item.prettyDate}</span>
                        </div>
                        <div className="row">
                            <div className="col-1 time">{item.prettyTime}</div>
                            <div className="col-9">
                                {t('order_status.' + item.status)}
                                <b>
                                    {getLabelByStatus(item, item.status)}
                                </b>
                            </div>
                        </div>
                    </div>);
                }) : <div/>
            }

        </div>
    );
};
