import React from 'react';
import PropTypes from 'prop-types';
import Button from 'CommonUI/Button';
import { withTranslation } from 'react-i18next';
import { connectedRoutes } from 'Layout/Connected/constants';
import { NavLink } from 'react-router-dom';
import { history } from '../_helpers/history';
import './style.scss';

class CartRow extends React.Component {

    render() {
        const {t, item} = this.props;

        return (
            <tr className="onClickEvent" onClick={()=>{ history.push(`${connectedRoutes.BUSINESS}/${item.business.id}/cart`)}}>
                <td data-label={t('list.business')}>{item.business.name}</td>
                <td data-label={t('list.date')}>{item.business.creation_date}</td>
                <td data-label={t('list.location')}>{item.business.location}</td>
                <td data-label={t('list.quantity')}>{item.cart.nb_products}</td>
                <td className="actions">
                    <NavLink to={`${connectedRoutes.BUSINESS}/${item.business.id}/cart`}>
                        <Button className="action circle" icon="preview"/>
                    </NavLink>
                </td>
            </tr>
        );
    }
}

CartRow.propTypes = {
    item: PropTypes.object.isRequired
};

export default withTranslation('basket')(CartRow);
