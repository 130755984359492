import { httpService } from '../../_network/http/service';
import Progress from 'react-progress-2';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import { notificationConstants } from 'CommonUI/Notification/_store/constants';
import { basketActions } from './actions';

class BasketServices {

    /**
     *
     * @param httpService
     */
    constructor(httpService) {
        this.httpService = httpService;
    }

    getList() {
        return this.httpService.get('/cart/list');
    }

    fillCountOfCarts(dispatch) {
        Progress.show();
        let countOfCarts = 0;
        this.getList()
            .then(response => {
                if (response && response.type === notificationConstants.SUCCESS_RESPONSE) {
                    for (let i = 0; i < response.result.length; i++) {
                        if (response.result[i].cart.nb_products !== 0) {
                            countOfCarts += 1;
                        }
                    }

                    dispatch(basketActions.updateCountOfCarts(countOfCarts));
                } else {
                    dispatch(notificationActions.add(response));
                }

                Progress.hide();
            });
    }
}

export const basketServices = new BasketServices(httpService);
