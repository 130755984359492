export const connectedRoutes = {
    DASHBOARD: "/dashboard",
    BUSINESS: "/dashboard/mes-affaires",
    ORDER: "/dashboard/mes-commandes",
    STATISTICS: "/dashboard/statistiques",
    BASKET: "/dashboard/mes-paniers",
    USER: "/dashboard/mon-compte",
    USERS_LIST: "/dashboard/users",
    ORDER_ARCHIVES: "/dashboard/mes-archives-commandes",
    PUNCHOUT: '/dashboard/punchout',
};
