import React from 'react';
import { Chooser } from 'CommonUI/FormFields';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Button from 'CommonUI/Button';
import CustomDateRange from 'CommonUI/CustomDateRange';
import { orderServices } from '../_store/services';

class Filters extends React.Component {

    constructor(props) {
        super(props);
        const {t} = this.props;

        this.state = {
            filter: {
                status: {id: 0, name: t('layout:all')},
                supplier: {id: 0, name: t('layout:all')},
                startDate: null,
                endDate: null,

            },
            statuses: null,
            suppliers: null,
            showDateRange: false
        };

        orderServices.getStatuses().then(
            (response) => {
                this.setState({
                    statuses: response.result.statuses
                })
            }
        );
        orderServices.getSuppliers().then(
            (response) => {
                let finalResult = [];
                response.result.forEach((item, index) => {
                    finalResult.push(item.supplier);
                });

                this.setState({
                    suppliers: finalResult
                })
            }
        )
    }


    handleChooserChange = (name, option) => {
        let {filter} = this.props;
        filter = {
            ...filter,
            [name]: option,
        };
        this.setState({filter});
    };


    selectDate = (startDate, endDate) => {
        let filter = this.state.filter;
        filter.startDate = startDate;
        filter.endDate = endDate;
        this.setState({filter});
    };

    handleSubmit = (values, {setSubmitting}) => {
        this.setState({
            showDateRange: false,
        });
        const {fetchData} = this.props;
        let filter = this.createFilters(values);
        fetchData({filter}).then(
            () => {
                setSubmitting(false);
                // don't hide filter and don't clear the inputs
                // toggleFilter();
            }
        );
    };


    updateDateChange = (showDateRange) => {
        this.setState({
            showDateRange,
        });
    }


    createFilters() {
        let filter = {};
        const {filter: {status, supplier, startDate, endDate}} = this.state;

        if (status && status.id !== 0) {
            filter.status = [status.id];
        }

        if (supplier && supplier.id !== 0) {
            filter.supplier = supplier.id;
        }

        if (startDate) {
            filter.start_date = startDate;
        }

        if (endDate) {
            filter.end_date = endDate;
        }

        return filter;
    }

    render() {
        const {t} = this.props;
        const {filter: {status, supplier, startDate, endDate}, showDateRange} = this.state;

        let statusOptions = [];
        let supplierOptions = [];
        if (this.state.statuses && this.state.suppliers) {
            statusOptions = [{id: 0, name: t('layout:all')}, ...this.state.statuses];
            supplierOptions = [{id: 0, company: t('layout:all')}, ...this.state.suppliers];
        }
        return (
            <Formik
                onSubmit={this.handleSubmit}
                enableReinitialize={true}
                initialValues={{status, supplier, startDate, endDate}}
                render={({errors, touched, isSubmitting}) => (
                    <Form className="row">
                        <div className="col-md-3 filters__field responsive-width">
                            <Chooser
                                name="status"
                                label={t('status')}
                                options={statusOptions}
                                value={status}
                                onChange={(option) => this.handleChooserChange('status', option)}
                                getOptionLabel={({name}) => name}
                                getOptionValue={({id}) => id}
                                error={errors.name}
                                touched={touched.name}
                            />
                        </div>

                        <div className="col-md-4 filters__field responsive-width">
                            <CustomDateRange
                                showDateRangeHandler={this.updateDateChange}
                                showDateRange={showDateRange}
                                onChange={this.selectDate}
                                label={t('date')}
                                placeholder={t('layout:placeholder.date_range')}
                            />
                        </div>

                        <div className="col-md-3 filters__field responsive-width">
                            <Chooser
                                name="supplier"
                                label={t('supplier')}
                                options={supplierOptions}
                                value={supplier}
                                onChange={(option) => this.handleChooserChange('supplier', option)}
                                getOptionLabel={({company}) => company}
                                getOptionValue={({id}) => id}
                                error={errors.supplier}
                                touched={touched.supplier}
                            />
                        </div>

                        <div className="col-md-2 filters__actions responsive-width">
                            <Button
                                className="block"
                                isLoading={isSubmitting}
                                label={t('orders:submit')}
                                type="submit"
                            />
                        </div>
                    </Form>
                )}
            />
        );
    }
}

export default connect((state) => {
    const {openedFilters} = state.business;
    return {
        openedFilters,
    };
})(withTranslation('orders')(Filters));

