import {basketConstants} from "./constants";

class BasketActions {
    updateCountOfCarts(item) {
        return ((dispatch) => {
            return dispatch({type: basketConstants.UPDATE_COUNT_OF_CARTS, item});
        });
    }
}

export const basketActions = new BasketActions();
