import React, { Component } from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CartsList from './CartsList';
import { ResponsiveContext } from '../_store/responsive-context';
import { Screen } from '../_constants';
import Button from '../CommonUI/Button';
import { PageHead, PageHeadActions, PageHeadTitle } from '../CommonUI/PageHead';
import Modal from '../CommonUI/Modal';
import SelectSupplierModal from './SelectSupplierModal';
import { notificationConstants } from 'CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';


class Basket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            buttonActive: true,
        };
    }

    componentDidMount() {
        const response = JSON.parse(sessionStorage.getItem('business'));

        if (response && response.result && response.result.length) {
            this.setState({buttonActive: true});
            this.setState({business: response.result});
        } else {
            this.setState({buttonActive: false});
        }
    }

    openModal = () => {
        const {t, dispatch} = this.props;
        if (!this.state.buttonActive) {
            const response = {
                type: notificationConstants.ERROR_RESPONSE,
                result: t('empty_business')
            };

            dispatch(notificationActions.add(response, t('empty_business')));
        } else {
            this.setState({
                showModal: true,
            });
        }
    }

    closeModal = () => {
        this.setState({
            showModal: false,
        });
    }

    render() {
        const {t, dispatch} = this.props;
        const {showModal, business} = this.state;

        return (
            <ResponsiveContext.Consumer>
                {({size}) => (

                    <div className="page page--basket bg-white">
                        <PageHead>
                            <PageHeadTitle>
                                <h2>{t('main_title')}</h2>
                            </PageHeadTitle>
                            <PageHeadActions>
                                <p>
                                    {(size >= Screen.md) && <Button
                                        className="primary round"
                                        label={t('import_basket')}
                                        onClick={this.openModal}
                                    />}
                                    {(size < Screen.md) && <Button
                                        className="primary round"
                                        label="+"
                                        onClick={this.openModal}
                                    />}
                                </p>
                            </PageHeadActions>
                        </PageHead>
                        <div className="container">
                            <CartsList/>
                        </div>
                        <Modal
                            className="punchout-import-form"
                            showModal={showModal}
                            title={t('select_supplier')}
                            children={<SelectSupplierModal
                                closeModal={this.closeModal}
                                showModal={showModal}
                                business={business}
                                dispatch={dispatch}
                            />}
                            onClose={this.closeModal}
                        />
                    </div>
                )}
            </ResponsiveContext.Consumer>
        );
    }
}

export default connect()(withTranslation('basket')(Basket));
