import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from "react-redux";

class PublicRoute extends React.Component {
    render() {
        const {loggedIn} = this.props;

        if (loggedIn) {
            return <Redirect to={{pathname: '/dashboard/mes-affaires', state: {from: this.props.location}}}/>;
        }

        return <Route {...this.props} />;
    }
}

export default connect(
    (state) => {
        const {loggedIn} = state.auth;
        return {
            loggedIn: loggedIn
        };
    }
)(PublicRoute);
