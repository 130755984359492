const rules = {
    user: {
        static: [],
        dynamic: []
    },
    manager: {
        static: [
            "users:list"
        ],
        dynamic: {
            "users:delete": ({userToRemoveRole}) => {
                //managers can remove only standard users
                return userToRemoveRole === 'user';
            }
        }
    }
};

export default rules;
