import * as Yup from 'yup';
import _ from 'lodash';

const schema = Yup.object().shape({
    products: Yup.array()
        .of(
            Yup.object().shape({
                quantity: Yup.number().typeError("layout:form.error.number_required").min(0, "layout:form.error.quantity_greater_than_0")
            })
        ).test('at-least-one-quantity', "orders:form.error.one_quantity_at_least", (products) => {
            for (let item in products) {
                let product = products[item];
                if (_.has(product, 'quantity') && product.quantity > 0) {
                    return true;
                }
            }
            return false;
        })
        .test('maximum-quantity', '', (products) => {
            let error = null;
            const errorMessage = "orders:form.error.allowed_quantity_exceeded";
            products.forEach((product, index) => {
                if (product.ordered_quantity < product.quantity + product.already_received_quantity) {
                    error = new Yup.ValidationError(errorMessage, product.quantity, 'products[' + index + '].quantity');
                }
            });
            return error ? error : true;
        })
});

export default schema;
