import {authConstants} from "./constants";
import cookies from "react-cookies";

let user = cookies.load('user');

const initialState = user ? {loggedIn: true, route: ''} : {route: ''};

export const auth = (state = initialState, action) => {

    let response = {};

    switch (action.type) {
        case authConstants.LOGIN_SUCCESS:
            response = {
                ...state,
                loggedIn: true
            };
            break;
        case authConstants.LOGOUT:
            response = {
                ...state,
                loggedIn: false
            };
            break;
        case authConstants.ROUTE_NOT_ALLOWED:
            response = {
                ...state,
                route: action.route
            };
            break;
        default:
            response = state;
            break;
    }

    return response;
};
