import React, { Fragment } from 'react';
import './List/style.scss';
import { withTranslation } from 'react-i18next';
import routes from './routes';
import { connect } from 'react-redux';
import { businessActions } from './_store/actions';

class Business extends React.Component {

    constructor(props) {
        super(props);
        this.dispatch = props.dispatch;
    }

    componentDidMount() {
        if (this.props.businessState && this.props.businessState.list == null) {
            this.dispatch(businessActions.getList({}))
        }
    }

    render() {
        return (
            <Fragment>
                {routes}
            </Fragment>
        );
    }
}

export default connect((state) => {
    const {business} = state;
    return {businessState: business}
})(withTranslation('business')(Business));
