import React from "react";
import {authActions} from "../Auth/_store/actions";
import {Route, Redirect} from 'react-router-dom';
import {connect} from "react-redux";

class PrivateRoute extends React.Component {
    render() {

        let {loggedIn, dispatch, location} = this.props;

        if (!loggedIn) {
            dispatch(authActions.saveRoute(location.pathname));
            return <Redirect to={{pathname: '/auth/connexion', state: {from: this.props.location}}}/>;
        }

        return <Route {...this.props} />;
    }
}

export default connect(
    (state) => {
        const {loggedIn} = state.auth;

        return {
            loggedIn
        }
    }
)(PrivateRoute);
