import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';

export default () => {
    const { t } = useTranslation('statistics');

    return (
        <div className="page page--statistics">
            <div className="content">
                <h2 className="page-title">{ t('Statistiques') }</h2>
            </div>
        </div>
    );
}
