/**
 * Convert Date object to dd/mm/yyyy
 *
 * @param date
 * @returns {string}
 */
export function convertDateToString(date) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    return [pad(date.getDate()), pad(date.getMonth()+1), date.getFullYear()].join('/');
}
/**
 * Convert dd/mm/yyyy string to Date object
 *
 * @param string
 * @returns {Date}
 */
export function convertStringToDate(string){
    var from = string.split('/')
    return new Date(from[2], from[1] - 1, from[0])
}