import { profileConstants } from "./constants";

import cookies from "react-cookies";

let user = cookies.load('user');

const initialState = user ? {user} : {user: null};

export const profile = (state = initialState, action) => {
    let response = {};

    switch (action.type) {
        case profileConstants.SET_PROFILE:
            response = {
                ...state,
                user: action.user
            };
            break;
        default:
            response = state;
            break;
    }

    return response;
};
