import React from 'react';
import '../style.scss';
import _ from 'lodash';
import { TextInput } from 'CommonUI/FormFields';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { Screen } from '../../../_constants';

export default ({item, index, errors, touched}) => {
    const {t} = useTranslation('orders');
    const [size, updateSize] = React.useState(window.outerWidth);

    React.useEffect(() => {
        function handleResize() {
            updateSize(window.outerWidth);
        }
        window.addEventListener('resize', handleResize);
    });

    return (
        <div className="row">
            {size >= Screen.md &&
                <div className="col-6 col-md-4">
                    <span className="name">{item.name}</span>
                    <span className="reference">{t('ref_fab')}: {item.reference}</span>
                    <span className="brand">{t('brand')}:{item.brand}</span>
                </div>
            }
            {size < Screen.md &&
                <>
                    <div className="col-6">{t('name')}:</div>
                    <div className="col-6">{item.name}</div>
                    <div className="col-6">{t('ref_fab')}:</div>
                    <div className="col-6">{item.reference}</div>
                    <div className="col-6">{t('brand')}:</div>
                    <div className="col-6">{item.brand}</div>
                </>
            }
            {size < Screen.md &&
                <div className="col-6">
                    {t('ordered_quantity')}
                </div>
            }
            <div className="col-6 col-md-3">
                <Field className="span-input" disabled name={`products.${index}.ordered_quantity`}/>
            </div>

            {size < Screen.md &&
                <div className="col-6">
                    {t('already_received_quantity')}
                </div>
            }
            <div className="col-6 col-md-3">
                <Field className="span-input" disabled name={`products.${index}.already_received_quantity`}/>
            </div>
            {size < Screen.md &&
                <div className="col-6">
                    {t('received_quantity')}
                </div>
            }
            <div className="col-6 col-md-2 received-quantity">
                <TextInput
                    type="text"
                    name={`products.${index}.quantity`}
                    error={_.isArray(errors.products) && errors.products[index] ? t(errors.products[index].quantity) : ''}
                    touched={_.isArray(touched.products) && touched.products[index] ? touched.products[index].quantity : false}
                />
                <TextInput
                    type="hidden"
                    name={`products.${index}.id`}
                />
            </div>
        </div>
    );
}