import React, { Fragment } from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { businessServices } from '../../_store/services';
import { NavLink } from 'react-router-dom';
import { connectedRoutes } from '../../../Layout/Connected/constants';
import Progress from 'react-progress-2'
import { notificationConstants } from 'CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import Button from '../../../CommonUI/Button';
import _ from 'lodash';
import { breadcrumbActions } from '../../../CommonUI/Breadcrumb/actions';
import CheckboxTree from 'react-checkbox-tree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faChevronRight, faChevronDown, faPlusSquare, faMinusSquare, faFolderOpen, faFolder, faFile, faSquare }
from '@fortawesome/free-solid-svg-icons'
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Icon from '../../../CommonUI/Icon';
// isamoil
// bad structure, functional, states and props. Need to change this component. I fixed this component for work, but I want that will be temporary
class Catalogue extends React.Component {
    
    constructor(props) {
        super(props);
        this.dispatch = props.dispatch;
        this.state = {
            products: null,
            loadingProducts: true,
            initialProductsLoaded: false,
            selectedProducts: [],
            productsPerSubcategory: {},
            searchedParams: {},
            offset: 0,
            limit: 100,
            totalProducts: 0,
            currentCountOfProducts: 0,
            subCategoryId: props.location.subCategoryId,
            checked: [],
            expanded: [],
        };
        const {dispatch, match, t} = this.props;
        dispatch(breadcrumbActions.push(<NavLink to={`${connectedRoutes.BUSINESS}/${match.params.id}`}>{t('catalogue.title')}</NavLink>));
        this.categories = null;
        this.onCheck = this.onCheck.bind(this);
        this.onExpand = this.onExpand.bind(this);
    }
    
    onCheck(checked) {
        this.setState({ checked });
        this.loadProducts(checked);
    }
    
    onExpand(expanded) {
        this.setState({ expanded });
    }
    
    buildCategoryTree(o) {
        const recursion = o => {
            if(o.children === null) {
                this.state.checked.push(o.id);
            } else {
                this.state.expanded.push(o.id);

                o.children.forEach(recursion);
            }
        }
        recursion(o);
        
        return true;
    }
    
    initProductList() {
        if (this.subCategoryIsValid() && !this.state.initialProductsLoaded) {
            this.setState({initialProductsLoaded: true});
            this.loadProducts(this.state.checked);
        }
        if (this.state.subCategoryId && !this.state.initialProductsLoaded) {
            this.setState({initialProductsLoaded: true});
            this.loadProducts(this.state.checked);
        }
    }

    componentDidMount() {
        this.initProductList();
    }

    componentWillUnmount() {
        const {dispatch} = this.props;
        dispatch(breadcrumbActions.pop());
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.buildCategoryTree(nextProps.chosenSubcategory);
        this.initProductList();
    }

    toggleSubcategory = () => {
        let subcategoryIds = [];
        document.querySelectorAll("input[name='chosenCategories']:checked").forEach((item, index) => {
            subcategoryIds.push(item.value);
        });

        this.loadProducts(subcategoryIds);
    };

    loadProducts = (subcategoryIds) => {
        if (_.isEmpty(subcategoryIds) === false) {
        if (subcategoryIds !== this.state.selectedSubcategoriesIds) {
            this.setState({
                offset: 0,
            })
        }
        Progress.show();
        this.setState({
            loadingProducts: true,
        });
        let data = {};
        data.filter = {};
        data.filter.subcategoryIds = subcategoryIds;

        businessServices.getProducts(data)
            .then(
                (response) => {
                    if (response) {
                        if (response.type === notificationConstants.SUCCESS_RESPONSE) {
                            let products = response.result[0];
                            if (products) {
                                this.setState({
                                    selectedSubcategoriesIds: subcategoryIds,
                                    searchedParams: data,
                                    totalProducts: response.result.totalProducts,
                                    currentCountOfProducts: products.length,
                                    selectedProducts: products
                                })
                            }
                        } else {
                            this.dispatch(notificationActions.add(response));
                        }
                        Progress.hide();
                    }
                });
        } else {
            this.setState({
                selectedSubcategoriesIds: [],
                totalProducts: 0,
                currentCountOfProducts: 0,
                selectedProducts: []
            })
        }

    };

    uploadNewProducts = () => {
        Progress.show();
        let newOffset = this.state.offset;
        newOffset += this.state.limit;
        // this.state.searchedParams.filter.offset = newOffset;
        this.setState({
            searchedParams: {
                ...this.state.searchedParams,
                filter: {
                    ...this.state.searchedParams.filter,
                    offset: newOffset,
                }
            }
        });
        let newSearchedParams = this.state.searchedParams;

        businessServices.getProducts(this.state.searchedParams).then(
            (response) => {
                if (response) {
                    if (response && response.type === notificationConstants.SUCCESS_RESPONSE) {
                        let currentCountOfProducts = this.state.currentCountOfProducts + response.result[0].length;
                        let selectedProducts = this.state.selectedProducts.concat(response.result[0]);
                        this.setState({
                            offset: newOffset,
                            searchedParams: newSearchedParams,
                            selectedProducts,
                            currentCountOfProducts
                        });
                        Progress.hide();
                    } else {
                        Progress.hide();
                        this.dispatch(notificationActions.add(response));
                    }
                }
            });
    };

    subCategoryIsValid() {
        return this.props.chosenSubcategory && (
            this.state.subCategoryId === this.props.chosenSubcategory.id ||
            (
                this.state.subCategoryId === undefined && this.props.chosenSubcategory.id !== null
            )
        );
    }
    
    render() {
        const { checked, expanded } = this.state;
        return (
            <Fragment>
                <div className="container row pb-0">
                    <div className="col-xs-12">
                        {
                            this.props.chosenBusiness && this.subCategoryIsValid() && this.props.chosenCategory &&
                            <Fragment>
                                <span className="color-gray">{this.props.t('welcome')} / </span>
                                <NavLink activeClassName="text-decoration-none"
                                         to={`${connectedRoutes.BUSINESS}/${this.props.chosenBusiness.id}`}
                                         className="color-20527a">
                                    {this.props.chosenBusiness.name}&nbsp;/&nbsp;
                                </NavLink>
                                <span className="color-20527a">{
                                    this.props.chosenCategory.label}
                                </span>
                            </Fragment>
                        }
                    </div>
                </div>
                <div className="container">
                    <div className="catalogue-container row">
                        {
                            this.props.chosenBusiness &&
                            this.props.chosenCategory &&
                            this.subCategoryIsValid() &&
                            this.props.chosenCategory.children.length > 0 &&
                            <div key={`cat${this.props.chosenCategory.id}`} className="subcategories-container">
                                <p className="title">{this.props.t('catalogue.categories')}</p>
                                <CheckboxTree
                                    nodes={this.props.chosenCategory.children}
                                    checked={checked}
                                    expanded={expanded}
                                    onCheck={this.onCheck}
                                    onExpand={this.onExpand}
                                    icons={{
                                        check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon={faCheckSquare} />,
                                        uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} />,
                                        halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon={faCheckSquare} />,
                                        expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon={faChevronRight} />,
                                        expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon={faChevronDown} />,
                                        expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon={faPlusSquare} />,
                                        collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon={faMinusSquare} />,
                                        parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" />,
                                        parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open"/>,
                                        leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" />
                                    }}
                                />
                                {/*{*/}
                                {/*    this.props.chosenCategory.children.map((subCategory) => {*/}
                                {/*        return (*/}
                                {/*            <div className="subcategory-item" key={`subcat${subCategory.value}`}>*/}
                                {/*                <label className="brand-secondary-color">*/}
                                {/*                    {*/}
                                {/*                        Number(subCategory.id) === Number(this.props.chosenSubcategory.id) && !Number.isNaN(Number(subCategory.value))*/}
                                {/*                            ?*/}
                                {/*                            <input type="checkbox"*/}
                                {/*                                   defaultChecked={true}*/}
                                {/*                                   name="chosenCategories"*/}
                                {/*                                   value={subCategory.id}*/}
                                {/*                                   onChange={this.toggleSubcategory}*/}
                                {/*                                   className="mr-2"/>*/}
                                {/*                            :*/}
                                {/*                            <input type="checkbox" name="chosenCategories"*/}
                                {/*                                   value={subCategory.id}*/}
                                {/*                                   onChange={this.toggleSubcategory}*/}
                                {/*                                   className="mr-2"/>*/}
                                {/*                    }*/}
                                {/*                    {subCategory.label}*/}
                                {/*                </label>*/}
                                {/*            </div>*/}
                                {/*        );*/}
                                {/*    })*/}
                                {/*}*/}
                            </div>
                        }
                        {
                            <div className="products-container">
                                {this.state.totalProducts !== 0 &&
                                <p className="title">{this.props.t('catalogue.product_list')} ({this.state.currentCountOfProducts} / {this.state.totalProducts})</p>
                                }
                                {
                                    this.subCategoryIsValid() && this.props.chosenBusiness &&
                                    this.state.selectedProducts.map((item, index) => {
                                        return (
                                            <div key={`prod${item.product.id}${index}`} className="product-item">
                                                {
                                                    <NavLink
                                                        to={`${connectedRoutes.BUSINESS}/${this.props.chosenBusiness.id}/product/${item.product.id}`}
                                                        className="product-link tooltip">
                                                        {item.product.name}
                                                        {item.product.supplier &&
                                                        <span className="">
                                                                &nbsp;&nbsp;({item.product.supplier})
                                                            </span>
                                                        }
                                                        {item.product.price &&
                                                            <span className="bold">
                                                                &nbsp;&nbsp;{item.product.price}€
                                                            </span>
                                                        }
                                                        
                                                        <span className="tooltiptext">{item.product.subcategory_name}</span>
                                                    </NavLink>
                                                }
                                            </div>
                                        );
                                    })
                                }

                            </div>

                        }
                    </div>
                    {this.state.totalProducts > 100
                    && this.state.totalProducts !== this.state.currentCountOfProducts &&
                    <Button
                        className="primary next_list"
                        onClick={this.uploadNewProducts}
                        label={this.props.t('product_list.next')}
                        type="submit"
                    />
                    }
                </div>
            </Fragment>
        );
    }
}

export default connect((state) => {
    const {categories, chosenBusiness, chosenCategory, chosenSubcategory} = state.business;
    return {categories, chosenBusiness, chosenCategory, chosenSubcategory};
})(withTranslation('business')(Catalogue));
