import React from 'react';
import {Link} from 'react-router-dom';
import './style.scss';
import Icon from 'CommonUI/Icon';
import {Dropdown, DropdownTrigger, DropdownMenu} from 'CommonUI/Dropdown';
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {history} from "_helpers/history";
import Access from '_rbac/Access';

const UserMenu = ({t, user, dispatch}) => {
    return (
        <ul className="user-menu">
            <li>
                <Dropdown>
                    <DropdownTrigger>
                        <div className="user-menu__avatar">
                            <Icon name="user"/>
                        </div>
                        {user ?
                            <div className="user-menu__info">

                                <div className="company">{user.customerGroupName}</div>
                                < div className="user"> {`${user.firstname} ${user.lastname}`}</div>

                            </div> : ""}
                    </DropdownTrigger>

                    <DropdownMenu className="dropdown-menu-right">
                        {user ?
                            <Access
                                role={user.role}
                                perform="users:list"
                                yes={() => (
                                    <Link to="/dashboard/users" className="dropdown-item" name="dropDown_item">
                                        <Icon name="user-group"/>{user.customerGroupName}
                                    </Link>)}
                            /> : ""}

                        <Link to="/dashboard/mon-compte" className="dropdown-item" name="dropDown_item">
                            <Icon name="user"/>
                            {t('profile')}
                        </Link>

                        <div className="dropdown-divider"></div>

                        <span className="dropdown-item" onClick={() => {
                            history.push('/auth/signout')
                        }}>
                            <Icon name="exit"/>
                            {t('sign_out')}
                        </span>
                    </DropdownMenu>
                </Dropdown>
            </li>
        </ul>
    );
};

export default connect((state) => {
    const user = state.profile.user;
    return {user}
})(withTranslation('user')(UserMenu));
