import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';

export default ({title, order, className}) => {
    const {t} = useTranslation('orders');
    return (
        <div className={`info-block ${className}`}>
            <p className="block-title">{title}</p>
            <div className="block-content">
                <div className="row">
                    <div className="col-6 col-md-3">{t('delivery.address')}</div>
                    <div className="col-6 col-md-3">{order.details.address}</div>
                    <div className="col-6 col-md-3">{t('delivery.country')}</div>
                    <div className="col-6 col-md-3">{order.details.country}</div>
                </div>
                <div className="row">
                    <div className="col-6 col-md-3">{t('delivery.city')}</div>
                    <div className="col-6 col-md-3">{order.details.city}</div>
                    <div className="col-6 col-md-3">{t('delivery.postal_code')}</div>
                    <div className="col-6 col-md-3">{order.details.postalCode}</div>
                </div>
                <div className="row">
                    <div className="additional-info">
                        {t('delivery.additional_info')}<br/>
                        {order.details.additionalInfo}
                    </div>
                </div>
            </div>
        </div>
    );
};
