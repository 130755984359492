import {usersConstants} from "./constants";
import {usersServices} from "./services";

class UsersActions {

    getList(filters = {}) {
        return (dispatch) => {
            return usersServices.getList(filters)
                .then((response) => {
                    if (response) {
                        dispatch({type: usersConstants.SET_LIST, users: response.result});
                    }
                    return response;
                })
                .catch(error => {
                    Promise.reject(error);
                });
        }
    }
}

export const usersActions = new UsersActions();