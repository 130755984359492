import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ name, rotate, flip, title, className }) => {

    let cssRotate = '',
        cssFlip = '';

    if (flip === "horizontal") cssFlip = 'scaleX(-1)';
    if (flip === "vertical") cssFlip = 'scaleY(-1)';
    if (rotate) cssRotate = `rotate(${rotate}deg)`;

    const style = { 
        display: 'inline-block',
        transform: `${cssRotate} ${cssFlip}` 
    };

    return (
        <span title={title} style={style} className={`icon dripicons-${name} ${className}`}></span>
    );
};

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    rotate: PropTypes.number,
    flip: PropTypes.string,
    title: PropTypes.string
};

export default Icon;
