import React from 'react';
import './style.scss';
import {connect} from "react-redux";
import PersonalInfoForm from "./PersonalInfoForm/index";
import ResetPasswordForm from "./ResetPasswordForm/index";

class Profile extends React.Component {
    render() {
        return (
            <div className="page page--user">
                <div className="container">
                    <div className="row">
                        <div className="card card-lg col-12">
                            <div className="card-body">
                                <PersonalInfoForm/>
                                <ResetPasswordForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
            ;
    }
}

export default connect((state) => {
    return {user: state.profile.user};
})(Profile);
