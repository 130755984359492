import React from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TextInput from '../../../CommonUI/FormFields/TextInput';
import Button from '../../../CommonUI/Button';
import * as Yup from 'yup';


const CreateOrderSchema = () => {
    return Yup.object().shape({
        country: Yup.string()
            .required('layout:form.error.required'),
        city: Yup.string()
            .required('layout:form.error.required'),
        address: Yup.string()
            .required('layout:form.error.required'),
        addressTitle: Yup.string()
            .required('layout:form.error.required'),
        postalCode: Yup.string()
            .required('layout:form.error.required'),
        newAddressAdditionalInfo: Yup.string().max(240, 'form.error.max_length'),
    })
};
const BusinessAdditionalInfoScheme = (items) => {
    let obj = {};
    for (let i = 0; i < items; i++) {
        obj = {
            ...obj,
            ['businessAddressAdditionalInfo' + i]: Yup.string()
                .max(240, 'form.error.max_length'),
        };
    }

    return Yup.object().shape({
       ...obj
    });
};

const AddressType = {
    Business: 'business',
    Customer: 'customer',
    Supplier: 'supplier',
    NewAddress: 'newAddress'
};

class SupplierAddress extends React.Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            chosenAddress: this.props.businessAddress.length ? (AddressType.Business + 0) : AddressType.Customer,
            newAddressForm: false,
            isExpanded: false,
            additionalInfo: null,
            firstClick: true,
        };
    }

    handleSubmit(values) {
        const {t} = this.props;
        switch (this.state.chosenAddress) {
            case AddressType.NewAddress:
                this.props.onAddressChange(values, values.addressTitle);
                break;
            case AddressType.Supplier:
                this.props.onAddressChange(this.props.supplierAddress,  this.props.supplierAddress.addressTitle);
                break;
            case AddressType.Customer:
                this.props.customerAddress.addressTitle = t('cart.customer_address');
                this.props.onAddressChange(this.props.customerAddress, t('cart.customer_address'));
                break;
            default:
                this.props.onAddressChange(
                    this.props.businessAddress[this.extractInt(this.state.chosenAddress)],
                    this.props.businessAddress[this.extractInt(this.state.chosenAddress)].addressTitle,
                    );
                break;
        }
    }

    updateState(chosenAddress) {
        this.setState({
            newAddressForm: chosenAddress === AddressType.NewAddress,
            chosenAddress: chosenAddress,
            firstClick: false,
        });
    }

    submit(e, values, errors) {
        e.preventDefault();
        const {address, country, city, postalCode, newAddressAdditionalInfo, addressTitle} = values;

        if (Object.values(errors).length) {
            return;
        }

        if (this.state.chosenAddress.includes(AddressType.Business)) {
            const index = this.extractInt(this.state.chosenAddress);
            if (!isNaN(index)) {
                const a = this.props.businessAddress[index];
                a.additionalInfo = values['businessAddressAdditionalInfo' + index];
                this.handleSubmit();
            }
        } else if (this.state.chosenAddress === AddressType.NewAddress) {
            this.handleSubmit({address, country, city, postalCode, addressTitle, additionalInfo: newAddressAdditionalInfo});
        } else {
            this.handleSubmit();
        }
    }

    extractInt(string) {
        const f = string.match(/\d+/g);
        return f.length ? Number(f[0]) : NaN;
    }

    getBusinessAddressTemplate(businessAddress, errors, touched) {
        const t = this.props.t;
        return businessAddress.map((address, index) => {
            return (
                <div key={index}>
                <FormControlLabel
                    value={AddressType.Business + index}
                    id={'business_radio'}
                    key={(AddressType.Business + index)}
                    control={<Radio key={'radio' + index} color="primary" checked={this.state.chosenAddress === (AddressType.Business + index)}/>}
                    onClick={() => { this.updateState((AddressType.Business + index)); }}
                    label={t("cart.modal.business") + address.name} />
                    <div className="container no-padding">
                        <div className="row no-padding">
                            <div className="address-country">
                                <div>{t('cart.modal.data.address_title')}{address.addressTitle}</div>
                                <div>{t('cart.modal.data.address')}{address.address}</div>
                                <div>{t('cart.modal.data.country')}{address.country}</div>
                            </div>
                            <div className="city-postal_code">
                                <div>{t('cart.modal.data.city')}{address.city}</div>
                                <div>{t('cart.modal.data.postal_code')}{address.postalCode}</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <label className="form-label">{t('modal.additional_info')}</label>
                            </div>
                            <div className="col-md-9">
                                <TextInput
                                    value={address.additionalInfo || ''}
                                    touched={touched['businessAddressAdditionalInfo' + index]}
                                    error={t(errors['businessAddressAdditionalInfo' + index])}
                                    key={'businessAddressAdditionalInfo' + index}
                                    type="text"
                                    name={'businessAddressAdditionalInfo' + index}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        })
    }

    render() {
        const {t, supplierAddress, customerAddress, businessAddress} = this.props;
        let initInputs = {
            address: '',
            country: '',
            city: '',
            postalCode: '',
            addressTitle: '',
            newAddressAdditionalInfo: '',
        };

        businessAddress.forEach((item, key) => {
            initInputs = {...initInputs, [`businessAddressAdditionalInfo${key}`]: item.additionalInfo}
        });

        return (
            <Formik
                initialValues={initInputs}
                validationSchema={
                    (this.state.newAddressForm && CreateOrderSchema) ||
                    (this.state.chosenAddress.includes(AddressType.Business) && (() => BusinessAdditionalInfoScheme(businessAddress.length)))
                }
            >
                {({errors, touched, isSubmitting, values}) => (
                    <Form className="form-inline" onSubmit={(e) => {this.submit(e, values, errors)}}>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="gender" name="gender1" className="modal-address">
                                {customerAddress &&
                                <div>
                                    <FormControlLabel
                                        value={AddressType.Customer}
                                        id="customer"
                                        key="customer"
                                        control={<Radio color="primary" checked={this.state.chosenAddress === AddressType.Customer}/>}
                                        onClick={() => { this.updateState(AddressType.Customer); }}
                                        label={t('cart.modal.customer') + customerAddress.name}/>
                                    <div className="container address-container no-padding">
                                        <div className="address-country">
                                            <div>{t('cart.modal.data.address')}{customerAddress.address}</div>
                                            <div>{t('cart.modal.data.country')}{customerAddress.country}</div>
                                        </div>
                                        <div className="city-postal_code">
                                            <div>{t('cart.modal.data.city')}{customerAddress.city}</div>
                                            <div>{t('cart.modal.data.postal_code')}{customerAddress.postalCode}</div>
                                        </div>
                                    </div>
                                </div>}
                                {this.getBusinessAddressTemplate(businessAddress, errors, touched)}
                                { supplierAddress &&
                                <div>
                                    <FormControlLabel
                                        value={AddressType.Supplier}
                                        id="suppliers_radio"
                                        key="supplier"
                                        control={<Radio color="primary" checked={this.state.chosenAddress === AddressType.Supplier}/>}
                                        onClick={() => { this.updateState(AddressType.Supplier); }}
                                        label={t('cart.modal.suppliers')}/>
                                    <div className="container address-container no-padding">
                                        <div className="address-country">
                                            <div>{t('cart.modal.data.address_title')}{supplierAddress.addressTitle}</div>
                                            <div>{t('cart.modal.data.address')}{supplierAddress.address}</div>
                                            <div>{t('cart.modal.data.country')}{supplierAddress.country}</div>
                                        </div>
                                        <div className="city-postal_code">
                                            <div>{t('cart.modal.data.city')}{supplierAddress.city}</div>
                                            <div>{t('cart.modal.data.postal_code')}{supplierAddress.postalCode}</div>
                                        </div>
                                    </div>
                                </div>}
                                <FormControlLabel
                                    value={AddressType.NewAddress}
                                    key="newAddress"
                                    control={<Radio color="primary" checked={this.state.chosenAddress === AddressType.NewAddress}/>}
                                    onClick={() => { this.updateState(AddressType.NewAddress); }}
                                    label={t('cart.modal.new_address')}/>
                            </RadioGroup>
                        </FormControl>
                        {this.state.newAddressForm &&
                        <hr/> &&
                        <br/> &&
                        <div id="new_address_form">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t('modal.address_title')}</label>
                                </div>
                                <div className="col-md-9">
                                    <TextInput
                                        type="text"
                                        name="addressTitle"
                                        error={errors.addressTitle ? t(errors.addressTitle) : ''}
                                        touched={touched.addressTitle}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t('modal.address')}</label>
                                </div>
                                <div className="col-md-9">
                                    <TextInput
                                        type="text"
                                        name="address"
                                        error={errors.address ? t(errors.address) : ''}
                                        touched={touched.address}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t('modal.postal_code')}</label>
                                </div>
                                <div className="col-md-9">
                                    <TextInput
                                        type="text"
                                        name="postalCode"
                                        error={errors.postalCode ? t(errors.postalCode) : ''}
                                        touched={touched.postalCode}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t('modal.city')}</label>
                                </div>
                                <div className="col-md-9">
                                    <TextInput
                                        type="text"
                                        name="city"
                                        error={errors.city ? t(errors.city) : ''}
                                        touched={touched.city}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t('modal.country')}</label>
                                </div>
                                <div className="col-md-9">
                                    <TextInput
                                        type="text"
                                        name="country"
                                        error={errors.country ? t(errors.country) : ''}
                                        touched={touched.country}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t('modal.additional_info')}</label>
                                </div>
                                <div className="col-md-9">
                                    <TextInput
                                        type="text"
                                        name="newAddressAdditionalInfo"
                                        error={errors.newAddressAdditionalInfo ? t(errors.newAddressAdditionalInfo) : ''}
                                        touched={touched.newAddressAdditionalInfo}
                                    />
                                </div>
                            </div>
                        </div>
                        }
                        <div className="col-12 col-sm-12 col-lg-4 text-right">
                            <Button
                                className="primary round responsive-width"
                                isLoading={isSubmitting}
                                label={t('modal.save_address')}
                                disabled={errors.length}
                                type="submit"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default connect((state) => {
    return state;
})(withTranslation('business')(SupplierAddress));
