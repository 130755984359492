import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { FileInput } from 'CommonUI/FormFields';
import { withTranslation } from 'react-i18next';
import Button from 'CommonUI/Button';
import { connect } from 'react-redux';
import '../style.scss';
import { punchoutService } from '../_store/service';
import { notificationConstants } from 'CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import { Redirect } from 'react-router';
import { connectedRoutes } from '../../Layout/Connected/constants';
import { HTTP_BAD_REQUEST, HTTP_INTERNAL, HTTP_NOT_FOUND } from '../_store/constant';
import { basketServices } from '../../Basket/_store/services';

const CreatePunchoutSchema = () => {
    let obj = {
        file: Yup.mixed()
            .required('layout:form.error.required'),
    };

    return Yup.object().shape(obj);
};

withTranslation('punchout')(CreatePunchoutSchema);

class PunchoutImportForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            submitting: false,
            redirect: false,
            initInputs: {
                file: '',
            },
        };

        this.fileUploaded = this.fileUploaded.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {

        if (nextProps.hasOwnProperty('showModal') && this.props.showModal === true && nextProps.showModal === false) {
            this.resetForm();
        }

        return true;
    }

    resetForm = () => {
        this.setState({
            file: null,
            initInputs: {
                file: '',
            },
        });
    }

    fileUploaded(data, setFieldValue) {
        if (data[0]) {
            let reader = new FileReader();
            reader.readAsDataURL(data[0]);
            reader.onload = () => {
                this.setState({file: reader.result});
                setFieldValue('file', reader.result);
            };
        }
    }

    handleSubmit() {
        const {supplier, businessId, dispatch, t} = this.props;
        this.setState({
            submitting: true,
        });
        punchoutService.importBasket(supplier.id, businessId, this.state.file).then((response) => {
            if (response.status === HTTP_INTERNAL || response.status === HTTP_NOT_FOUND || response.status === HTTP_BAD_REQUEST) {
                const result = {
                    type: notificationConstants.ERROR_RESPONSE,
                    result: response.result,
                };

                dispatch(notificationActions.add(result, response.result));
                this.setState({
                    submitting: false,
                });
                return Promise.resolve(null);
            }

            const result = {
                type: notificationConstants.SUCCESS_RESPONSE,
                result: t('cart_success')
            };

            basketServices.fillCountOfCarts(dispatch);
            dispatch(notificationActions.add(result, t('cart_success')));
            this.setState({
                submitting: false,
                redirect: true,
            });
        });
    }

    render() {
        const {t, showModal, supplier} = this.props;
        const {initInputs, redirect, submitting} = this.state;

        if (redirect) {
            return <Redirect to={connectedRoutes.BASKET} />;
        }

        return (
            showModal && <Formik
                initialValues={initInputs}
                enableReinitialize={true}
                onSubmit={this.handleSubmit.bind(this)}
                validationSchema={CreatePunchoutSchema}
                >
                    {({errors, touched, isSubmitting, setFieldValue, values}) => (
                        <Form className="form-inline">
                            <div className="row">
                                <div className="col-12 col-sm-3 col-lg-4">
                                    <label className="form-label">{t('modal.label.file')}</label>
                                </div>
                                <div className="col-12 col-sm-9 col-lg-8">
                                    <FileInput
                                        name="file"
                                        error={errors.file ? t(errors.file) : ''}
                                        touched={touched.file}
                                        onChange={(values) => this.fileUploaded(values, setFieldValue)}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <a className="hint" href={supplier.importExampleLink} target="_blank">{t('download_example')}</a>
                                </div>
                            </div>
                            <hr/>
                            <div className="d-flex justify-content-between">
                                <Button
                                    className="primary round"
                                    isLoading={submitting}
                                    label={t('modal.save')}
                                    type="submit"
                                />
                            </div>

                        </Form>
                    )}
                </Formik>);
    }
}

export default connect()(withTranslation('punchout')(PunchoutImportForm));
