import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import Row from './Row';
import Modal from 'CommonUI/Modal';
import Form from '../Form';

class List extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            user: null
        };
        this.dataUploaded = false;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.dataUploaded = true;
    }

    toggleModal = () => {
        return this.setState((prevState) => {
            return {
                showModal: !prevState.showModal
            }
        });
    };

    editUser(item) {
        this.setState(() => {
            return {
                showModal: true,
                user: item
            }
        });
    }

    render() {
        const {t, users} = this.props;
        const validData = _.isArray(users) && !_.isEmpty(users);
        return (
            <div>
                <div className="table-responsive">
                    <table className="table users-table">
                        <thead>
                        <tr>
                            <th>
                                {t('users.list.firstname')}
                            </th>
                            <th>
                                {t('users.list.lastname')}
                            </th>
                            <th>
                                {t('users.list.email')}
                            </th>
                            <th>
                                {t('users.list.phone')}
                            </th>
                            <th>
                                {t('users.list.orderlimit')}
                            </th>
                            <th>
                                {t('users.list.role')}
                            </th>
                            <th className="actions">
                                {t('users.list.actions')}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {  validData
                            ? users.map((item) =>
                                <Row
                                    key={item.id}
                                    item={item}
                                    editUser={this.editUser.bind(this)}
                                />
                            ) :
                            this.dataUploaded && <tr>
                                <td className="no-data" colSpan="8">
                                    {t('layout:no_data')}
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                {<Modal
                    showModal={this.state.showModal}
                    title={t('users.actions.update.title')}
                    children={<Form closeModal={this.toggleModal} user={this.state.user} type="update"/>}
                    onClose={this.toggleModal}
                />}
            </div>

        )
    }
}

export default connect(
    (state) => {
        return {user: state.profile.user}
    }
)(withTranslation('admin')(List));
