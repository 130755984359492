import React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import App from 'Layout';
import Auth from 'Auth';
import Connected from 'Layout/Connected';
import PrivateRoute from "./PrivateRoute";
import Notfound from "./NotFound";
import OrderConfirmation from './Orders/OrderConfirmation'

const Routes = (
    <App>
        <Switch>
            <Route path="/auth" component={Auth} />
            <PrivateRoute path="/dashboard" component={Connected} />
            <Route path="/api/order/update/status/:orderToken/:orderStatus" component={OrderConfirmation} />
            <Route path="/" render={() => ( <Redirect to='/auth' />)} />
            <Route component={Notfound} />
        </Switch>
    </App>
);
export {
    Routes
}
