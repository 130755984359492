import React, { Fragment } from 'react';
import CartRow from './CartRow';
import { withTranslation } from 'react-i18next';
import Progress from "react-progress-2";
import { basketServices } from "./_store/services";
import { notificationConstants } from "../CommonUI/Notification/_store/constants";
import Button from 'CommonUI/Button';

class CartsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listOfCarts: {},
            loaded: false,
            sortDirection: null,
            enableSort: {label: null, direction: 'right'}
        }
    }

    componentDidMount() {
        Progress.show();
        basketServices.getList()
            .then(
                response => {
                    if (response) {
                        if (response.type === notificationConstants.SUCCESS_RESPONSE) {
                            this.setState({
                                listOfCarts: response.result,
                                loaded: true,
                            });
                        }
                        Progress.hide();
                    }
                }
            ).catch(reason => this.setState({
            loaded: true,
        }));
    }

    businessIsEmpty = (business) => {
        let flag = true;
        Object.values(business).forEach(entry => {
            if (entry !== null && entry !== undefined) {
                flag = false;
            }
        });

        return flag;
    }

    sortRecords = (label, sortDirection) => {
        let listCarts = this.state.listOfCarts;
        if (listCarts && Array.isArray(listCarts)) {
            listCarts = listCarts.filter((item) => !this.businessIsEmpty(item.business))
        }
        console.log(listCarts);
        let nextDirection = '';
        if (sortDirection === null || sortDirection !== 'up') {
            nextDirection = 'up';
            switch (label) {
                case 'name':
                    listCarts.sort((a, b) => (a.business.name.toLowerCase() < b.business.name.toLowerCase()) ? 1 : -1);
                    break;
                case 'creation_date':
                    listCarts.sort((a, b) => (a.business.creation_date < b.business.creation_date) ? 1 : -1);
                    break;
                case 'location':
                    listCarts.sort((a, b) => (a.business.location.toLowerCase() < b.business.location.toLowerCase()) ? 1 : -1);
                    break;
                case 'quantity':
                    listCarts.sort((a, b) => (a.cart.nb_products < b.cart.nb_products) ? 1 : -1);
                    break;
                default:
                    break;
            }
        } else {
            nextDirection = 'down';
            switch (label) {
                case 'name':
                    listCarts.sort((a, b) => (a.business.name.toLowerCase() > b.business.name.toLowerCase()) ? 1 : -1);
                    break;
                case 'creation_date':
                    listCarts.sort((a, b) => (a.business.creation_date > b.business.creation_date) ? 1 : -1);
                    break;
                case 'location':
                    listCarts.sort((a, b) => (a.business.location.toLowerCase() > b.business.location.toLowerCase()) ? 1 : -1);
                    break;
                case 'quantity':
                    listCarts.sort((a, b) => (a.cart.nb_products > b.cart.nb_products) ? 1 : -1);
                    break;
                default:
                    break;
            }
        }
        let direction = '';
        if (nextDirection === 'up'){
            direction = 'down';
        } else {
            direction = 'up';
        }
        this.setState({
            sortDirection: nextDirection,
            listOfCarts: listCarts,
            enableSort: {label, direction}
        })
    };

    sanitizeCarts = () => {
        let carts = this.state.listOfCarts;

        const sanitized = [];
        if (carts && Array.isArray(carts)) {
            carts.forEach(item => {
                if (item.business.id && item.business.name && item.cart.nb_products !== 0) {
                    sanitized.push(item);
                }
            });
        }

        return sanitized;
    }

    render() {
        const {t} = this.props;
        const {sortDirection, enableSort, loaded} = this.state;

        const listOfCarts = this.sanitizeCarts();
        return (
            <div className="table-responsive">
                <table className="table">
                    <thead>
                    <tr className="border-box">
                        <th className="left"
                            onClick={() => {
                                this.sortRecords('name', sortDirection)
                            }}>
                            <Button
                                className="link"
                                label={t('basket:list.name')}
                                icon={`chevron-${enableSort.label === 'name' ? enableSort.direction : 'right'}`}
                            />
                        </th>
                        <th  onClick={() => {
                            this.sortRecords('creation_date', sortDirection)
                        }}>
                            <Button
                                className="link"
                                label={t('basket:list.date')}
                                icon={`chevron-${enableSort.label === 'creation_date' ? enableSort.direction : 'right'}`}
                            />
                        </th>
                        <th className="left"
                            onClick={() => {
                                this.sortRecords('location', sortDirection)
                            }}>
                            <Button
                                className="link"
                                label={t('basket:list.location')}
                                icon={`chevron-${enableSort.label === 'location' ? enableSort.direction : 'right'}`}
                            />
                        </th>
                        <th className="left"
                            onClick={() => {
                                this.sortRecords('quantity', sortDirection)
                            }}>
                            <Button
                                className="link"
                                label={t('basket:list.quantity')}
                                icon={`chevron-${enableSort.label === 'quantity' ? enableSort.direction : 'right'}`}
                            />
                        </th>
                        <th className="center"
                            onClick={() => {}}>
                            {t("basket:list.actions")}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {listOfCarts.length > 0
                        ? listOfCarts.map((item, index) => {
                            if (item.business.id && item.business.name && item.cart.nb_products !== 0) {
                                return <CartRow
                                    key={index}
                                    item={item}
                                />
                            }

                            return <Fragment key={index}/>;
                        }) :
                        this.state.loaded &&
                        <tr>
                            <td className="no-data" colSpan="8">
                                {t('basket:empty_basket')}
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default  withTranslation()(CartsList);
