export const businessConstants = {
    SET_CHOSEN_BUSINESS: "SET_CHOSEN_BUSINESS",
    SET_CHOSEN_CATEGORY: "SET_CHOSEN_CATEGORY",
    SET_CHOSEN_SUBCATEGORY: "SET_CHOSEN_SUBCATEGORY",
    SET_CHOSEN_PRODUCT: "SET_CHOSEN_PRODUCT",
    SET_PAGE_TITLE: "SET_PAGE_TITLE",
    SET_PAGE_IMAGE: "SET_PAGE_IMAGE",
    SET_LIST: "SET_LIST",
    SET_CATEGORIES: "SET_CATEGORIES",
    SET_FILTER_NAMES: "SET_FILTER_NAMES",
    SET_FILTER_LOCALISATIONS: "SET_FILTER_LOCALISATIONS",
    SET_LIST_IS_FILTERED: "SET_LIST_IS_FILTERED",
    SET_SEARCHED_PRODUCTS: "SET_SEARCHED_PRODUCTS"
};

export const BusinessStatus = {
    ACTIVE: 'active',
    CLOSED: 'closed',
}
