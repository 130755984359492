import React from 'react';
import './style.scss';

export default () => {
    return (
        <footer className="footer">
            <div className="footer__content">
                <a href="http://ia-btp.com/mentions-legales/" target="_blank" rel="noopener noreferrer">Mentions légales</a>
            </div>
        </footer>
    );
}
