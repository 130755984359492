import React from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';
import noImage from '_assets/images/noImage.png';
import PersonalCard from './PersonalCard';
import Icon from 'CommonUI/Icon';
import Modal from 'CommonUI/Modal';
import OrderStatusHistory from './OrderStatusHistory';
import DeliveryCard from './DeliveryCard';
import { connect } from 'react-redux';

class GeneralInfoPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        }
    }

    toggleModal = () => {
        return this.setState((prevState) => {
            return {
                showModal: !prevState.showModal
            }
        });
    };

    render() {
        const {t, item, history} = this.props;
        const {order, business, client, supplier} = item;
        const {showModal} = this.state;
        let businessImageSrc = noImage;
        if (business.image) {
            businessImageSrc = process.env.REACT_APP_API_URL + '/' + business.image;
        }
        return (
            <div className="container">
                <div className="row panel-title">
                    <div className="col-12 general-info__title m-10-0">
                        <span className="blue-text">{t('orders:general_info')}</span>
                    </div>
                </div>

                <div className="row general-info__panel">
                    <div className="col-12 col-md-4 border-right-r border-bottom-r">
                        <p className="block-title">{t('layout:business')}</p>
                        <img src={businessImageSrc} alt={business.name}/>
                        <p>{business.name}</p>
                        <p>
                            {business.date} {business.location}
                        </p>
                    </div>
                    <div className="col-md-8">
                        <div className="info-block  border-bottom">
                            <p className="block-title">{t('layout:order')}</p>

                            <div className="block-content">
                                <div className="row">
                                    <div className="col-6 col-md-2">{t('orders:number')}:</div>
                                    <div className="value col-6 col-md-4">{order.details.number}</div>
                                    <div className="col-6 col-md-3">{t('orders:product')}:</div>
                                    <div className="value col-6 col-md-3">{order.products.length}</div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-2">{t("layout:date")}:</div>
                                    <div className="value col-6 col-md-4">{order.details.date}</div>
                                    <div className="col-6 col-md-3">{t("layout:status")}:</div>
                                    <div className="value col-6 col-md-3"> {order.details.status}
                                        <div className="icon" onClick={() => {
                                            this.toggleModal();
                                            this.props.fetchHistory();
                                        }}>
                                            <Icon name="time-reverse"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PersonalCard
                            title={t('orders:client')}
                            person={client}
                            order={order}
                            className="border-bottom"
                        />
                        <PersonalCard
                            title={t('orders:supplier')}
                            person={supplier}
                            order={order}
                            className="border-bottom"
                        />
                        <DeliveryCard
                            title={t('orders:delivery.title')}
                            order={order}
                            className="border-bottom"
                        />
                    </div>
                </div>
                <Modal
                    showModal={showModal}
                    title={t('orders:status_history')}
                    children={<OrderStatusHistory history={history}/>}
                    onClose={this.toggleModal}
                    className="order-status-history-modal"
                />
            </div>
        );
    }
}

export default connect()(withTranslation()(GeneralInfoPanel));
