import {httpService} from "../../_network/http/service";

class OrderServices {

    /**
     *
     * @param httpService
     */
    constructor(httpService) {
        this.httpService = httpService;
    }

    createNewOrder(deliveryInfo) {
        return this.httpService.post(`/order/launch`, deliveryInfo)
            .then(response => {
                    return response;
                }
            );
    }

    getList(filters = {}) {
        return this.httpService.post('/order/list', filters)
            .then(response => {
                return response;
            })
    }

    getStatuses() {
        return this.httpService.get('/order/statuses/list')
            .then(response => {
                return response;
            })
    }

    getItem(id) {
        return this.httpService.get(`/order/${id}`)
            .then(response => {
                return response;
            })
    }

    updateReceivedQuantityOfProducts(data) {
        return this.httpService.post('/order-item/modify/received-quantity', data)
            .then(response => {
                return response;
            })
    }

    getOrderPdf(orderToken) {
        return this.httpService.downloadPdf(`/order/generate/pdf/${orderToken}`)
            .then((response) => {
                return response;
            })
    }

    getOrderHistory(token) {
        return this.httpService.get(`/order/history-statuses/${token}`)
            .then((response) => {
                return response;
            })
    }

    confirmationOrder(orderToken, orderStatus) {
        return this.httpService.post(`/api/order/${orderToken}/${orderStatus}`, '', '')
            .then((response) => {
                return response;
            })
    }

    getOrderDataByToken(orderToken) {
        return this.httpService.get(`/api/order/${orderToken}`, '', '')
            .then((response) => {
                return response;
            })
    }

    getSuppliers() {
        return this.httpService.get('/supplier/list')
            .then((response) => {
                return response;
            })
    }
}

export const orderServices = new OrderServices(httpService);
