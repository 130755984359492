import React from 'react';
import ResetPasswordForm from './Form';
import { useTranslation } from 'react-i18next';

export default () => {
    const { t } = useTranslation('auth');

    return (
        <div className="reset-password">
            <h3>{t('reset.title')}</h3>
            <ResetPasswordForm />
        </div>
    );
}
