import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import logo from '_assets/images/logo.png';

const Logo = ({ source = logo, alt = 'IA-BTP', className, ...rest }) => {

    let cssClass = 'logo';
    if (className) cssClass += ` ${className}`;

    return (
        <div className={cssClass} {...rest}>
            <NavLink to="/">
                <img src={source} alt={alt} />
            </NavLink>
        </div>
    );
}

Logo.propTypes = {
    source: PropTypes.string,
    alt: PropTypes.string
};

export default Logo;