import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DropdownTrigger extends Component {
    render() {
        const { children, className, ...dropdownTriggerProps } = this.props;
        const defaultClassName = 'dropdown-trigger';

        dropdownTriggerProps.className = className ? `${defaultClassName} ${className}` : defaultClassName;

        return (
            <a {...dropdownTriggerProps}>
                {children}
            </a>
        );
    }
}

DropdownTrigger.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

export default DropdownTrigger;