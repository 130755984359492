import React from 'react';
import {Route, Switch} from 'react-router';
import SignIn from './SignIn';
import SignOut from './SignOut';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import NotFound from "../NotFound";
import PublicRoute from "../PublicRoute";
import PrivateRoute from "../PrivateRoute";

export default (
    <Switch>
        <PublicRoute path="/auth" exact component={SignIn}/>
        <PublicRoute path="/auth/connexion" exact component={SignIn}/>
        <PublicRoute path="/auth/mot-de-passe-oubile" component={ForgotPassword}/>
        <PublicRoute path="/auth/nouveau-mot-de-passe/:token" component={ResetPassword}/>
        <PrivateRoute path="/auth/signout" component={SignOut}/>
        <Route component={NotFound}/>

    </Switch>
);
