import { httpService } from "../../_network/http/service";
import { saveUserCookie } from '_helpers/cookies';
import { notificationConstants } from "../../CommonUI/Notification/_store/constants";

class ProfileServices {

    update(user) {
        const data = {
            customer: {
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                phone: user.phone
            }
        };
        return httpService.post('/customer/edit/' + user.id, data)
            .then(
                (response) => {
                    if (response.type === notificationConstants.TYPE_SUCCESS) {
                        delete response.result.address;
                        saveUserCookie(response.result);
                    }
                    return response;
                });
    }

    changePassword(newPassword) {
        return httpService.post("/user/changePassword", {newPassword: newPassword})
            .then(
                (response) => {
                    return response;
                })
    }
}

export const profileServices = new ProfileServices(httpService);
