import React from 'react';
import { Formik, Form } from 'formik';
import { FileInput } from 'CommonUI/FormFields';
import { withTranslation } from 'react-i18next';
import Button from 'CommonUI/Button';
import { connect } from 'react-redux';
import { HTTP_BAD_REQUEST, HTTP_INTERNAL, HTTP_NOT_FOUND } from '../../Punchout/_store/constant';
import { tarifeoService } from '../__store/service';
import { notificationConstants } from 'CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import { Redirect } from 'react-router';
import { connectedRoutes } from '../../Layout/Connected/constants';
import { basketServices } from '../../Basket/_store/services';

class TarifeoPopup extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            submitting: false,
            redirect: false,
            initInputs: {
                file: '',
            },
        };
    }
    
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.hasOwnProperty('showModal') && this.props.showModal === true && nextProps.showModal === false) {
            this.resetForm();
        }
        return true;
    }
    
    resetForm = () => {
        this.setState({
            file: null,
            initInputs: {
                file: '',
            },
        });
    }
    
    
    handleSubmit() {
        const {tarifeoIframeUniqueId, cartId, dispatch, t} = this.props;
        this.setState({
            submitting: true,
        });
        tarifeoService.validateIframe(tarifeoIframeUniqueId, cartId).then((response) => {
            if (response.status === HTTP_INTERNAL || response.status === HTTP_NOT_FOUND || response.status === HTTP_BAD_REQUEST) {
                const result = {
                    type: notificationConstants.ERROR_RESPONSE,
                    result: response.result,
                };
            
                dispatch(notificationActions.add(result, response.result));
                this.setState({
                    submitting: false,
                });
                return Promise.resolve(null);
            }
            basketServices.fillCountOfCarts(this.props.dispatch);
            const result = {
                type: notificationConstants.SUCCESS_RESPONSE,
                result: t('cart_success')
            };
        
            dispatch(notificationActions.add(result, t('cart_success')));
            this.setState({
                submitting: false,
                redirect: true,
            });
        });
    }
    
    render() {
        const {t, showModal, tarifeoIframeUrl, cartId} = this.props;
        const {submitting, redirect } = this.state;
        if (redirect) {
            return <Redirect to={connectedRoutes.BASKET} />;
        }
        return (
            showModal && <Formik
                initialValues={''}
                onSubmit={this.handleSubmit.bind(this)}
            >
                <Form className="form-inline">
                    <iframe src={tarifeoIframeUrl} width="100%" height="450"/>
                    <Button
                        className="primary round"
                        isLoading={submitting}
                        label={t('modal.save')}
                        type="submit"
                    />
                </Form>
            </Formik>);
    }
}

export default connect()(withTranslation('punchout')(TarifeoPopup));
