import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { TextInput, FileInput } from 'CommonUI/FormFields';
import { withTranslation } from 'react-i18next';
import Button from 'CommonUI/Button';
import { businessServices } from "../_store/services";
import { businessActions } from "../_store/actions";
import { connect } from "react-redux";
import { notificationConstants } from "CommonUI/Notification/_store/constants";
import { notificationActions } from "CommonUI/Notification/_store/actions";
import Progress from 'react-progress-2';
import CustomDatePicker from "CommonUI/CustomDatePicker";
import moment from "moment";
import './style.scss';

const CreateBusinessSchema = (secondAddress = false) => {
    let obj = {
        name: Yup.string()
            .required('layout:form.error.required'),
        localisation: Yup.string()
            .required('layout:form.error.required'),
        startDate: Yup.date().typeError('layout:form.error.date_format')
            .required('layout:form.error.required'),
        addressTitle: Yup.string()
            .max(56, 'layout:form.error.max_length')
            .required('layout:form.error.required'),
        country: Yup.string()
            .required('layout:form.error.required'),
        city: Yup.string()
            .required('layout:form.error.required'),
        address: Yup.string()
            .required('layout:form.error.required'),
        postalCode: Yup.string()
            .required('layout:form.error.required'),
        illustrationPath: Yup.mixed(),
        additionalInfo: Yup.mixed()
    };
    if (secondAddress) {
        obj = {
            ...obj,
            secondAddressTitle: Yup.string()
                .required('layout:form.error.required'),
            secondCountry: Yup.string()
                .required('layout:form.error.required'),
            secondCity: Yup.string()
                .required('layout:form.error.required'),
            secondAddress: Yup.string()
                .required('layout:form.error.required'),
            secondPostalCode: Yup.string()
                .required('layout:form.error.required'),
        };
    }

    return Yup.object().shape(obj)
};

withTranslation('business')(CreateBusinessSchema);

class CreateBusinessForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initInputs: {
                id: '',
                name: '',
                localisation: '',
                illustrationPath: '',
                startDate: moment().toDate(),
                country: '',
                city: '',
                addressTitle: '',
                address: '',
                postalCode: '',
                additionalInfo: '',
                secondCountry: '',
                secondCity: '',
                secondAddressTitle: '',
                secondAddress: '',
                secondPostalCode: '',
                secondAdditionalInfo: '',
            },
            secondAddress: false,
            loaded: false
        };
        this.illustrationUploaded = this.illustrationUploaded.bind(this);
    }

    illustrationUploaded(data) {
        if (data[0]) {
            let _this = this;
            let reader = new FileReader();
            reader.readAsDataURL(data[0]);
            reader.onload = function () {
                _this.setState({illustration: reader.result});
            };
            reader.onerror = function (error) {
            };
        }
    }

    hasSecondAddress(data) {
        let flag = false;
        Object.entries(data).forEach(item => {
            let [key, value] = item;
            if (key.includes('second') && value !== null && value !== undefined && ((typeof value === 'string' && value.length) || typeof value !== 'string')) {
                flag = true;
            }
        });

        return flag;
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.businessData) {
            const data = nextProps.businessData;
            let startDate = data.startDate ? moment(data.startDate, 'DD-MM-YYYY').toDate() : moment().toDate();
            this.setState({
                initInputs: {
                    id: data.id,
                    name: data.name,
                    localisation: data.localisation,
                    country: data.country,
                    city: data.city,
                    addressTitle: data.addressTitle || '',
                    address: data.address,
                    postalCode: data.postalCode,
                    additionalInfo: data.additionalInfo,
                    secondCountry: data.secondCountry || '',
                    secondCity: data.secondCity || '',
                    secondAddressTitle: data.secondAddressTitle || '',
                    secondAddress: data.secondAddress || '',
                    secondPostalCode: data.secondPostalCode || '',
                    secondAdditionalInfo: data.secondAdditionalInfo || '',
                    illustrationPath: data.illustrationPath || '',
                    startDate
                },
                secondAddress: this.hasSecondAddress(data),
            }, () => {
                this.setState({
                    loaded: true
                })
            });
        }
    }

    handleSubmit(values, {resetForm, setSubmitting}) {
        const {t, dispatch, closeModal} = this.props;
        const data = {...values, startDate: moment(values.startDate).format('DD-MM-YYYY')};
        data.illustration = this.state.illustration;

        if (values.id === undefined) {
            Progress.show();
            businessServices.createBusiness({business: data})
                .then(
                    (response) => {
                        if (response) {
                            sessionStorage.removeItem('business');
                            let business = response.result;
                            if (response.type === notificationConstants.SUCCESS_RESPONSE) {
                                dispatch(notificationActions.add(response, t("notification.success.create_business_part1") + business.name + t("notification.success.create_business_part2")));
                                dispatch(businessActions.getList())
                            }
                        }
                    }
                )
                .finally(() => {
                setSubmitting(false);
                resetForm();
                if (closeModal) closeModal();
                Progress.hide();
            });
        } else {
            Progress.show();
            businessServices.updateBusiness(values.id, {business: data})
                .then(
                    (response) => {
                        if (response) {
                            sessionStorage.removeItem('business');
                            let business = response.result;
                            if (response.type === notificationConstants.SUCCESS_RESPONSE) {
                                dispatch(notificationActions.add(response, t("notification.success.update_business_part1") + business.name + t("notification.success.update_business_part2")));
                                dispatch(businessActions.getList())
                            }
                        }
                    },
                )
                .finally(() => {
                    setSubmitting(false);
                    resetForm();
                    if (closeModal) closeModal();
                    Progress.hide();
                })
        }
        return {};
    }

    render() {
        const {t, businessData} = this.props;
        const {initInputs, loaded, secondAddress} = this.state;
        return (
            businessData && loaded ?
                <Formik
                    initialValues={initInputs}
                    enableReinitialize={true}
                    onSubmit={this.handleSubmit.bind(this)}
                    validationSchema={() => CreateBusinessSchema(secondAddress)}
                >
                    {({errors, touched, isSubmitting, setFieldValue, values}) => (
                        <Form className="form-inline">
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t("modal.name")}</label>
                                </div>
                                <div className="col-md-9">
                                    <TextInput
                                        type="text"
                                        name="name"
                                        error={errors.name ? t(errors.name) : ''}
                                        touched={touched.name}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t("modal.date")}</label>
                                </div>
                                <div className="col-md-9">
                                    <CustomDatePicker
                                        name="startDate"
                                        value={values.startDate}
                                        onChange={setFieldValue}
                                        error={errors.startDate ? t(errors.startDate) : ''}
                                        touched={touched.startDate}
                                        minDate={moment().subtract(5, 'years').toDate()}
                                        maxDate={moment().add(5, 'years').toDate()}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t("modal.location")}</label>
                                </div>
                                <div className="col-md-9">
                                    <TextInput
                                        type="text"
                                        name="localisation"
                                        error={errors.localisation ? t(errors.localisation) : ''}
                                        touched={touched.localisation}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t("modal.illustration")}</label>
                                </div>
                                <div className="col-md-9">
                                    <FileInput
                                        name="illustration"
                                        error={errors.illustration ? t(errors.illustration) : ''}
                                        touched={touched.illustration}
                                        onChange={this.illustrationUploaded}
                                    />
                                </div>
                            </div>
                            <hr/>
                            <div><b>{t('modal.delivery_modal_part')}</b></div>
                            <br/>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t("modal.address_title")}</label>
                                </div>
                                <div className="col-md-9">
                                    <TextInput
                                        type="text"
                                        name="addressTitle"
                                        error={errors.addressTitle ? t(errors.addressTitle) : ''}
                                        touched={touched.addressTitle}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t("modal.address")}</label>
                                </div>
                                <div className="col-md-9">
                                    <TextInput
                                        type="text"
                                        name="address"
                                        error={errors.address ? t(errors.address) : ''}
                                        touched={touched.address}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t("modal.postal_code")}</label>
                                </div>
                                <div className="col-md-9">
                                    <TextInput
                                        type="text"
                                        name="postalCode"
                                        error={errors.postalCode ? t(errors.postalCode) : ''}
                                        touched={touched.postalCode}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t("modal.city")}</label>
                                </div>
                                <div className="col-md-9">
                                    <TextInput
                                        type="text"
                                        name="city"
                                        error={errors.city ? t(errors.city) : ''}
                                        touched={touched.city}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t("modal.country")}</label>
                                </div>
                                <div className="col-md-9">
                                    <TextInput
                                        type="text"
                                        name="country"
                                        error={errors.country ? t(errors.country) : ''}
                                        touched={touched.country}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">{t("modal.additional_info")}</label>
                                </div>
                                <div className="col-md-9">
                                    <TextInput
                                        type="text"
                                        name="additionalInfo"
                                        error={errors.additionalInfo ? t(errors.additionalInfo) : ''}
                                        touched={touched.additionalInfo}
                                    />
                                </div>
                            </div>
                            { secondAddress && <>
                                <hr />
                                <div><b>{t('modal.second_delivery_modal_part')}</b></div>
                                <br/>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">{t("modal.address_title") + '(2)'}</label>
                                    </div>
                                    <div className="col-md-9">
                                        <TextInput
                                            type="text"
                                            name="secondAddressTitle"
                                            error={errors.secondAddressTitle ? t(errors.secondAddressTitle) : ''}
                                            touched={touched.secondAddressTitle}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">{t("modal.address") + '(2)'}</label>
                                    </div>
                                    <div className="col-md-9">
                                        <TextInput
                                            type="text"
                                            name="secondAddress"
                                            error={errors.secondAddress ? t(errors.secondAddress) : ''}
                                            touched={touched.secondAddress}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">{t("modal.postal_code") + '(2)'}</label>
                                    </div>
                                    <div className="col-md-9">
                                        <TextInput
                                            type="text"
                                            name="secondPostalCode"
                                            error={errors.secondPostalCode ? t(errors.secondPostalCode) : ''}
                                            touched={touched.secondPostalCode}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">{t("modal.city") + '(2)'}</label>
                                    </div>
                                    <div className="col-md-9">
                                        <TextInput
                                            type="text"
                                            name="secondCity"
                                            error={errors.secondCity ? t(errors.secondCity) : ''}
                                            touched={touched.secondCity}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">{t("modal.country") + '(2)'}</label>
                                    </div>
                                    <div className="col-md-9">
                                        <TextInput
                                            type="text"
                                            name="secondCountry"
                                            error={errors.secondCountry ? t(errors.secondCountry) : ''}
                                            touched={touched.secondCountry}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="form-label">{t("modal.additional_info") + '(2)'}</label>
                                    </div>
                                    <div className="col-md-9">
                                        <TextInput
                                            type="text"
                                            name="secondAdditionalInfo"
                                            error={errors.secondAdditionalInfo ? t(errors.secondAdditionalInfo) : ''}
                                            touched={touched.secondAdditionalInfo}
                                        />
                                    </div>
                                </div>
                            </>}
                            <div className="d-flex justify-content-between">
                                {!secondAddress &&
                                    <Button
                                        className="primary round"
                                        label={t('modal.create_second_address')}
                                        onClick={() => this.setState({secondAddress: true})}
                                    />}
                                {secondAddress && <div className="hidden"></div>}
                                    <Button
                                        className="primary round"
                                        isLoading={isSubmitting}
                                        label={t('modal.save')}
                                        type="submit"
                                    />

                            </div>

                        </Form>
                    )}
                </Formik> : null);
    }
}

export default connect()(withTranslation('business')(CreateBusinessForm));
