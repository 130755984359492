import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from 'CommonUI/Icon';

import './style.scss';

const Button = ({ 
    className, 
    label, 
    onClick, 
    type = 'button', 
    color = 'default', 
    icon, 
    children,
    disabled = false,
    isLoading = false,
    isExternal = false,
    to = ''
}) => {
    const buttonDisabled = disabled || isLoading;

    let cssClass = `btn ${color}`;
    if (className) cssClass += ` ${className}`;
    if (isLoading) cssClass += ' loading';

    const buttonContent = (
        <Fragment>
            {!buttonDisabled && <div className="anim"></div>}
            {children ? children :
                <Fragment>
                    {label && <span className="label">{label}</span>}
                    {isLoading 
                        ? 
                        <div className="spiner"></div>
                        :
                        <Fragment>
                            {icon && <Icon name={icon} />}
                        </Fragment>
                    }
                </Fragment>
            }
        </Fragment>
    );

    return (
        <Fragment>
            {to ?
                isExternal ?
                    <a className={cssClass} href={to} target="_blank" rel="noopener noreferrer">{buttonContent}</a>
                    :
                    <Link
                        to={to}
                        className={cssClass}
                        disabled={buttonDisabled}
                    >{buttonContent}
                    </Link>
                :
                <button
                    type={type}
                    className={cssClass}
                    onClick={onClick}
                    disabled={buttonDisabled}
                >{buttonContent}</button>
            }
        </Fragment>
    );
}

Button.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.any,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    to: PropTypes.string
};

export default Button;