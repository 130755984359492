import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Layout from 'Layout/_locales';
import CommonUI from 'CommonUI/_locales';
import Auth from 'Auth/_locales';
import Business from 'Business/_locales';
import Orders from 'Orders/_locales';
import Statistics from 'Statistics/_locales';
import Profile from 'Profile/_locales';
import Basket from 'Basket/_locales';
import Admin from 'Administration/_locales';
import Punchout from 'Punchout/_locales';

const options = {
    interpolation: {
        escapeValue: false,
    },

    debug: true,
    resources: {
        fr: {
            'layout': Layout.fr,
            'commonui': CommonUI.fr,
            'auth': Auth.fr,
            'business': Business.fr,
            'orders': Orders.fr,
            'statistics': Statistics.fr,
            'user': Profile.fr,
            'basket': Basket.fr,
            'admin': Admin.fr,
            'punchout': Punchout.fr,
        },
        en: {
            'layout': Layout.en,
            'commonui': CommonUI.en,
            'auth': Auth.en,
            'business': Business.en,
            'orders': Orders.en,
            'statistics': Statistics.en,
            'user': Profile.en,
            'basket': Basket.en,
            'admin': Admin.en,
            'punchout': Punchout.en,
        },
    },

    lng: 'fr',
    fallbackLng: 'fr',

    ns: ['common'],

    defaultNS: 'common',

    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    },
};

//TODO: add later XHR if needed
i18n
    .use(LanguageDetector)
    .init(options)
;

export default i18n;
