import React from "react";
import "../style.scss";
import _ from 'lodash';
import { TextInput } from 'CommonUI/FormFields';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { connectedRoutes } from 'Layout/Connected/constants';
import { Screen } from '../../../_constants';
import { ResponsiveContext } from '../../../_store/responsive-context';
import Icon from 'CommonUI/Icon';
import ReactTooltip from 'react-tooltip';

class FormRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: true,
            values: null,
        }
    }

    componentWillMount() {
        const {onChangeQuantity, recalculateTotalPrice} = this.props;
        this.delayedCallback = _.debounce(function (cartItemId, value, products) {
            if (this.props.isValid) {
                onChangeQuantity(cartItemId, value, products);
            }
            recalculateTotalPrice(this.props.values.products);
        }, 1000);
    }

    render() {
        const {product, index, businessId, styles, removeProduct, errors, touched, isValid, setFieldValue, values, t} = this.props;
        return (
            <ResponsiveContext.Consumer>
                {({size}) => (
                    <div style={styles} className="row text-left cart-row">
                        {this.state.size < Screen.md &&
                            <div className="col-6 cart-column">
                                <span className="form-field">{t('cart.table.table_title_product')}</span>
                            </div>
                        }
                        <div className="col-6 col-md-5 cart-column">
                            <b>
                                {product.isPunchout || product.isTarifeo ?
                                    <>
                                        <span data-tip data-for={`${index}-cart-icon`}> <Icon name="duplicate" className="cursor-pointer" /> </span>
                                        <span
                                            className={(product.tarifeoStatus > 0 ? 'text-decoration-none tarifeo-not-found' : 'color-20527a text-decoration-none')}
                                        >
                                            {product.name}
                                        </span>
                                     </>:
                                    <NavLink
                                        to={`${connectedRoutes.BUSINESS}/${businessId}/product/${product.product_id}`}
                                        className="color-20527a text-decoration-none">
                                        {product.name}
                                    </NavLink>
                                }
                            </b>
                            <br/>
                            <span>{t('cart.table.ref')} {product.reference} </span>
                            <span>{t('cart.table.mark')} {product.brand}</span>
                            <br/>
                            <NavLink
                                className="remove_product_link"
                                to={`${connectedRoutes.BUSINESS}/${businessId}/cart`}
                                onClick={() => {
                                    removeProduct(product);
                                }}
                            >{t('cart.table.remove')}</NavLink>
                        </div>
                        {size < Screen.md &&
                            <div className="col-6 cart-column">
                                <span className="form-field">{t('cart.table.table_title_supplier')}</span>
                            </div>
                        }
                        <div className="col-6 col-md-3 cart-column">
                            <b>{product.supplier}</b>
                        </div>
                        {size < Screen.md &&
                            <div className="col-6 cart-column">
                                <span className="form-field">{t('cart.table.table_title_price')}</span>
                            </div>
                        }
                        <div className="col-6 col-md-2 cart-column">
                            <b>{Math.round(product.price * 100) / 100} {t('cart.table.currency_symbol')}</b>
                        </div>

                        {size < Screen.md &&
                            <div className="col-6 cart-column">
                                <span className="form-field">{t('cart.table.table_title_quantity')}</span>
                            </div>
                        }
                        <div className="col-6 col-md-2 cart-column">
                            <TextInput
                                className={(size >= Screen.md ? 'quantityInput' : 'form-control quantityInput')}
                                type="text"
                                name={`products.${index}.quantity`}
                                error={_.isArray(errors.products) && errors.products[index] ? t(errors.products[index].quantity) : ''}
                                touched={_.isArray(touched.products) && touched.products[index] ? touched.products[index].quantity : false}
                                onChange={
                                    (e) => {
                                        setFieldValue(`products.${index}.quantity`, e.target.value);
                                        e.persist();
                                        this.setState({isValid, values}, () => {
                                            let products = values.products;
                                            this.delayedCallback(product.item_id, e.target.value, products);
                                        });
                                    }
                                }

                            />
                            <TextInput
                                type="hidden"
                                name={`products.${index}.cartItemId`}
                            />
                            <TextInput
                                type="hidden"
                                name={`products.${index}.price`}
                            />

                            <TextInput
                                type="hidden"
                                name={`products.${index}.orderLimit`}
                            />
                        </div>
                        <ReactTooltip
                            id={`${index}-cart-icon`}
                            effect='solid'
                            key={`${index}-cart-icon`}>
                            {t('tooltip.punchout')}
                        </ReactTooltip>
                    </div>
                )}
            </ResponsiveContext.Consumer>
        );
    }
}

export default withTranslation('business')(FormRow);
