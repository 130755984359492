class OrderHelpers{
    processHistory(history) {
        return history.map((item) => {
            let locale =  localStorage.getItem('i18nextLng');
            item.jsDate = this.createDateFromString(item.date);
            const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
            item.prettyDate = item.jsDate.toLocaleDateString(locale, options);
            item.prettyDate = item.prettyDate.charAt(0).toUpperCase() + item.prettyDate.slice(1);
            item.prettyTime=item.jsDate.getHours()+':'+ String(item.jsDate.getMinutes()).padStart(2, "0");
            return item;
        });
    }

    createDateFromString(string) {
        const regex = /\s|\/|:/g;
        const dateParts = string.split(regex);
        return new Date(dateParts[2], dateParts[1] - 1, dateParts[0], dateParts[3], dateParts[4])
    }

}

export const orderHelpers = new OrderHelpers();