import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Alert = ({ type, message, canClose = false, ...rest }) => {
    
    const allowedTypes = ['success', 'danger', 'warning', 'info'];
    const alertClass   = allowedTypes.includes(type) ? type : allowedTypes[0];

    return (
        <div className={`alert alert--${alertClass}`} {...rest}>
            <div dangerouslySetInnerHTML={{ __html: message }}></div>
        </div>
    );
};

Alert.propTypes = {
    type: PropTypes.string,
    message: PropTypes.string,
    canClose: PropTypes.bool
};

export default Alert;