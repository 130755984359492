import * as Yup from 'yup';

const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .required("layout:form.error.required"),
    repeatPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "layout:form.error.match_password")
        .required("layout:form.error.required")
});

export default  ResetPasswordSchema;
