import React from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
    const {t} = useTranslation('layout');
    return (
        <div className="text-center">
            <h3>{t('unauthorized_access')}</h3>
        </div>
    );
}
