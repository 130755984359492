import React from 'react';
import { Formik, Form, FieldArray, ErrorMessage } from 'formik';
import { withTranslation } from 'react-i18next';
import Button from 'CommonUI/Button';
import { orderServices } from 'Orders/_store/services';
import '../style.scss';
import schema from './schema';
import { notificationConstants } from '../../../CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import { connect } from 'react-redux';
import FormRow from '../../../Business/Item/Cart/FormRow';


class ValidationForm extends React.Component {

    constructor(props) {
        super(props);
        this.dispatch = props.dispatch;
        this.state = {
            products: []
        };
        this.formik = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {showModal} = this.props;
        if (showModal !== prevProps.showModal && showModal === false) {
            this.formik.current.resetForm();
        }
    }

    saveData(values, {setSubmitting}) {
        const {closeModal, fetchOrder} = this.props;
        let data = this.createRequestData(values);

        orderServices.updateReceivedQuantityOfProducts({data})
            .then(
                (response) => {
                    if (response) {
                        if (response.type === notificationConstants.SUCCESS_RESPONSE) {
                            setSubmitting(false);
                            if (closeModal) closeModal();
                            fetchOrder();
                        } else {
                            this.dispatch(notificationActions.add(response));
                            this.formik.current.resetForm();
                            setSubmitting(false);
                        }
                    }
                }
            )
    }

    createRequestData(values) {
        const data = {
            order_id: this.props.orderId,
            products: []
        };
        Object.keys(values.products).forEach(function (key) {
            if (values.products[key].quantity > 0) {
                data.products.push({id: values.products[key].id, quantity: parseInt(values.products[key].quantity)});
            }
        });
        return data;
    }

    render() {
        const {t, products} = this.props;
        let initialValues = {};
        initialValues.products = [];
        products.forEach((item, index) => {
            initialValues.products[index] = {
                id: item.variant_id,
                quantity: 0,
                ordered_quantity: item.quantity,
                already_received_quantity: item.received_quantity
            };
            if (typeof item.received_quantity === 'undefined') {
                item.received_quantity = 0;
            }
        });

        return (<Formik
            initialValues={initialValues}
            onSubmit={this.saveData.bind(this)}
            ref={this.formik}
            validationSchema={schema}
            enableReinitialize={true}
            render={({errors, touched, isSubmitting}) => (
                <Form className="form-inline validation-form">
                    <div className="row border-bottom responsive-thead">
                        <div className="col-6 col-sm-4">{t('orders:product')}</div>
                        <div className="col-6 col-sm-3">{t('orders:ordered_quantity')}</div>
                        <div className="col-6 col-sm-3">{t('orders:already_received_quantity')}</div>
                        <div className="col-6 col-sm-2">{t('orders:received_quantity')}</div>
                    </div>
                    <FieldArray
                        name="products"
                        render={arrayHelpers => (
                            <div>
                                {products.map((product, index) => (
                                    <FormRow
                                        item={product}
                                        index={index}
                                        key={index}
                                        errors={errors}
                                        touched={touched}/>
                                ))}
                            </div>
                        )}

                    />
                    {typeof errors.products === 'string' ?
                        <ErrorMessage name="products" render={msg => <div className="error">{t(msg)}</div>}/> : ''}
                    <div className="text-right">
                        <Button
                            className="primary"
                            isLoading={isSubmitting}
                            label={t('layout:confirm')}
                            type="submit"
                        />
                    </div>
                </Form>
            )}
        />);
    }
}

export default connect(() => {})(withTranslation()(ValidationForm));
