import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './style.scss';
import { basketServices } from "../../../Basket/_store/services";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

class shoppingCart extends Component {
    constructor(props) {
        super(props);
        this.dispatch = props.dispatch;
        this.state = {
            countOfCarts: 0,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.hasOwnProperty('nbCarts') &&
            nextProps.nbCarts !== null &&
            nextProps.nbCarts !== undefined &&
            !Number.isNaN(Number(nextProps.nbCarts))) {
            this.setState({
                countOfCarts: Number(nextProps.nbCarts),
            });
        }
    }

    componentDidMount() {
        this.initCountOfCarts();
    }

    initCountOfCarts = () => {
        basketServices.fillCountOfCarts(this.props.dispatch);
    };

    render() {
        const {countOfCarts} = this.state;
        const {t, isActive} = this.props;

        return (
            <NavLink
                to="/dashboard/mes-paniers"
                activeClassName="is-active"
                isActive={isActive}
                className="shopping-cart"
            >
                <span>{t('main_menu.my_carts')}</span>
                <div className="shopping-cart__count">{countOfCarts || 0}</div>
            </NavLink>
        );
    }
}

export default connect((state) => {
    const {nbCarts} = state.basket;
    return {nbCarts};
})(withTranslation('layout')(shoppingCart));

