import React, { Fragment } from 'react';
import Header from 'Layout/Header';
import Footer from 'Layout/Footer';

import routes from './routes';

export default () => {
    return (
        <Fragment>
            <Header />
            <main className="main">{routes}</main>
            <Footer />
        </Fragment>
    );
}
