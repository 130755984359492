import {orderConstants} from "./constants";

const initialState = {
    filter: {
        status: null,
        supplier: null,
        startDate: null,
        endDate: null
    },
    statuses: [],
    suppliers: []
};

export const order = (state = initialState, action) => {
    let response;
    switch (action.type) {
        case orderConstants.SET_ORDERS_FILTERS:
            response = {...state, filter: action.data};
            break;

        case orderConstants.SET_ORDERS_STATUSES:
            response = {...state, statuses: action.data};
            break;

        case orderConstants.SET_ORDERS_SUPPLIERS:
            response = {...state, suppliers: action.data};
            break;

        case orderConstants.CLEAR_ORDERS_FILTERS:
            response = {...state, filter: initialState.filter};
            break;

        default:
            response = state;
            break;
    }

    return response;
};
