import React from 'react';
import { Route, Switch } from 'react-router';
import { connectedRoutes } from '../Layout/Connected/constants';
import BusinessList from './List';
import BusinessItem from './Item';
import Cart from './Item/Cart';
import SuppliersAddresses from './SuppliersAddresses';

export default (
    <Switch>
        <Route path={`${connectedRoutes.BUSINESS}/:id/product/:productId`} component={BusinessItem} />
        <Route path={`${connectedRoutes.BUSINESS}/:id/catalogue/:subcategoryId`} component={BusinessItem} />
        <Route path={`${connectedRoutes.BUSINESS}/:id/cart/addresses`} component={SuppliersAddresses} />
        <Route path={`${connectedRoutes.BUSINESS}/:id/cart`} component={Cart} />
        <Route path={`${connectedRoutes.BUSINESS}/:id`} component={BusinessItem} />
        <Route path={connectedRoutes.BUSINESS} component={BusinessList} />
    </Switch>
);
