import axios from 'axios';
import cookies from 'react-cookies';
import { notificationConstants } from 'CommonUI/Notification/_store/constants';

class HttpService {

    get(url, params, urlSuffix = '/vsbridge') {
        let request = axios({
            method: 'GET',
            url: process.env.REACT_APP_API_URL + urlSuffix + url,
            headers: this.getAxiosHeaders(),
            data: params
        });
        return request
            .then(response => {
                return HttpService.handleResponse(response.data, '');
            })
            .catch(httpService.handleError);

    }

    post(url, params, urlSuffix = '/vsbridge') {
        let request = axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + urlSuffix + url,
            headers: this.getAxiosHeaders(),
            data: params
        });
        return request
            .then(response => {
                return HttpService.handleResponse(response.data, url);
            })
            .catch(this.handleError);
    }

    delete(url, urlSuffix = '/vsbridge') {

        let request = axios({
            method: 'DELETE',
            url: process.env.REACT_APP_API_URL + urlSuffix + url,
            headers: this.getAxiosHeaders()
        });

        return request
            .then(response => {
                return HttpService.handleResponse(response.data, url);
            })
            .catch(this.handleError);
    }

    getAxiosHeaders() {
        let axiosHeaders = {
            'Content-Type': 'application/json'
        };

        const token = cookies.load('token');
        if (token) {
            axiosHeaders['Authorization'] = 'Bearer ' + token;
        }

        return axiosHeaders;
    }

    refreshToken() {
        const refreshToken = cookies.load('refreshToken');

        if (refreshToken) {
            const data = {
                'refresh_token': refreshToken
            };

            return axios({
                method: 'POST',
                url: process.env.REACT_APP_API_URL + '/api/token/refresh',
                data: data
            })
                .then(rs => {
                    let response = false;
                    if (rs) {
                        cookies.remove('token', {path: '/'});
                        cookies.save('token', rs.data.result, {path: '/'});
                        response = true;
                    }

                    return response;
                })
                .catch(httpService.handleError);
        }

        return Promise.resolve(true);
    }


    handleError(error) {
        let response = {};
        if (error.response && error.response.config) {
            let requestData = error.response.config;
            if (error.response && error.response.status === 401) {
                httpService.refreshToken()
                    .then(() => {
                        let url = requestData.url.split('vsbridge');
                        switch (requestData.method) {
                            case 'delete':
                                httpService.delete(url[1]);
                                break;
                            case 'get':
                                httpService.get(url[1], requestData.data);
                                break;
                            case 'post':
                                httpService.post(url[1], requestData.data);
                                break;
                            default:
                                break;
                        }
                    })
            }
        }

        response.type = notificationConstants.ERROR_RESPONSE;
        response.result = notificationConstants.ERROR_MESSAGE;
        response.statue = error.status;

        return HttpService.handleResponse(response, '');
    }

    static handleResponse(response, url) {
        let data = {};
        if (response.status === 200 || response.code === 200) {
            data.type = notificationConstants.SUCCESS_RESPONSE;
            data.result = response.result ? response.result : response.message
        } else {
            data.type = notificationConstants.ERROR_RESPONSE;
            data.result = response.result;
            data.status = response.status || response.code || null;
        }

        if (url === '/user/login') {
            data.meta = response.meta;
        }

        return data;
    }

    downloadPdf(url, urlSuffix = '/vsbridge') {
        url = process.env.REACT_APP_API_URL + urlSuffix + url;
        if (url) {
            return axios({
                method: 'GET',
                url: url,
                headers: this.getAxiosHeaders(),
                responseType: 'blob'
            })
                .then(response => {
                        return response;
                    }
                )
                .catch(httpService.handleError);
        }
    }
}

export const httpService = new HttpService();
