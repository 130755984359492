import React from 'react';
import {authActions} from 'Auth/_store/actions';
import {connect} from "react-redux";

class SignOut extends React.Component {
    constructor(props) {
        super(props);
        this.props.dispatch(authActions.logout());
    }

    render() {
        return ('');
    }

}

export default connect()(SignOut)
