import React from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { connectedRoutes } from "../../../Layout/Connected/constants";
import { ResponsiveContext } from '../../../_store/responsive-context';
import Button from '../../../CommonUI/Button';
import { Screen } from '../../../_constants';

class CategoriesDropdown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <ResponsiveContext.Consumer>
                {({size}) => (
                    <>
                        {size >= Screen.md &&
                            <a className={this.props.className || ''} href={`${connectedRoutes.BUSINESS}/${this.props.affairId}`}>{this.props.t('browse_product_families')}</a>
                        }
                        {size < Screen.md &&
                            <Button
                                to={`${connectedRoutes.BUSINESS}/${this.props.affairId}`}
                                className={`secondary responsive-width ${this.props.className || ''}`}
                                type="button"
                                label={this.props.t('browse_product_families')}
                                />
                        }
                </>
                )}
            </ResponsiveContext.Consumer>
        );
    }
}

export default connect((state) => {
    const { categories } = state.business;
    return { categories };
})(withTranslation('business')(CategoriesDropdown));
