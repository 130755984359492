import React from 'react';
import OrderRow from './OrderRow';
import { withTranslation } from 'react-i18next';
import _ from 'lodash'
import Button from 'CommonUI/Button';

class OrderList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: {},
            enableSort: {label: null, direction: null},
            showModal: false,
            sortDirection: null
        };
        this.dataUploaded = false;

    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.dataUploaded = true;
        this.setState({
            orders: nextProps.orders
        })
    }

    sortRecords = (label, sortDirection) => {
        let listOrders = this.props.orders;
        let nextDirection = '';
        if (sortDirection === null || sortDirection !== 'up') {
            nextDirection = 'up';
            switch (label) {
                case 'id':
                    listOrders.sort((a, b) => (a.order.business_id < b.order.business_id) ? 1 : -1);
                    break;
                case 'number':
                    listOrders.sort((a, b) => (a.order.number < b.order.number) ? 1 : -1);
                    break;
                case 'provider':
                    listOrders.sort((a, b) => (a.supplier.company.toLowerCase() < b.supplier.company.toLowerCase()) ? 1 : -1);
                    break;
                case 'business':
                    listOrders.sort((a, b) => (a.business.name.toLowerCase() < b.business.name.toLowerCase()) ? 1 : -1);
                    break;
                case 'price':
                    listOrders.sort((a, b) => (a.order.total_price < b.order.total_price) ? 1 : -1);
                    break;
                case 'date':
                    listOrders.sort((a, b) => (a.order.date - b.order.date) ? 1 : -1);
                    break;
                case 'status':
                    listOrders.sort((a, b) => (a.order.status < b.order.status) ? 1 : -1);
                    break;
                default:
                    break;
            }
        } else {
            nextDirection = 'down';
            switch (label) {
                case 'id':
                    listOrders.sort((a, b) => (a.order.business_id > b.order.business_id) ? 1 : -1);
                    break;
                case 'number':
                    listOrders.sort((a, b) => (a.order.number > b.order.number) ? 1 : -1);
                    break;
                case 'provider':
                    listOrders.sort((a, b) => (a.supplier.company.toLowerCase() > b.supplier.company.toLowerCase()) ? 1 : -1);
                    break;
                case 'business':
                    listOrders.sort((a, b) => (a.business.name.toLowerCase() > b.business.name.toLowerCase()) ? 1 : -1);
                    break;
                case 'price':
                    listOrders.sort((a, b) => (a.order.total_price > b.order.total_price) ? 1 : -1);
                    break;
                case 'date':
                    listOrders.sort((a, b) => (a.order.date - b.order.date) ? 1 : -1);
                    break;
                case 'status':
                    listOrders.sort((a, b) => (a.order.status < b.order.status) ? 1 : -1);
                    break;
                default:
                    break;
            }
        }

        let direction = nextDirection === 'up' ? 'down' : 'up';
        this.setState({
            sortDirection: nextDirection,
            orders: listOrders,
            enableSort: {label: label, direction: direction}
        })
    };

    render() {
        const {t, requestForUnfinishedOrders} = this.props;
        const {orders,sortDirection,enableSort} = this.state;
        const validData = typeof orders !== 'undefined' && this.dataUploaded && !_.isEmpty(orders);
        const emptyListMessage = requestForUnfinishedOrders ? t('orders:any_order_in_progress') : + t('layout:no_data_found');
        return (
            <div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th width="8%"
                                    onClick={() => {
                                    this.sortRecords('id', sortDirection)
                                }}>
                                    <Button
                                    className="link"
                                    label={t('orders:list.id')}
                                    icon={`chevron-${enableSort.label === 'id' ? enableSort.direction : 'right'}`}
                                />
                                </th>
                                <th onClick={() => {
                                    this.sortRecords('number', sortDirection)
                                }}>
                                    <Button
                                        className="link"
                                        label={t('orders:list.number')}
                                        icon={`chevron-${enableSort.label === 'number' ? enableSort.direction : 'right'}`}
                                    />
                                </th>
                                <th onClick={() => {
                                    this.sortRecords('provider', sortDirection)
                                }}>
                                    <Button
                                        className="link"
                                        label={t('orders:list.provider')}
                                        icon={`chevron-${enableSort.label === 'provider' ? enableSort.direction : 'right'}`}
                                    />
                                </th>
                                <th onClick={() => {
                                    this.sortRecords('business', sortDirection)
                                }}>
                                    <Button
                                        className="link"
                                        label={t('orders:list.business')}
                                        icon={`chevron-${enableSort.label === 'business' ? enableSort.direction : 'right'}`}
                                    />
                                </th>
                                <th
                                    onClick={() => {
                                        this.sortRecords('price', sortDirection)
                                    }}>
                                    <Button
                                        className="link"
                                        label={t('orders:list.price')}
                                        icon={`chevron-${enableSort.label === 'price' ? enableSort.direction : 'right'}`}
                                    />
                                </th>
                                <th onClick={() => {
                                    this.sortRecords('date', sortDirection)
                                }}>
                                    <Button
                                        className="link"
                                        label={t('orders:list.date')}
                                        icon={`chevron-${enableSort.label === 'date' ? enableSort.direction : 'right'}`}
                                    />
                                </th>
                                <th onClick={() => {
                                    this.sortRecords('status', sortDirection)
                                }}>
                                    <Button
                                        className="link"
                                        label={t('orders:list.status')}
                                        icon={`chevron-${enableSort.label === 'status' ? enableSort.direction : 'right'}`}
                                    />
                                </th>
                                <th className="actions">
                                    {t('orders:list.actions')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {validData
                                ? orders.map((item) =>
                                    <OrderRow
                                        key={item.order.id}
                                        item={item}
                                    />
                                ) :
                                this.dataUploaded && <tr>
                                    <td className="no-data" colSpan="8">
                                        {emptyListMessage}
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withTranslation()(OrderList);
