import React from 'react';

import { useTranslation } from 'react-i18next';
import ForgotPasswordForm from './Form'

export default () => {
    const { t } = useTranslation('auth');

    return (
        <div className="forgot-password">
            <h3>{t('forgot.title')} </h3>
            <ForgotPasswordForm />
        </div>
    );
}
