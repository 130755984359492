import { breadcrumbConstants } from "./constants";
import { NavLink } from 'react-router-dom';
import React from 'react';

class BreadcrumbActions {
    push(item) {
        const part = this.sanitizeItem(item);
        return part ? (dispatch) => dispatch({type: breadcrumbConstants.PUSH_PART, item: part}) : null;
    }

    pop() {
        return (dispatch) => dispatch({type: breadcrumbConstants.POP_PART});
    }

    clear() {
        return (dispatch) => dispatch({type: breadcrumbConstants.CLEAR});
    }

    set(items) {
        if (items && Array.isArray(items)) {
            items = items.map(item => this.sanitizeItem(item));
        } else {
            items = [this.sanitizeItem(items)];
        }

        return (dispatch) => dispatch({type: breadcrumbConstants.SET, item: items});
    }

    sanitizeItem(item) {
        let part;
        if (typeof item === 'object' && item.hasOwnProperty('$$typeof')) {
            part = item;
        } else if (typeof item === 'string') {
            part = <NavLink to={'#'} key={Math.random() * 10} className={`breadcrumb`}>{item}</NavLink>;
        }

        return part;
    }
}

export const breadcrumbActions = new BreadcrumbActions();
