import React from 'react';
import '../style.scss';
import { NavLink } from 'react-router-dom';
import { connectedRoutes } from 'Layout/Connected/constants';
import { history } from '../../../_helpers/history';
import noImage from '_assets/images/noImage.png';
import ReactTooltip from 'react-tooltip';
import Icon from 'CommonUI/Icon';

export const RowAsCell = ({product, t, businessId, index}) => (
    <div
        className="row div-row"
        onClick={() => { !product.isPunchout &&
        history.push(`${connectedRoutes.BUSINESS}/${businessId}/product/${product.product_id}`)
    }}>
        <div className="product-image-td col-12 col-md-1">
            <img
                src={product.imageUrl || noImage}
                title={product.name}
                alt={product.name}
                className="product-image"
            />
        </div>
        <div className="col-12 col-md-6 d-flex">
            <div className="col-4 cart-column">
                <span className="form-field">{t('orders:product')}</span>
            </div>
            <div className="col-8">
                <p className="bold">
                    {product.isPunchout ?
                        <>
                            <span data-tip data-for={`${index}-cart-icon`}> <Icon name="duplicate" className="cursor-pointer" /> </span>
                            <span className="color-20527a text-decoration-none"> {product.name} </span>
                        </> :
                        <NavLink
                            to={`${connectedRoutes.BUSINESS}/${businessId}/product/${product.product_id}`}
                            className="color-20527a text-decoration-none">
                            {product.name}
                        </NavLink>
                    }
                </p>
                <p>{t('orders:brand')}: {product.brand}</p>
                {/*  <a href="#">{t("orders:delete")}</a>*/}
            </div>
        </div>
        <div className="col-12 col-md-2 d-flex">
            <div className="col-4 cart-column">
                <span className="form-field">{t('orders:delay')}</span>
            </div>
            <div className="col-8">
                {product.delay}
            </div>
        </div>
        <div className="col-12 col-md-2 d-flex">
            <div className="col-4 cart-column">
                <span className="form-field">{t('orders:price')}</span>
            </div>
            <div className="col-8">
                {Math.round(product.price * 100) / 100} {t('business:cart.table.currency_symbol')}
            </div>
        </div>

        <div className="col-12 col-md-1 d-flex">
            <div className="col-4 cart-column">
                <span className="form-field">{t('orders:quantity')}</span>
            </div>
            <div className="col-8">
                {product.quantity}
            </div>
        </div>
        <ReactTooltip
            id={`${index}-cart-icon`}
            effect='solid'
            key={`${index}-cart-icon`}>
            {t('business:tooltip.punchout')}
        </ReactTooltip>
    </div>
);