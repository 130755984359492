import React, { Fragment } from 'react';
import '../style.scss';
import { NavLink } from 'react-router-dom';
import { connectedRoutes } from 'Layout/Connected/constants';
import { history } from '../../../_helpers/history';
import noImage from '_assets/images/noImage.png';
import ReactTooltip from 'react-tooltip';
import Icon from 'CommonUI/Icon';

export const RegularRow = ({product, t, businessId, index}) => (
    <tr
        className="onClickEvent row"
        onClick={() => { !product.isPunchout && !product.isTarifeo && history.push(`${connectedRoutes.BUSINESS}/${businessId}/product/${product.product_id}`)}}>

        <td className="product-image-td col-12 col-md-1">
            <img
                src={product.imageUrl || noImage}
                title={product.name}
                alt={product.name}
                className="product-image"
            />
        </td>

        <td className="col-12 col-md-6">
            <p className="bold">
                {product.isPunchout || product.isTarifeo ?
                    <Fragment key={`${index}-key`}>
                        <span data-tip data-for={`${index}-cart-icon`}> <Icon name="duplicate" className="cursor-pointer" /> </span>
                        <span className="color-20527a text-decoration-none"> {product.name} </span>
                    </Fragment> :
                    <NavLink
                        key={`${index}-link`}
                        to={`${connectedRoutes.BUSINESS}/${businessId}/product/${product.product_id}`}
                        className="color-20527a text-decoration-none">
                        {product.name}
                    </NavLink>
                }
            </p>
            <p>{t('orders:brand')}: {product.brand}</p>
            {/*  <a href="#">{t("orders:delete")}</a>*/}
            <ReactTooltip
                id={`${index}-cart-icon`}
                effect='solid'
                key={`${index}-cart-icon`}>
                {t('business:tooltip.punchout')}
            </ReactTooltip>
        </td>

        <td className="col-12 col-md-2">
            {product.delay}
        </td>

        <td className="col-12 col-md-2">
            {Math.round(product.price * 100) / 100} {t('business:cart.table.currency_symbol')}
        </td>

        <td className="col-12 col-md-1">
            {product.quantity}
        </td>
    </tr>
    );
