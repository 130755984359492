import React from 'react';
import { notificationConstants } from './_store/constants';
import connect from 'react-redux/es/connect/connect';
import Icon from '../Icon';
import './style.scss';

class Notification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notificationList: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.notificationList) {
            this.setState({
                notificationList: nextProps.notificationList
            }, () => {
                const notificationKeys = [];
                notificationKeys.push(Object.keys(this.state.notificationList));
                notificationKeys[0].forEach((notificationKey) => {
                    if (nextProps.notificationList[notificationKey].time > 0) {
                        setTimeout(() => {
                            this.closeNotification(notificationKey);
                        }, nextProps.notificationList[notificationKey].time)
                    }
                });
            });
        }
    }

    closeNotification = (notificationKey) => {
        const { notificationList } = this.state;
        delete notificationList[notificationKey];
        this.setState({ notificationList });
    };

    selectColor = (type) => {
        switch (type) {
            case notificationConstants.TYPE_SUCCESS:
                return 'green';
            case notificationConstants.TYPE_DANGER:
                return 'red';
            case notificationConstants.TYPE_WARNING:
                return 'red';
            case notificationConstants.TYPE_INFO:
                return 'yellow';
            default:
                return 'black';
        }
    };

    onShow = () => {
        const { notificationList: list } = this.state;
        return <div className='alert-container'>
                {Object.keys(list).map(key => (
                    <div key={key} className="alert-item" style={{ backgroundColor: this.selectColor(list[key].type) }}>
                        <span onClick={() => this.closeNotification(key)}>
                            <Icon name="cross" />
                        </span>
                        <span>{list[key].message}</span>
                    </div>
                ))}
            </div>
    };

    render() {
        const { notificationList } = this.state;
        return notificationList && this.onShow();
    }
}

export default connect((state) => {
    const { notificationList } = state.notification;

    return { 
        notificationList,
    };
})(Notification);