import React from 'react';
import '../style.scss';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { punchoutService } from '../_store/service';
import Modal from '../../CommonUI/Modal';
import { ResponsiveContext } from '../../_store/responsive-context';
import { PageHead, PageHeadActions, PageHeadTitle } from '../../CommonUI/PageHead';
import { Screen } from '../../_constants';
import Button from '../../CommonUI/Button';
import PunchoutImportForm from '../PunchoutImportForm';
import { notificationConstants } from 'CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import { Redirect } from 'react-router';
import { businessServices } from '../../Business/_store/services';

class SupplierPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            item: null,
            history: null,
            showModal: false,
            returnBack: false,
            businessId: null,
            business: null,
        };

        this.dispatch = props.dispatch;
    }

    componentDidMount() {
        const {match, dispatch, t} = this.props;
        if (Number.isNaN(Number(match.params.supplierId)) || Number.isNaN(Number(match.params.businessId))) {
            const response = {
                type: notificationConstants.ERROR_RESPONSE,
                result: t('wrong_id_match')
            };

            dispatch(notificationActions.add(response, t('wrong_id_match')));
            this.setState({
                returnBack: true,
            });
        }

        this.setState({
            businessId: Number(match.params.businessId),
        });

        punchoutService.getOnePunchoutSupplier(match.params.supplierId).then(response => {
            this.setState({
                supplier: response.result,
            });
        }).catch((response) => {
            const result = {
                type: notificationConstants.ERROR_RESPONSE,
                result: response.result
            };

            dispatch(notificationActions.add(result, response.result));
        });

        businessServices.getBusiness(match.params.businessId).then(response => {
            this.setState({
                business: response.result,
            });
        }).catch((response) => {
            const result = {
                type: notificationConstants.ERROR_RESPONSE,
                result: response.result
            };

            dispatch(notificationActions.add(result, response.result));
        });
    }

    openModal = () => {
        this.setState({
            showModal: true,
        });
    }

    closeModal = () => {
        this.setState({
            showModal: false,
        });
    }

    render() {
        const {t} = this.props;
        const {supplier, showModal, returnBack, business} = this.state;
        if (!supplier || !business) {
            return <></>;
        }

        if (returnBack) {
            return <Redirect to="/" />;
        }

        return (
            <ResponsiveContext.Consumer>
                {({size}) => (
                    <>
                        <PageHead>
                            <PageHeadTitle>
                                <h2>{t('guide.title', {supplier: supplier.name})}</h2>
                            </PageHeadTitle>
                            <PageHeadActions>
                                <p>
                                    {(size >= Screen.md) && <Button
                                        className="primary round"
                                        label={t('import_basket')}
                                        onClick={this.openModal}
                                    />}
                                    {(size < Screen.md) && <Button
                                        className="primary round"
                                        icon="upload"
                                        onClick={this.openModal}
                                    />}
                                </p>
                            </PageHeadActions>
                        </PageHead>
                        <div className="container">
                            <div className="row">
                                <div className="text-left w-100">
                                    <h2 className="ml-5">{t('guide.subtitle', {business: business.name})}</h2>
                                </div>
                            </div>
                            <ol className="numbered">
                                <li key="li-1">
                                    {t('guide.step_1', {supplier: supplier.name})}
                                </li>
                                <a href={supplier.cartLink} target="_blank">{supplier.cartLink}</a>
                                <li key="li-2">
                                    {t('guide.step_2')}
                                </li>
                                <li key="li-3">
                                    {t('guide.step_3')}
                                </li>
                                <li key="li-4">
                                    {t('guide.step_4')}<br />
                                    <Button
                                        className="primary round"
                                        label={t('import_basket')}
                                        onClick={this.openModal}
                                    />
                                </li>
                            </ol>

                            <Modal
                                className="punchout-import-form"
                                showModal={showModal}
                                title={t('import_basket')}
                                children={<PunchoutImportForm
                                    closeModal={this.closeModal}
                                    showModal={showModal}
                                    supplier={supplier}
                                    businessId={business.id}
                                />}
                                onClose={this.closeModal}
                            />
                        </div>
                    </>
                )}
            </ResponsiveContext.Consumer>
        );
    }
}

export default connect((state) => state)(withTranslation('punchout')(SupplierPage));
