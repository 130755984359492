import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { connectedRoutes } from 'Layout/Connected/constants';
import './style.scss';
import Button from 'CommonUI/Button';
import Icon from 'CommonUI/Icon';
import CreateBusinessForm from '../CreateBusinessForm';
import Modal from 'CommonUI/Modal';
import { withTranslation } from 'react-i18next';
import * as moment from 'moment';
import Progress from 'react-progress-2';
import { businessServices } from '../../_store/services';
import { notificationActions } from '../../../CommonUI/Notification/_store/actions';
import { notificationConstants } from '../../../CommonUI/Notification/_store/constants';
import { BusinessStatus } from '../../_store/constants';
import ReactTooltip from 'react-tooltip';

class Entries extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            businessData: {},
        }
    }

    toggleStatus = (data) => {
        const {t, dispatch} = this.props;
        Progress.show();
        businessServices.updateBusiness(data.id, {business: data})
            .then(
                (response) => {
                    if (response) {
                        sessionStorage.removeItem('business');
                        let business = response.result;
                        if (response.type === notificationConstants.SUCCESS_RESPONSE) {
                            dispatch(notificationActions.add(response, t("notification.success.update_business_part1") + ' ' + business.name + t("notification.success.update_business_part2")));
                        }
                    }
                },
            )
            .finally(() => {
                this.props.forceRefresh();
                Progress.hide();
            });
    }

    toggleModal = (businessData) => {
        const { showModal } = this.state;
        this.setState({
            showModal: !showModal,
            businessData,
        });
    };

    render() {
        const { list, listIsFiltered, t, filterStatus } = this.props;
        const { showModal, businessData } = this.state;

        return (
            <div className="business-list row">
                {list ? (
                    list.map(({
                        id,
                        name,
                        localisation,
                        illustrationPath,
                        startDate,
                        country,
                        city,
                        addressTitle,
                        address,
                        postalCode,
                        additionalInfo,
                        secondCountry,
                        secondCity,
                        secondAddressTitle,
                        secondAddress,
                        secondPostalCode,
                        secondAdditionalInfo,
                        status,
                    }) => {
                        const formatStartDate = moment(startDate, 'DD-MM-YYYY').format('DD/MM/YYYY');

                        return (filterStatus === BusinessStatus.ACTIVE && status === BusinessStatus.ACTIVE) || filterStatus === BusinessStatus.CLOSED ? <div key={id} className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="business-block">
                                <NavLink to={`${connectedRoutes.BUSINESS}/${id}`}>
                                    <div className="image">
                                        {illustrationPath ? (
                                            <img src={`${process.env.REACT_APP_API_URL}/${illustrationPath}`} alt={name} />
                                        ) : (
                                            <Icon name="camera" />
                                        )}
                                    </div>
                                </NavLink>

                                <div className="description">
                                    <div className="description__title">
                                        <NavLink to={`${connectedRoutes.BUSINESS}/${id}`}>
                                            <h3 id={`business_name_${id}`}>{name}</h3>
                                        </NavLink>
                                    </div>

                                    <span className="description__option"><Icon name="location" /> {localisation}</span>
                                    <span className="description__option"><Icon name="calendar" /> {formatStartDate}</span>

                                    <Button
                                        className="button action round status-trigger"
                                        id={id}
                                        key={`${id}-status`}
                                        data-tip="data-tip"
                                        data-for={`${id}-status-tooltip`}
                                        icon={status === BusinessStatus.ACTIVE ? `cross` : 'preview'}
                                        onClick={() => this.toggleStatus({
                                            id,
                                            name,
                                            localisation,
                                            illustrationPath,
                                            startDate,
                                            country,
                                            city,
                                            addressTitle,
                                            address,
                                            postalCode,
                                            additionalInfo,
                                            secondCountry,
                                            secondCity,
                                            secondAddressTitle,
                                            secondAddress,
                                            secondPostalCode,
                                            secondAdditionalInfo,
                                            status: status === BusinessStatus.ACTIVE ? BusinessStatus.CLOSED : BusinessStatus.ACTIVE,
                                        })}
                                    />

                                    <ReactTooltip
                                        id={`${id}-status-tooltip`}
                                        effect='solid'
                                        key={`${id}-status-tooltip`}>
                                        {status === BusinessStatus.ACTIVE ? t('business:tooltip.status.close') : t('business:tooltip.status.activate') }
                                    </ReactTooltip>

                                    <Button
                                        className="button action round edit-trigger"
                                        id={id}
                                        icon="pencil"
                                        onClick={() => {
                                            this.toggleModal({
                                                id,
                                                name,
                                                localisation,
                                                illustrationPath,
                                                startDate,
                                                country,
                                                city,
                                                addressTitle,
                                                address,
                                                postalCode,
                                                additionalInfo,
                                                secondCountry,
                                                secondCity,
                                                secondAddressTitle,
                                                secondAddress,
                                                secondPostalCode,
                                                secondAdditionalInfo,
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div> : <div key={id} />
                    })
                ) : list && (
                    <h1 className="text-center">
                        {listIsFiltered ? t('layout:no_data_found') : t('empty_business_list')}
                    </h1>
                )}
                <Modal
                    showModal={showModal}
                    title={t('edit_business')}
                    overflow={true}
                    children={<CreateBusinessForm
                    businessData={businessData}
                    closeModal={this.toggleModal}
                    />}
                    onClose={() => this.toggleModal({})}
                />
            </div>
        );
    }
}

export default connect((state) => {
    const { list, listIsFiltered } = state.business;
    return { list, listIsFiltered };
})(withTranslation('business')(Entries))
