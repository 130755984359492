import React, { Fragment } from 'react';
import routes from './routes';
import { connect } from 'react-redux';
import { businessActions } from '../_store/actions';
import { notificationConstants } from 'CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';

class Router extends React.Component {
    constructor(props) {
        super(props);
        this.dispatch = props.dispatch;
        this.state = {
            choosingProduct: false,
            choosingBusiness: false,
            choosingCategory: false,
            choosingSubCategory: false
        };
    }

    componentDidMount() {
        if (this.props.businessState) {
            this.load(this.props);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.businessState) {
            this.load(nextProps)
        }
    }

    load = (props) => {
        /*check/set chosen business state*/
        if (props.params.id && this.state.choosingBusiness === false) {
            if (props.businessState.chosenBusiness == null || props.businessState.chosenBusiness.id !== parseInt(props.params.id)) {
                this.setState({choosingBusiness: true}, () => {
                    this.dispatch(businessActions.setChosenBusiness(props.params.id))
                        // .then(
                        //     (response) => {
                        //         if (response) {
                        //             if (response.type === notificationConstants.ERROR_RESPONSE) {
                        //                 this.dispatch(notificationActions.add(response));
                        //             }
                        //         }
                                this.setState({choosingBusiness: false});
                            // });
                });
            }
        }

        /*check/set chosen subcategory state*/
        if (props.params.subcategoryId && this.state.choosingSubCategory === false) {
            if (props.businessState.chosenSubcategory == null || props.businessState.chosenSubcategory.id !== parseInt(props.params.subcategoryId)) {
                this.setState({choosingSubCategory: true}, () => {
                    this.dispatch(businessActions.setChosenSubcategory(props.params.subcategoryId));
                    this.setState({choosingSubCategory: false});
                        // .then(
                        //     (response) => {
                                // if (response) {
                                //     if (response.type === notificationConstants.ERROR_RESPONSE) {
                                //         this.dispatch(notificationActions.add(response));
                                //     }
                                // }

                            // });
                });
            }
        }

        /*check/set chosen category state*/
        if (props.params.subcategoryId && this.state.choosingCategory === false) {
            if (props.businessState.chosenCategory == null || (props.businessState.chosenSubcategory != null && props.businessState.chosenSubcategory.id !== parseInt(props.params.subcategoryId))) {
                this.setState({choosingCategory: true}, () => {
                    this.dispatch(businessActions.setChosenCategory(props.params.subcategoryId));
                    this.setState({choosingCategory: false});
                        // .then(
                        //     (response) => {
                        //         if (response && response.type === notificationConstants.ERROR_RESPONSE) {
                        //             this.dispatch(notificationActions.add(response));
                        //         }
                        //
                        //     });

                });
            }
        }

        /*check/set chosen product state*/
        if (props.params.subcategoryId && props.params.productId && this.dispatch && this.state.choosingProduct === false) {
            if ((props.businessState.chosenProduct == null || props.businessState.chosenProduct._id !== parseInt(props.params.productId))) {
                this.setState({choosingProduct: true}, () => {
                    this.dispatch(businessActions.setChosenProduct(props.params.productId))
                        .then(
                            (response) => {
                                    if (response && response.type === notificationConstants.ERROR_RESPONSE) {
                                        this.dispatch(notificationActions.add(response));
                                    }
                                this.setState({choosingProduct: false});
                            }
                        );
                })
            }
        }
        /*check/set categories*/
        if (props.businessState.categories == null) {
            this.dispatch(businessActions.getCategories());
        }
    };

    render() {
        return (
            <Fragment>
                {routes}
            </Fragment>
        );
    }
}

export default connect(
    (state) => {
        const {
            business
        }
            = state;
        return {businessState: business};
    })(Router);
