export function getBusinessesNames(businesses) {
    let names = [];
    if (typeof businesses !== "string") {
        businesses.forEach((item, index) => {
            names.push({id: index + 1, name: item.name});
        });
    }

    return names;
}

export function getBusinessesLocalisations(businesses) {
    let locations = [];
    if (typeof businesses !== "string") {
        businesses.forEach((item) => {
            locations.push({name: item.localisation});
        });
        locations = Array.from(new Set(locations.map(JSON.stringify))).map(JSON.parse);
        locations.forEach((item, index) => {
            item.id = index + 1;
        });
    }
    return locations;
}
