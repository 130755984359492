import React from 'react';
import '../style.scss';
import Access from '_rbac/Access';
import Icon from 'CommonUI/Icon';

export default ({user, userToRemove, editUser, deleteUser}) => {
    return (
        <div>
            <div onClick={editUser}>
                <Icon name="pencil"/>
            </div>

            <Access
                role={user.role}
                perform="users:delete"
                data={{
                    userToRemoveRole: userToRemove.role
                }}
                yes={() => (
                    <div onClick={deleteUser}>
                        <Icon name="cross"/>
                    </div>
                )}
            />
        </div>
    );
};
