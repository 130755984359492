import { profileConstants } from './constants';
import { profileServices } from './services';

class ProfileActions {
    update(user) {
        return (dispatch) => {
            return profileServices.update(user).then(
                response => {
                    dispatch({type: profileConstants.SET_PROFILE, user: response.result});
                    return response;
                })
        };
    }

}

export const profileActions = new ProfileActions();
