import React, { Component } from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';
import Alert from 'CommonUI/Alert';
import OrderList from './OrderList';
import { orderServices } from './_store/services';
import Progress from 'react-progress-2';
import Button from 'CommonUI/Button';
import Filters from './Filters';
import { notificationConstants } from 'CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import { PageHead, PageHeadTitle, PageHeadActions, PageHeadFilters } from 'CommonUI/PageHead';
import { connect } from 'react-redux';
import _ from 'lodash';
import { orderConstants } from './_store/constants';

class Orders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            showFilters: false,
            requestForUnfinishedOrders: true
        };
        this.initialStatusFilter = [
            orderConstants.FINISHED_ID
        ];
    }

    componentDidMount() {
        this.fetchData({filter: {status: this.initialStatusFilter}});
    }

    toggleFilters = () => {
        const { showFilters } = this.state;
        this.setState({ showFilters: !showFilters });
    };


    fetchData(filter = {}) {
        const { dispatch } = this.props;
        Progress.show();
        filter.status = orderConstants.RECEIVED;
        return orderServices.getList(filter)
            .then((response) => {
                if (response) {
                    if (response.type === notificationConstants.SUCCESS_RESPONSE) {
                        this.setState({
                            orders: response.result,
                            requestForUnfinishedOrders: this.isRequestForUnfinishedOrders(filter)
                        });
                    } else {
                        dispatch(notificationActions.add(response));
                    }
                    Progress.hide();
                }
            });
    }

    isRequestForUnfinishedOrders({filter}) {
        if (filter && filter.status) {
            return _.isEqual(filter.status.sort(), this.initialStatusFilter.sort());
        } else {
            return false;
        }
    }

    render() {
        const { t, location: { status } } = this.props;
        const { showFilters, orders, requestForUnfinishedOrders } = this.state;
        const orderStatus = status && status.orderStatus ? status.orderStatus : null;

        return (
            <div className="page page--orders">
                <PageHead>
                    <PageHeadTitle>
                        <h2>{t('orders:my_archives_orders')}</h2>
                    </PageHeadTitle>
                    <PageHeadActions>
                        <Button
                            className="link"
                            label={t('layout:filters')}
                            onClick={this.toggleFilters}
                            icon={`chevron-${showFilters ? 'up' : 'down'}`}
                        />
                    </PageHeadActions>
                    <PageHeadFilters isOpened={showFilters}>
                        <Filters
                            fetchData={this.fetchData.bind(this)}
                            toggleFilter={this.toggleFilters}
                        />
                    </PageHeadFilters>
                </PageHead>

                <div className="container">
                    {orderStatus === 'created' && <Alert type='success' message={t('orders:actions.create.success')} />}

                    <OrderList
                        orders={orders}
                        requestForUnfinishedOrders={requestForUnfinishedOrders}
                    />
                </div>
            </div>
        );

    }
}

export default connect()(withTranslation()(Orders));
