import React from 'react';
import FormField from '../FormField';
import { Field } from 'formik';
import './style.scss';

import Icon from 'CommonUI/Icon';

const Checkbox = ({type, label, ...props}) => (
    <FormField fieldType="checkbox" {...props}>
        <div className="checkbox">
            <label>
                <Field className="form-control" type="checkbox" {...props} />
                <span className="cb"><Icon name="checkmark" /></span>
                {label}
            </label>
        </div>
    </FormField>
);

export default Checkbox;