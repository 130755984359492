import cookies from "react-cookies";
import {httpService} from "../../_network/http/service";
import {saveUserCookie} from '_helpers/cookies';
import {notificationConstants} from "../../CommonUI/Notification/_store/constants";


/**
 * @type {{login: (function(*=, *=)), logout: (function())}}
 */
class AuthServices {

    constructor() {
        this.cookies = cookies;
    }

    /**
     *
     * @param username
     * @param password
     * @param rememberMe
     * @returns {Promise.<TResult>}
     */
    login(username, password, rememberMe) {
        return httpService.post(`/user/login`, {username, password}, "/vsbridge")
            .then((response) => {
                if (response.type === notificationConstants.ERROR_RESPONSE) {
                    return response;
                }
                const token = response.result;
                const refreshToken = response.meta.refreshToken;
                localStorage.setItem('rememberMe', rememberMe);

                let cookieOptions = {path: '/'};
                if (rememberMe === true) {
                    cookieOptions = {path: '/', maxAge: process.env.REACT_APP_TOKEN_TTL}
                }
                this.cookies.save("token", token, cookieOptions);
                this.cookies.save("refreshToken", refreshToken, cookieOptions);

                return httpService.get(`/user/me`, {}, "/vsbridge")
                    .then((response) => {
                        if (response.type === notificationConstants.SUCCESS_RESPONSE) {
                            delete response.result.address;
                            saveUserCookie(response.result);
                        }
                        return response;
                    });
            })
    }

    /**
     * @returns {boolean}
     */
    logout() {
        cookies.remove('user', {path: '/'});
        cookies.remove('token', {path: '/'});
        cookies.remove('refreshToken', {path: '/'});
        return true;
    }

    forgotPassword(email) {
        return httpService.post(`/customer/reset-password-request`, {email}, "/api")
            .then((response) => {
                return response;
            });
    }

    firstPasswordReset(token, password) {
        return httpService.post(`/customer/reset-password/${token}`, {password}, "/api")
            .then((response) => {
                return response;
            });
    }
}

export const authServices = new AuthServices();
