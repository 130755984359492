import rules from "./rules";
import {checkAccess} from "./helpers";

const Access = props =>
    checkAccess(rules, props.role, props.perform, props.data)
        ? props.yes()
        : props.no();

Access.defaultProps = {
    yes: () => null,
    no: () => null
};

export default Access;