import React from 'react';
import '../../style.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { connectedRoutes } from 'Layout/Connected/constants';
import Button from '../../../../CommonUI/Button';
import { businessServices } from '../../../_store/services';
import { history } from '_helpers/history';
import Progress from 'react-progress-2';
import { notificationConstants } from '../../../../CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import Categories from '../../Categories';
import { Screen } from '../../../../_constants';
import { Redirect } from 'react-router';
import { ResponsiveContext } from '../../../../_store/responsive-context';

class SearchResult extends React.Component {

    constructor(props) {
        super(props);
        this.dispatch = this.props.dispatch;
        this.state = {
            searchedProducts: props.searchedProducts,
            offset: 0,
            limit: 100,
            searchedParams: {},
            totalCountOfProducts: 0,
            currentCountOfProducts: 0,
            loaded: false,
            isRedirect: false,
        };

    }

    componentDidMount() {
        this.initProducts(this.props);
        history.listen(location => {
            this.getCountOfSearchedProducts(location.data);
            this.setState({
                searchedParams: location.data
            });
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.initProducts(nextProps);
    }

    initProducts (props) {
        if (props !== undefined && props.searchedProducts !== undefined) {
            let totalCountOfProducts = props.searchedProducts.length || 0;
            let currentCountOfProducts = props.searchedProducts.length || 0;
            this.setState({
                totalCountOfProducts,
                currentCountOfProducts,
                searchedProducts: props.searchedProducts,
                loaded: true,
            });
        }
    }

    getCountOfSearchedProducts = (data) => {
        if (data && data.filter) {
            data.filter.limit = 9999999;
            businessServices.searchProducts(data).then((response) => {
                if (response && response.type === notificationConstants.SUCCESS_RESPONSE) {
                    let totalCountOfProducts = response.result.length;
                    this.setState({
                        totalCountOfProducts
                    }, () => {
                        data.filter.limit = 100;
                    });
                    Progress.hide();
                } else {
                    Progress.hide();
                    this.dispatch(notificationActions.add(response));
                }
            });
        }
    };

    uploadNewProducts = () => {
        Progress.show();
        let newOffset = this.state.offset;
        newOffset += this.state.limit;
        // this.state.searchedParams.filter.offset = newOffset;
        this.setState({
            searchedParams: {
                ...this.state.searchedParams,
                filter: {
                    ...this.state.searchedParams.filter,
                    offset: newOffset,
                },
            },
        });

        let newSearchedParams = this.state.searchedParams;
        businessServices.searchProducts(this.state.searchedParams).then(
            (response) => {
                if (response) {
                    if (response && response.type === notificationConstants.SUCCESS_RESPONSE) {
                        let currentCountOfProducts = this.state.currentCountOfProducts + response.result.length;
                        this.setState({
                            offset: newOffset,
                            searchedParams: newSearchedParams,
                            searchedProducts: [...response.result],
                            currentCountOfProducts
                        });
                        Progress.hide();
                    } else {
                        Progress.hide();
                        this.dispatch(notificationActions.add(response));
                    }
                }
            });
    };

    redirectTo(url) {
        this.setState({isRedirect: url});
    }

    getListStyleProduct(item, t) {
        return (
            <div className="row" key={item.id}>
                <div className="col-md-5">
                    <u>
                        <NavLink
                            to={`${connectedRoutes.BUSINESS}/${this.props.chosenBusiness.id}/product/${item.id}`}
                            className="product-link">
                            {item.product_name}
                        </NavLink>
                    </u>
                </div>
                <div className="col-md-3">
                    {t('product_list.ref')} {item.reference}
                </div>
                <div className="col-md-4">
                    {t('product_list.mark')} {item.brand}
                </div>
            </div>
        );
    }

    getBoxStyleProduct(item, t) {
        return (
            <div
                className="row light-box"
                onClick={() => {this.redirectTo(`${connectedRoutes.BUSINESS}/${this.props.chosenBusiness.id}/product/${item.id}`)}}
                key={item.id}>
                <div className="col-12">
                    <u>{item.product_name}</u>
                </div>
                <div className="col-6">
                    {t('product_list.ref')} {item.reference}
                </div>
                <div className="col-6">
                    {t('product_list.mark')} {item.brand}
                </div>
            </div>
        );
    }

    render() {
        const {t} = this.props;
        return (
            <ResponsiveContext.Consumer>
                {({size}) => (
                    <>
                        {this.state.isRedirect && <Redirect to={this.state.isRedirect} />}
                        <div className="container products">
                            {this.state.loaded &&
                            this.state.currentCountOfProducts > 0 &&
                            this.state.totalCountOfProducts !== 0 &&
                            this.state.searchedProducts &&
                            typeof this.state.searchedProducts !== undefined &&
                            <div>
                                <h4 className="products__title">
                                    {t('catalogue.product_list')} ({this.state.currentCountOfProducts} / {this.state.totalCountOfProducts})
                                </h4>
                                {
                                    size >= Screen.md &&
                                    this.state.searchedProducts.map((product) => this.getListStyleProduct(product.product, t))
                                }
                                {
                                    size < Screen.md &&
                                    this.state.searchedProducts.map((product) => this.getBoxStyleProduct(product.product, t))
                                }
                                {this.state.totalCountOfProducts > 100 &&
                                <Button
                                    className="primary next_list"
                                    onClick={this.uploadNewProducts()}
                                    label={t('product_list.next')}
                                    type="submit"
                                />
                                }
                            </div>
                            }
                            {((this.state.loaded && this.state.currentCountOfProducts === 0) || (this.state.loaded === false && this.state.searchedProducts === undefined)) &&
                            <b>
                                <div className="text-center">{t('product_list.empty')}</div>
                            </b>}
                        </div>
                        <Categories/>
                    </>
                )}
            </ResponsiveContext.Consumer>
        );
    }
}

export default connect((state) => {
    const {searchedProducts, chosenBusiness} = state.business;

    return {
        searchedProducts, chosenBusiness
    };
})(withTranslation('business')(SearchResult));
