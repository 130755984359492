export const orderConstants = {
    WAITING: "En attente",
    VALIDATED: "Validée",
    CANCELED: "Annulée",
    PARTIALLY_RECEIVED: "Partiellement réceptionnée",
    RECEIVED: "Terminée",
    CONFIRM_STATUS: 'ok',
    REJECT_STATUS: 'cancel',
    WAITING_ID: 1,
    VALIDATED_ID: 2,
    CANCELED_ID: 3,
    PARTIALLY_RECEIVED_ID: 4,
    FINISHED_ID: 5,
    SET_ORDERS_FILTERS: "SET_ORDERS_FILTERS",
    SET_ORDERS_STATUSES: "SET_ORDERS_STATUSES",
    SET_ORDERS_SUPPLIERS: "SET_ORDERS_SUPPLIERS",
    CLEAR_ORDERS_FILTERS: "CLEAR_ORDERS_FILTERS"
};