import * as Yup from 'yup';
import {phoneRegExp} from '_helpers/yup-phone';

const PersonalInfoFormSchema = Yup.object().shape({
    firstname: Yup.string()
        .required('layout:form.error.required'),
    lastname: Yup.string()
        .required('layout:form.error.required'),
    email: Yup.string()
        .email('layout:form.error.invalid_email')
        .required('layout:form.error.required'),
    phone: Yup.string()
        .required('layout:form.error.required')
        .matches(phoneRegExp, 'layout:form.error.invalid_phone')
});

export default PersonalInfoFormSchema;