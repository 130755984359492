import {httpService} from "../../_network/http/service";

class BusinessServices {

    /**
     * @param httpService
     */
    constructor(httpService) {
        this.httpService = httpService;
    }

    getList(filters = {}) {
        return this.httpService.post('/businesses', filters)
            .then(response => {
                if (typeof response.type === 'string' && response.type === 'success') {
                    sessionStorage.removeItem('business');
                    let jsonBusiness = JSON.stringify(response);
                    sessionStorage.setItem('business', jsonBusiness);
                }
                return response;
            })
    }

    addProductToCart(productId, businessId, quantity) {
        return this.httpService.post(`/cart/add/${productId}`, {"business_id": businessId, quantity})
            .then(response => {
                return response;
            })
    }

    getCategories() {
        return this.httpService.get('/categories')
            .then(response => {
                if (typeof response.type === 'string' && response.type === 'success') {
                    let jsonCat = JSON.stringify(response.result);
                    sessionStorage.setItem('categories', jsonCat);
                }
                return response;
            })
    }

    getProducts(filter) {
        return this.httpService.post(`/products/list`, filter)
            .then(response => {
                return response;
            })
    }

    searchProducts(filter) {
        return this.httpService.post(`/products/search`, filter)
            .then(response => {
                return response;
            })
    }

    createBusiness(data = {}) {
        return this.httpService.post('/business/create', data)
            .then(response => {
                return response;
            })
    }

    updateBusiness(id, data = {}) {
        return this.httpService.post(`/business/edit/${id}`, data)
            .then(response => {
                return response;
            })
    }


    getListFromCart(businessId) {
        return this.httpService.get(`/cart/${businessId}`)
            .then(response => {
                return response;
            })
    }
    
    verifyTarifeoProducts(businessId) {
        return this.httpService.post(`/cart/${businessId}`)
            .then(response => {
                return response;
            })
    }

    updateCart(cartId, productItemId, data = {}) {
        return this.httpService.post(`/cart/${cartId}/update/${productItemId}`, data)
            .then(response => {
                return response;
            })
    }

    removeProductFromCart(cartId, productItemId) {
        return this.httpService.get(`/cart/${cartId}/remove/${productItemId}`)
            .then(response => {
                return response;
            })
    }

    getProductData(productId) {
        return this.httpService.get(`/product/${productId}`)
            .then(response => {
                return response;
            })
    };

    getBusiness(id) {
        return this.httpService.get(`/business/${id}`);
    }
}

export const businessServices = new BusinessServices(httpService);
