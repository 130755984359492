import React from 'react';
import { Route, Switch } from 'react-router';
import Business from '../../Business';
import Orders from '../../Orders';
import OrderDetails from 'Orders/OrderDetails';
import DownloadOrder from 'Orders/OrderDetails/DownloadOrder';
import Statistics from '../../Statistics';
import Cart from '../../Business/Item/Cart';
import Notfound from '../../NotFound';
import { connectedRoutes } from './constants';
import Basket from '../../Basket';
import Profile from '../../Profile';
import Users from 'Administration/Users';
import ArchivesOrders from '../../ArchivesOrders'
import ArchivesOrderDetails from '../../ArchivesOrders/ArchivesOrderDetails/index';
import DownloadArchivesOrder from '../../ArchivesOrders/ArchivesOrderDetails/DownloadOrder';
import Unauthorized from '../../Unauthorized';
import SupplierPage from '../../Punchout/SupplierPage';

export default (
    <Switch>
        <Route path={connectedRoutes.DASHBOARD} exact component={Business}/>
        <Route path={`${connectedRoutes.BUSINESS}/:id/${connectedRoutes.CART}/:businessId`} component={Cart}/>
        <Route path={`${connectedRoutes.BUSINESS}/:id`} component={Business}/>
        <Route path={connectedRoutes.BUSINESS} component={Business}/>
        <Route path={`${connectedRoutes.ORDER}/download/:token`} component={DownloadOrder}/>
        <Route path={`${connectedRoutes.ORDER}/:id`} component={OrderDetails}/>
        <Route path={connectedRoutes.ORDER} component={Orders}/>
        <Route path={connectedRoutes.BASKET} component={Basket}/>
        <Route path={connectedRoutes.STATISTICS} component={Statistics}/>
        <Route path={connectedRoutes.USER} component={Profile}/>
        <Route path={connectedRoutes.USERS_LIST} component={Users}/>
        <Route path={`${connectedRoutes.ORDER_ARCHIVES}/download/:token`} component={DownloadArchivesOrder}/>
        <Route path={`${connectedRoutes.ORDER_ARCHIVES}/:id`} component={ArchivesOrderDetails}/>
        <Route path={connectedRoutes.ORDER_ARCHIVES} component={ArchivesOrders}/>
        <Route path={`${connectedRoutes.PUNCHOUT}/:businessId/:supplierId`} component={SupplierPage} />
        <Route path="/dashboard/unauthorized" component={Unauthorized}/>
        <Route component={Notfound}/>
    </Switch>
);
