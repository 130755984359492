import { httpService } from '../../_network/http/service';

class PunchoutService {

    /**
     * @param httpService
     */
    constructor(httpService) {
        this.httpService = httpService;
    }

    importBasket(supplier, business, file) {
        return this.httpService.post('/punchout/import', {supplier, business, file});
    }

    getPunchoutSuppliersList() {
        return this.httpService.get('/supplier/punchout/list');
    }

    getOneSupplier(id) {
        return this.httpService.get(`/supplier/${id}`);
    }

    getOnePunchoutSupplier(id) {
        return this.httpService.get(`/supplier/punchout/${id}`);
    }
}

export const punchoutService = new PunchoutService(httpService);