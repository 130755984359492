import React from 'react';
import './style.scss';
import {withTranslation} from 'react-i18next';
import {connect} from "react-redux";
import Icon from "../../../../CommonUI/Icon";
import {Form, Formik} from "formik";
import Button from "../../../../CommonUI/Button";
import {TextInput} from "../../../../CommonUI/FormFields";
import {businessServices} from "../../../_store/services";
import {notificationActions} from "CommonUI/Notification/_store/actions";
import Progress from "react-progress-2";
import {basketActions} from 'Basket/_store/actions'
import {basketServices} from "../../../../Basket/_store/services";
import {notificationConstants} from "../../../../CommonUI/Notification/_store/constants";
import schema from "./schema";

class Suppliers extends React.Component {

    constructor(props) {
        super(props);
        this.dispatch = props.dispatch;
        this.state = {
            variants: props.variants,
            alertS: false
        };
        this.quantityValues = {};
    }

    handleSubmit(values, {setSubmitting}) {
        const {variant, quantity} = values;
        const {t, dispatch, chosenBusiness} = this.props;
        Progress.show();
        businessServices.addProductToCart(variant, chosenBusiness.id, quantity)
            .then((response) => {
                    if (response) {
                        dispatch(notificationActions.add(response, t('notification.success.add_product')));
                        this.updateCountOfCarts();
                    }
                })
            .finally(() => {
                setSubmitting(false);
                Progress.hide();
            });
    }

    updateCountOfCarts = () => {
        basketServices.fillCountOfCarts(this.props.dispatch);
    }

    initInputs = (variant_id) => {
        this.quantityValues[`quantity_${variant_id}`] = 1;
    };

    render() {
        const {t, variants} = this.props;
        return (
            variants &&
            <div className="suppliers-container">

                <p className="title">
                    {t('product.suppliers.title')}
                </p>

                <div className="row justify-content-center">
                    {variants.map((variant, index) => {
                        return (
                            <div key={index} className="col-12 col-sm-12 col-md-4 m-10-0">
                                <div className="supplier-container">
                                    <p className="supplier-name">{variant.supplier_name}</p>
                                    <div className="price-details-container" >
                                        <span className="money-symbol">{t('business:cart.table.currency_symbol')}
                                        </span>
                                        <span className="price">&nbsp;{(variant.price).toFixed(2) } </span>
                                        <span className="price-black">(HT)</span>
                                        {variant.days ?
                                            <span className="days">{variant.days}
                                                <Icon name="clock">{t('business:cart.table.currency_symbol')}</Icon>
                                            </span> :
                                            <span className="days">
                                                {t('product.suppliers.days_null')}&nbsp;
                                                <Icon name="clock">{t('business:cart.table.currency_symbol')}</Icon>
                                            </span>
                                        }
                                    </div>
                                    {
                                        <Formik
                                            initialValues={{quantity: 1, variant: variant.id}}
                                            onSubmit={this.handleSubmit.bind(this)}
                                            validationSchema={schema}
                                            validateOnBlur={true}
                                        >
                                            {({errors, touched, isSubmitting}) => (
                                                <Form className="">
                                                    <div className="row padding-0-20 mt-10">
                                                        <div className="col-xs-12 col-sm-6 text-right">
                                                            <label
                                                                className="quantity-label">{t('product.suppliers.quantity')}</label>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-4">
                                                            <TextInput
                                                                type="text"
                                                                min={1}
                                                                name="quantity"
                                                                error={errors.quantity ? t(errors.quantity) : ''}
                                                                touched={touched.quantity}
                                                            />
                                                        </div>
                                                        <TextInput
                                                            type="hidden"
                                                            name="variant"
                                                        />
                                                    </div>
                                                    <div className="text-center mb-20">
                                                        <Button
                                                            className="primary"
                                                            isLoading={isSubmitting}
                                                            label={t('product.suppliers.add_to_cart')}
                                                            type="submit"
                                                        />
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    }
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default connect((state) => {
    const {chosenBusiness} = state.business;
    return {chosenBusiness};
})(withTranslation('business')(Suppliers));
