import React from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { connectedRoutes } from 'Layout/Connected/constants';
import _ from 'lodash';

class Categories extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            affairId: null
        };
    }

    componentDidMount() {
        if (this.props && this.props.chosenBusiness) {
            this.setState({
                affairId: this.props.chosenBusiness.id
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.match) {
            this.setState({
                affairId: nextProps.match.params.id
            });
        }
    }

    render() {
        const {t, chosenBusiness, categories} = this.props;
        const {affairId} = this.state;
        return (
            <div className="container categories">
                {chosenBusiness && affairId && categories &&
                <h4 className="categories__title">{t('categories_title')}</h4>
                }
                <div className="categories__container">
                    {chosenBusiness && affairId && categories && (
                        categories.map((category) => {
                            return (!_.isEmpty(category.children) && (
                                <ul key={category.id} className="category">
                                    <li>
                                        <h3 className="category__title">{category.label}</h3>
                                    </li>

                                    {category.children && category.children.length > 0 && (
                                        category.children.map(subCategory => {
                                            return (
                                                <li key={subCategory.id}>
                                                    <NavLink
                                                        className="category__subtitle"
                                                        to={{
                                                            pathname: `${connectedRoutes.BUSINESS}/${affairId}/catalogue/${subCategory.id}`,
                                                            subCategoryId: subCategory.id
                                                        }}
                                                    >
                                                        {subCategory.label}
                                                    </NavLink>
                                                </li>

                                            );
                                        })
                                    )}
                                </ul>
                            ));
                        })
                    )}
                </div>
            </div>
        );
    }
}

export default connect((state) => {
    const {categories, chosenBusiness} = state.business;

    return {
        categories,
        chosenBusiness,
    };
})(withTranslation('business')(Categories));
