import React from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { TextInput, Chooser } from 'CommonUI/FormFields';
import Button from 'CommonUI/Button';
import { notificationConstants } from '../../../CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import { businessActions } from '../../_store/actions';
import { connectedRoutes } from 'Layout/Connected/constants';
import { history } from '_helpers/history';
import Progress from 'react-progress-2';

class Search extends React.Component {

    constructor(props) {
        super(props);
        this.dispatch = props.dispatch;
        this.state = {
            productsList: {},
            countOfProducts: 0
        };
        // businessServices.getCategories();
    }

    getOptionLabel = (option) => {
        const {label, parent} = option;
        return (
            <div className={`catalog-option ${parent ? 'subcategory' : 'category'}`}>
                {label}
                {parent && <small className="catalog-option__parent">{parent.label}</small>}
            </div>
        );
    };

    filterOption = (option, inputValue) => {
        const {data} = option;
        const label = (data.label || '').toLowerCase();
        const parent = ((data.parent ? data.parent.label : '') || '').toLowerCase();
        const value = (inputValue || '').toLowerCase();

        return label.includes(value) || parent.includes(value);
    };

    handleSubmit = ({category, searchString}, {resetForm, setSubmitting}) => {
        Progress.show();
        if (searchString.length >= 3) {
            let data = {};
            data.filter = {};
            if (category && category.hasOwnProperty('parent')) {
                if (category.parent === null && category.value !== 0) {
                    data.filter.category_id = category.value;
                } else {
                    data.filter.subcategory_id = category.value;
                }
            }
            if (searchString) {
                data.filter.searched_data = searchString;
            }

            this.dispatch(businessActions.setSearchedProducts(data))
                .then(
                (response) => {
                    if (response && response.type === notificationConstants.SUCCESS_RESPONSE) {
                        history.push({
                            pathname: `${connectedRoutes.BUSINESS}/${this.props.businessId}/search-products-result`,
                            data: data,
                            count: this.state.countOfProducts
                        });
                        Progress.hide();
                    } else {
                        Progress.hide();

                        this.dispatch(notificationActions.add(response));
                    }
                },
            );
            resetForm();
        } else {
            let response = {};
            response.type  = notificationConstants.ERROR_RESPONSE;
            response.result = this.props.t('notification.fail.search');
            this.dispatch(notificationActions.add(response));
            Progress.hide();
        }
        setSubmitting(false);
    };

       render() {
        const {t, categories} = this.props;
        const defaultOption = {
            value: 0,
            label: t('search.all_categories'),
            parent: null,
        };

        const cats = categories || [];
        const options = cats.reduce((acc, {id, name, subcategories = []}) => {
            acc.push({
                value: id,
                label: name,
                parent: null,
            });

            if (subcategories.length) {
                subcategories.forEach(({id: _id, name: _name}) => {
                    acc.push({
                        value: _id,
                        label: _name,
                        parent: {
                            value: id,
                            label: name
                        },
                    });
                });
            }
            return acc;
        }, [defaultOption]);


        return (
            <Formik
                initialValues={{
                    category: 0,
                    searchString: '',
                }}
                onSubmit={this.handleSubmit}
            >
                {({errors, touched, isSubmitting, resetForm}) => (
                    <Form>
                        <div className="search-input-group">
                            <Chooser
                                name="category"
                                options={options}
                                value={defaultOption}
                                getOptionLabel={this.getOptionLabel}
                                filterOption={this.filterOption}
                                id="searchCategoriesSelect"
                            />
                            <TextInput
                                type="text"
                                name="searchString"
                                autoComplete="off"
                                error={errors.searchString}
                                touched={touched.searchString}
                                placeholder={t('search.search_string')}

                            />
                            <Button
                                isLoading={isSubmitting}
                                className="primary responsive-width responsive-mb-1"
                                type="submit"
                                label="Recherche"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        );
    };
}

export default connect((state) => {
    const {categories} = state.business;
    return {
        categories
    };
})(withTranslation('business')(Search));
