import React from 'react';
import { Formik, Form } from 'formik';
import { TextInput } from 'CommonUI/FormFields';
import { withTranslation } from 'react-i18next';
import ResetPasswordSchema from './schema';
import Button from 'CommonUI/Button';
import { connect } from 'react-redux';
import { profileServices } from 'Profile/_store/services';
import { notificationActions } from 'CommonUI/Notification/_store/actions';

class ResetPasswordForm extends React.Component {
    handleSubmit(values, {setSubmitting}) {
        const {t, dispatch} = this.props;
        profileServices.changePassword(values.password)
            .then(
                (response) => {
                    if (response) {
                        dispatch(notificationActions.add(response, t('actions.change_password.success')));
                    }
                    setSubmitting(false)
                });
    }

    render() {
        const {t} = this.props;
        let initialValues = {password: '', repeatPassword: ''};
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={(values, {resetForm, setErrors, setSubmitting}) => {
                    const {t, dispatch} = this.props;
                    profileServices.changePassword(values.password)
                        .then(
                            (response) => {
                                if (response) {
                                    dispatch(notificationActions.add(response, t('actions.change_password.success')));
                                }
                                setSubmitting(false)
                            });
                        resetForm();
                    }}
                validationSchema={ResetPasswordSchema}
            >
                {({errors, touched, isSubmitting}) => (
                    <Form className="form-inline user-form">
                        <h3 className="area-title">
                            <span>
                                {t('change_password')}
                            </span>
                        </h3>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="form-label">
                                    {t('new_password')}
                                </label>
                            </div>
                            <div className="col-md-9">
                                <TextInput
                                    type="password"
                                    name="password"
                                    error={errors.password ? t(errors.password) : ''}
                                    touched={touched.password}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="form-label">{t('repeat_new_password')}</label>
                            </div>
                            <div className="col-md-9">
                                <TextInput
                                    type="password"
                                    name="repeatPassword"
                                    error={errors.repeatPassword ? t(errors.repeatPassword) : ''}
                                    touched={touched.repeatPassword}
                                />
                            </div>
                        </div>
                        <div className="text-right">
                            <Button
                                className="primary"
                                isLoading={isSubmitting}
                                label={t('layout:confirm')}
                                type="submit"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default connect((state) => {
    return {user: state.profile.user};
})(withTranslation('user')(ResetPasswordForm));
