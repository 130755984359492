import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const FormField = ({ label, error, touched, fieldType, className, children }) => {

    let fieldClassName  = `form-field form-field--${fieldType}`;

    if (className) {
        fieldClassName += ` ${className}`;
    }

    if (error && error.length && touched) {
        fieldClassName += ' has-error';
    }

    return(
        <div className={fieldClassName}>
            {label && <label className="form-label">{label}</label>}
            {children}
            {error && touched ? ( <div className="error-message">{error}</div> ) : null}
        </div>
    );
}

FormField.propTypes = {
    label: PropTypes.string,
    labelFor: PropTypes.string,
    name: PropTypes.string,
    errors: PropTypes.any,
    touched: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.any
};

export default FormField;
