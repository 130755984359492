import React from 'react';
import SignInForm from './Form';
import { useTranslation } from 'react-i18next';

export default () => {
    const { t } = useTranslation('auth');

    return (
        <div className="sign-in">
            <h3>{t('signin.title')}</h3>
            <SignInForm />
        </div>
    );
}
