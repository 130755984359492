import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import BackButton from 'Orders/OrderDetails/BackButton';

export default ({orderNumber}) => {
    const {t} = useTranslation('orders');

    return (
        <div className="container responsive-container">
            <div className="row justify-content-between">
                <div className="col-10 col-md-7 order-header">
                    <span> {t('my_orders')} > </span>
                    <span className="blue-text">  {t('order_number')} {orderNumber} </span>
                </div>
                <div className="col-2 col-md-5">
                    <BackButton />
                </div>
            </div>
        </div>
    );
};
