import React from 'react';
import './style.scss';
import { DateRange } from 'react-date-range';
import { convertDateToString } from '_helpers/dateHelper';
import Icon from '../Icon';

class CustomDateRange extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDateRange: false,
            startDate: null,
            endDate: null
        }
    }

    toggleDateRange = () => {
        this.props.showDateRangeHandler(!this.props.showDateRange);
    };

    onChange(data) {
        const {onChange} = this.props;
        let startDate = convertDateToString(data.startDate._d);
        let endDate = convertDateToString(data.endDate._d);
        this.setState(() => {
            return {
                endDate: endDate,
                startDate: startDate
            }
        });
        onChange(startDate, endDate);
    }


    clearDateRange() {
        const {onChange} = this.props;
        this.setState(() => {
            return {
                endDate: null,
                startDate: null
            }
        });
        onChange(null, null);
    }

    render() {
        const {startDate, endDate} = this.state;
        const {label, placeholder} = this.props;
        const rangeSelected = startDate && endDate;
        const initStartDate = startDate ? startDate : convertDateToString(new Date());
        const initEndDate = endDate ? endDate : convertDateToString(new Date());

        const showDateRange = this.props.showDateRange;

        return (
            <div className="form-field custom-date-range">
                <label className="form-label">{label}</label>
                <div className="form-control form-field field"
                >
                    <div className={rangeSelected ? "" : "gray"} id="selection" onClick={this.toggleDateRange}>
                        {rangeSelected ? `${startDate} - ${endDate}` : placeholder}
                    </div>
                    <div id="close" onClick={this.clearDateRange.bind(this)}>
                        <Icon className="left" name="cross"/>
                    </div>
                </div>
                {showDateRange ?
                    <DateRange
                        onChange={this.onChange.bind(this)}
                        lang="fr"
                        startDate={initStartDate}
                        endDate={initEndDate}

                    /> : ""}
            </div>
        )

    }

}

export default CustomDateRange;