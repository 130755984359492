import React from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Search from './Search';
import CategoriesDropdown from './CategoriesDropdown';
import { NavLink } from 'react-router-dom';
import Button from 'CommonUI/Button';
import Router from './Router';
import { connectedRoutes } from '../../Layout/Connected/constants';
import Alert from 'CommonUI/Alert';
import { PageHead, PageHeadTitle, PageHeadActions } from 'CommonUI/PageHead';
import { Screen } from '../../_constants';
import { ResponsiveContext } from '../../_store/responsive-context';
import Progress from 'react-progress-2';
import { punchoutService } from '../../Punchout/_store/service';
import noImage from '_assets/images/noImage.png';
import ReactTooltip from 'react-tooltip';
import { Redirect } from 'react-router';
import { businessServices } from '../_store/services';
import Modal from '../../CommonUI/Modal';
import TarifeoPopup from '../../Tarifeo/TarifeoPopup';
import cookies from 'react-cookies';
import { tarifeoService } from '../../Tarifeo/__store/service';


class BusinessItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            chosenBusiness: null,
            suppliers: [],
            isLoading: true,
            redirectTo: null,
            showModal: false
        };
    }

    componentDidMount() {
        const {t, location, match, nbCarts} = this.props;
        
        punchoutService.getPunchoutSuppliersList().then(response => {
            this.setState({
                suppliers: response.result,
                isLoading: false,
            });
            Progress.hide();
        });

        Progress.show();
        businessServices.getBusiness(this.props.match.params.id).then(response => {
            this.setState({
                chosenBusiness: response.result,
                isLoading: false,
            });
            Progress.hide();
        });
        
        Progress.show();
        tarifeoService.iframeUrl(match.params.id).then(response => {
            this.setState({
                tarifeoIframeUrl: response.result.url,
                tarifeoUniqueId: response.result.uniqueId,
                cartId: response.result.cartId
            });
            Progress.hide();
        })
    }

    redirectTo = (supplierId) => {
        this.setState({
            redirectTo: supplierId,
        });
    }

    prepareSuppliersLogos = () => {
        if (this.state.suppliers && Array.isArray(this.state.suppliers)) {
            return this.state.suppliers.map((item, index) =>
            <div className="supplier-logo" key={index}>
                <img
                    src={item.logo ? item.logo : noImage}
                    key={`${index}-image`}
                    alt="logo"
                    data-tip="data-tip"
                    data-for={`supplier-${index}`}
                    onClick={() => this.redirectTo(item.id)} />
            </div>
            );
        }

        return <></>;
    }
    
    openModal = () => {
        this.setState({
            showModal: true,
        });
    }
    
    closeModal = () => {
        this.setState({
            showModal: false,
        });
    }

    prepareSuppliersTooltips = () => {
        const {t} = this.props;
        if (this.state.suppliers && Array.isArray(this.state.suppliers)) {
            return this.state.suppliers.map((item, index) =>
                <ReactTooltip
                    id={`supplier-${index}`}
                    effect='solid'
                    key={`${index}-tooltip`}>
                    {t('tooltip.supplier', {supplier: item.name})}
                </ReactTooltip>
            );
        }

        return <></>;
    }

    render() {
        const {t, location, match, nbCarts} = this.props;
        const {chosenBusiness, redirectTo, showModal, tarifeoIframeUrl, tarifeoUniqueId, cartId} = this.state;
        const orderStatus = location.state && location.state.orderStatus ? location.state.orderStatus : '';
        const user = cookies.load('user');
        
        if (redirectTo) {
            return <Redirect to={`${connectedRoutes.PUNCHOUT}/${match.params.id}/${redirectTo}`} />;
        }

        return (
            <ResponsiveContext.Consumer>
                {({size}) => (
                    <div className="page page--business">
                        {chosenBusiness &&
                        <PageHead>
                            <PageHeadTitle>
                                <h2>{t('catalogue.title')} {chosenBusiness ? chosenBusiness.name : ''}</h2>
                                <p>{t('catalogue.instruction')}</p>
                                {/*/!*<Breadcrumb />*!/ Postponed for the later*/}
                            </PageHeadTitle>
                            <PageHeadActions>
                                {(size >= Screen.md) &&
                                <Button
                                    to={`${connectedRoutes.BUSINESS}`}
                                    className="primary round"
                                    label={t('catalogue.go_back')}
                                />}
                                {(size < Screen.md) &&
                                <Button
                                    to={`${connectedRoutes.BUSINESS}`}
                                    className="primary round min-width"
                                    icon="reply"
                                />}
                            </PageHeadActions>
                        </PageHead>
                        }
                        <div className="container">
                            <Search businessId={match.params.id}/>
                            <div className="categories-actions">
                                <div className="row">
                                    <div className="col-12 col-md-3 mt-1">
                                        <Button
                                            to={`${connectedRoutes.BUSINESS}/${match.params.id}`}
                                            className={`secondary ${size < Screen.md ? 'responsive-width' : ''} ${this.props.className || ''}`}
                                            type="button"
                                            label={t('browse_product_families')}
                                        />
                                    </div>

                                    <div className="col-12 col-md-2 mt-1">
                                        {nbCarts !== 0 && <Button
                                            to={`${connectedRoutes.BUSINESS}/${match.params.id}/cart`}
                                            className={`tertiary ${size < Screen.md ? 'responsive-width' : ''}`}
                                            type="button"
                                            label={t('my_cart')}
                                        />}
                                    </div>
    
                                    <Modal
                                        className="tarifeo"
                                        showModal={this.state.showModal}
                                        title={t('open_tarifeo')}
                                        width={1200}
                                        children={<TarifeoPopup
                                            closeModal={this.closeModal}
                                            showModal={showModal}
                                            tarifeoIframeUrl={tarifeoIframeUrl}
                                            tarifeoIframeUniqueId={tarifeoUniqueId}
                                            cartId={cartId}
                                        />}
                                        onClose={this.closeModal}
                                    />
    
                                    <div className="col-12 col-md-2 mt-1">
                                        {user.tarifeo !== null &&<Button
                                            className={`tertiary ${size < Screen.md ? 'responsive-width' : ''}`}
                                            type="button"
                                            label={t('open_tarifeo')}
                                            onClick={this.openModal}
                                        />}
                                    </div>
                                    
                                    <div className="col-12 col-sm-12 col-md-5 d-flex">
                                        {this.prepareSuppliersLogos()}
                                        {this.prepareSuppliersTooltips()}
                                    </div>
                                </div>

                            </div>
                            {orderStatus === 'removed' && <Alert type="success" message={t('cart.remove_all_products')}/>}
                        </div>

                        <Router params={match.params && match.params}/>
                    </div>
                )}
            </ResponsiveContext.Consumer>
        );
    }
}

export default connect((state) => {
    const {business, basket} = state;

    return {
        businessState: business,
        nbCarts: basket.nbCarts,
    };
})(withTranslation('business')(BusinessItem));
