import {httpService} from "_network/http/service";

class UserServices {

    getList(filter) {
        return httpService.post("/customers", {filter})
            .then((response) => {
                return response;
            })
            .catch(() => {
                return Promise.reject();
            });
    }

    create(user) {
        const data = {customer: user};
        return httpService.post('/customer/create', data).then((response) => {
            return response;
        })
            .catch((error) => {
                return Promise.reject(error.message);
            });
    }

    update(user) {
        const data = {
            customer: user
        };
        return httpService.post(`/customer/edit/${user.id}`, data).then((response) => {
            return response;
        })
            .catch((error) => {
                return Promise.reject(error.message);
            });
    }

    deleteUser(id) {
        return httpService.delete(`/customer/delete/${id}`).then((response) => {
            return response;
        })
            .catch((error) => {
                return Promise.reject(error.message);
            });
    }
}

export const usersServices = new UserServices(httpService);