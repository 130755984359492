import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { TextInput } from 'CommonUI/FormFields';
import Button from 'CommonUI/Button';
import { NavLink } from 'react-router-dom';
import { authServices } from '../_store/services';

const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('layout:form.error.invalid_email')
        .required('layout:form.error.required')
});
const ForgotPasswordForm = ({t, dispatch}) => {
    return (
        <Formik
            initialValues={{
                email: ''
            }}
            validationSchema={ForgotPasswordSchema}

            onSubmit={({email}, {resetForm, setSubmitting}) => {
                authServices.forgotPassword(email).then((response) => {
                    if (response) {
                        dispatch(notificationActions.add(response, t('notification.success.forgot_password'), 10000
                        ));
                    }
                }).finally(() => {
                    setSubmitting(false);
                    resetForm();
                });
            }}
        >
            {({errors, touched, isSubmitting}) => (
                <Form>
                    <TextInput
                        type="email"
                        name="email"
                        error={errors.email ? t(errors.email) : ''}
                        touched={touched.email}
                        placeholder={t('common.email')}
                    />
                    <Button className="primary block" isLoading={isSubmitting} label={t('forgot.submit')}
                            type="submit"/>
                    <div className="text-right form-field">
                        <NavLink to='/auth'>{t('signin.title')}</NavLink>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default connect()(withTranslation('auth')(ForgotPasswordForm));
