import cookies from "react-cookies";

export function saveUserCookie(user) {
    cookies.save("user", user, getOptions());
}

function getOptions() {
    let cookieOptions = {path: '/'};
    const rememberMe = localStorage.getItem('rememberMe');
    if (rememberMe === 'true') {
        cookieOptions = {path: '/', maxAge: process.env.REACT_APP_TOKEN_TTL}
    }
    return cookieOptions;
}