import React from 'react';
import './style.scss';
import OrderHeader from './OrderHeader';
import GeneralInfoPanel from './GeneralInfoPanel';
import Validation from './Validation';
import { orderServices } from 'ArchivesOrders/_store/services';
import Progress from 'react-progress-2';
import { orderConstants } from 'ArchivesOrders/_store/constants';
import { orderHelpers } from 'ArchivesOrders/_store/helpers';
import { notificationConstants } from '../../CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Screen } from '../../_constants';
import { RowAsCell } from '../../Orders/OrderDetails/ProductsPanel/rowAsCell';
import { RegularRow } from '../../Orders/OrderDetails/ProductsPanel/regularRow';
import { NavLink } from 'react-router-dom';
import { connectedRoutes } from '../../Layout/Connected/constants';
import BackButton from './BackButton';
import { ResponsiveContext } from '../../_store/responsive-context';

class ArchivesOrderDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {item: null, history: null};
        this.dispatch = props.dispatch;
    }

    fetchOrder() {
        Progress.show();
        const itemId = this.props.match.params.id;
        return orderServices.getItem(itemId)
            .then(
                (response) => {
                    if (response) {
                        if (response.type === notificationConstants.SUCCESS_RESPONSE) {
                            let item = response.result;
                            this.setState({item});
                            Progress.hide();
                        } else {
                            Progress.hide();
                            this.dispatch(notificationActions.add(response));
                        }
                    }
                })
    }

    fetchHistory = (token) => {
        if (!token) {
            token = this.state.item.order.details.token;
        }

        Progress.show();
        orderServices.getOrderHistory(token)
            .then(
                (response) => {
                    if (response) {
                        if (response.type === notificationConstants.TYPE_SUCCESS) {
                            let history = orderHelpers.processHistory(response.result);
                            this.setState({...this.state, history: history});
                        } else {
                            this.dispatch(notificationActions.add(response));
                        }
                    }
                    Progress.hide();
                })
            .catch(() => {
                Progress.hide();
            });
    }

    componentDidMount() {
        this.fetchOrder();
    }

    render() {
        const {item, history} = this.state;
        const {t} = this.props;
        const showValidation = item !== null && (item.order.details.status === orderConstants.VALIDATED || item.order.details.status === orderConstants.PARTIALLY_RECEIVED);
        let products, orderTotal, orderToken, businessId;

        if (item !== null) {
            products = item.order.products;
            orderTotal = Math.round(item.order.details.total_price * 100) / 100;
            orderToken = item.order.details.token;
            businessId = item.order.details.business_id;
        } else {
            return <div/>;
        }

        return (
            <ResponsiveContext.Consumer>
                {({size}) => (
                    <div className="content">
                        {item !== null ?
                            <div>
                                <div className="bg-white">
                                    <OrderHeader orderNumber={item.order.details.number}/>
                                    {showValidation ?
                                        <Validation products={item.order.products} orderId={item.order.details.id}
                                                    fetchOrder={this.fetchOrder.bind(this)}/> : ''
                                    }
                                    <GeneralInfoPanel fetchHistory={this.fetchHistory} item={item} history={history}/>
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xs-12 panel-title">
                                            <span className="blue-text">{t('orders:ordered_products')}</span>
                                        </div>
                                    </div>
                                    {size < Screen.md && products.map((product, index) => <RowAsCell product={product} index={index} t={t} key={product.variant_id} businessId={businessId}/>)}
                                    {size >= Screen.md &&
                                    <table className="products-table">
                                        <thead>
                                        <tr className="row">
                                            <th className="col-md-1"></th>
                                            <th className="col-md-6">{t('orders:product')}</th>
                                            <th className="col-md-2">{t('orders:delay')}</th>
                                            <th className="col-md-2">{t('orders:price')}</th>
                                            <th className="col-md-1">{t('orders:quantity')}</th>
                                        </tr>
                                        </thead>
                                        <tbody className="none">
                                        {products.map((product, index) => <RegularRow product={product} index={index} t={t} key={product.variant_id} businessId={businessId} />)}
                                        </tbody>
                                    </table>
                                    }
                                    <div className="d-flex flex-row justify-content-between products-footer">
                                        <NavLink to={`${connectedRoutes.ORDER_ARCHIVES}/download/${orderToken}`} target="_blank">
                                            {t('orders:download')}
                                        </NavLink>
                                        <span>
                                            {t('orders:total')}: {orderTotal.toFixed(2)} {t('business:cart.table.currency_symbol')}
                                        </span>
                                    </div>
                                    <BackButton buttonClassName="m-10-0" />
                                </div>
                            </div>
                            : <div/>
                        }
                    </div>
                )}
            </ResponsiveContext.Consumer>
        );
    }
}
export default connect((state) => state)(withTranslation()(ArchivesOrderDetails));
