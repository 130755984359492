import React from 'react';
import './style.scss';
import Collapse from 'react-collapse';

export const PageHead = ({ title, children }) => {
    return (
        <div className="page--head">
            <div className="container">
                {title &&
                    <div className="title">
                        <h2>{title}</h2>
                    </div>
                }
                {children}
            </div>
        </div>
    )
}

export const PageHeadTitle = ({ className, children, ...pageHeadTitleProps }) => {
    const defaultClassName = 'title';

    pageHeadTitleProps.className = className ? `${defaultClassName} ${className}` : defaultClassName;

    return (
        <div {...pageHeadTitleProps}>
            {children}
        </div>
    )
}

export const PageHeadActions = ({ className, children, ...pageHeadActionsProps }) => {
    const defaultClassName = 'actions';

    pageHeadActionsProps.className = className ? `${defaultClassName} ${className}` : defaultClassName;

    return (
        <div {...pageHeadActionsProps}>
            {children}
        </div>
    )
}

export const PageHeadFilters = ({ className, isOpened, children, ...pageHeadFiltersProps }) => {
    const defaultClassName = 'filters';

    pageHeadFiltersProps.className = className ? `${defaultClassName} ${className}` : defaultClassName;

    return (
        <div {...pageHeadFiltersProps}>
            <Collapse 
                isOpened={isOpened || false}
                springConfig={{stiffness: 120, damping: 17}}
                theme={{
                    collapse: 'filters__collapse',
                    content: 'filters__content'
                }}
            >
                {children}
            </Collapse>
        </div>
    )
}

export default {
    PageHead,
    PageHeadTitle,
    PageHeadActions,
    PageHeadFilters,
};