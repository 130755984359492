import React from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '../../../CommonUI/Button';
import SupplierItem from '../SupplierItem';
import Progress from 'react-progress-2';
import { orderServices } from '../../../Orders/_store/services';
import { notificationConstants } from '../../../CommonUI/Notification/_store/constants';
import { connectedRoutes } from '../../../Layout/Connected/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import { history } from '_helpers/history';
import { basketServices } from '../../../Basket/_store/services';
import { basketActions } from 'Basket/_store/actions';

class SupplierList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            cart: null,
            isSubmitting: false,
        };
        this.data = [];
        this.dataLoaded = false;
        this.deliveryData = [];
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.cartData) {
            this.setState({
                loaded: true,
                cart: nextProps.cartData.cart,
            });
        }
    }

    buildSupplierList() {
        if (!this.dataLoaded) {
            this.data = [];
            this.state.cart.products.forEach(product => this.groupDataByProduct(product));
            this.dataLoaded = true;
        }
    }

    getSupplierTemplate(supplier, expanded = false) {
        return <SupplierItem
            key={supplier.name}
            updateAddress={(address) => this.handleAddressChange(supplier, address)}
            supplier={supplier}
            expanded={expanded}
            addresses={this.state.cart.addresses}/>;
    }

    handleAddressChange(supplier, address) {
        let deliveryData = {};
        this.state.cart.products.forEach(item => {
            if (item.supplier === supplier.name) {
                deliveryData = {
                    name: supplier.name,
                    address: address.address,
                    postalCode: address.postalCode || address.postal_code,
                    city: address.city,
                    country: address.country,
                    additionalInfo: address.additionalInfo,
                    addressTitle: address.addressTitle,
                };
            }
        });

        this.deliveryData[supplier.name] = deliveryData;
    }

    checkDeliveryData() {
        const keys = Object.keys(this.data);
        const deliveryKeys = Object.keys(this.deliveryData);
        keys.forEach(key => {
            if (!this.inArray(key, deliveryKeys)) {
                this.deliveryData[key] = this.getDefaultAddress(key);
            }
        });
    }

    inArray(item, array) {
        let flag = false;
        array.forEach(a => {
            if (a === item) {
                flag = true;
            }
        });

        return flag;
    }

    getDefaultAddress(supplierName) {
        const addresses = this.state.cart.addresses;
        const businessAddress = addresses.businessAddress[0];

        return {
            name: supplierName,
            country: businessAddress.country,
            city: businessAddress.city,
            address: businessAddress.address,
            postalCode: businessAddress.postalCode,
            addressTitle: businessAddress.addressTitle,
            additionalInfo: businessAddress.additionalInfo,
        };
    }

    tryRequest()
    {
        this.checkDeliveryData();
        const {t, dispatch} = this.props;
        Progress.show();
        let newData = [];
        Object.values(this.deliveryData).forEach((item) => newData.push(item));
        let deliveryInfo = {
            cartId: this.props.cartData.cart.id,
            deliveryData: newData,
        };
        orderServices.createNewOrder({deliveryInfo})
            .then(
                (response) => {
                    if (response) {
                        if (response.type === notificationConstants.SUCCESS_RESPONSE) {
                            dispatch(notificationActions.add(response, t('notification.success.create_order')));
                            this.updateCountOfCarts();
                            setTimeout(
                                history.push({
                                    pathname: connectedRoutes.ORDER,
                                    state: {orderStatus: 'created'}
                                }), 200);
                        } else {
                            dispatch(notificationActions.add(response));
                        }
                    }

                }
            ).finally(() => {
            Progress.hide();
        });

    }

    getSuppliers() {
        this.buildSupplierList();
        let first = true;
        let items = [];
        Object.values(this.data).forEach(item => {
            if (first) {
                items.push(this.getSupplierTemplate(item, first));
                first = false;
            } else {
                items.push(this.getSupplierTemplate(item));
            }
        });

        return items;
    }

    groupDataByProduct(product) {
        if (this.arrayHasKey(this.data, product.supplier)) {
            this.data[product.supplier] = {
                qty: this.data[product.supplier].qty + 1,
                price: this.data[product.supplier].price + (product.qty * product.price),
                name: product.supplier,
            };
        } else {
            this.data[product.supplier] = {
                qty: product.qty,
                price: product.price * product.qty,
                name: product.supplier,
            }
        }
    }

    updateCountOfCarts = () => {
        basketServices.fillCountOfCarts(this.props.dispatch);
    }

    arrayHasKey(array, key) {
        return Object.keys(array).includes(key);
    }

    submit() {
        this.tryRequest();
    }

    render() {
        const {t, cartData} = this.props;
        const {
            loaded,
            isSubmitting,
        } = this.state;

        return (
            cartData && loaded &&
            <>
                {this.getSuppliers()}
                <div className="col-12 col-md-12 col-lg-4 text-left m-10-0">
                    <Button
                        className="primary round responsive-width"
                        isLoading={isSubmitting}
                        label={t('modal.save')}
                        type="submit"
                        onClick={() => this.submit()}
                    />
                </div>
            </>
        );
    }
}

export default connect((state) => {
    // console.log(state);
    return state;
})(withTranslation('business')(SupplierList));
