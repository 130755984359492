import {businessConstants} from "./constants";

const initialState = {list: null,
    categories: null,
    chosenBusiness: null,
    chosenCategory: null,
    chosenSubcategory: null,
    chosenProduct: null,
    filterNames: null};

export const business = (state = initialState, action) => {
    let response;
    switch (action.type) {
        case businessConstants.SET_LIST:
            response = {
                ...state,
                list: action.list
            };
            break;
        case businessConstants.SET_CHOSEN_BUSINESS:
            response = {
                ...state,
                chosenBusiness: action.item
            };
            break;
        case businessConstants.SET_CHOSEN_CATEGORY:
            response = {
                ...state,
                chosenCategory: action.item
            };
            break;
        case businessConstants.SET_CHOSEN_SUBCATEGORY:
            response = {
                ...state,
                chosenSubcategory: action.item
            };
            break;
        case businessConstants.SET_CHOSEN_PRODUCT:
            response = {
                ...state,
                chosenProduct: action.item
            };
            break;
        case businessConstants.SET_CATEGORIES:
            response = {
                ...state,
                categories: action.categories
            };
            break;
        case businessConstants.SET_FILTER_NAMES:
            response = {
                ...state,
                filterNames: action.names
            };
            break;
        case businessConstants.SET_FILTER_LOCALISATIONS:
            response = {
                ...state,
                filterLocalisations: action.localisations
            };
            break;
        case businessConstants.SET_LIST_IS_FILTERED:
            response = {
                ...state,
                listIsFiltered: action.filtered
            };
            break;
        case businessConstants.SET_SEARCHED_PRODUCTS:
            response = {
                ...state,
                searchedProducts: action.item
            };
            break;
        default:
            response = state;
            break;
    }

    return response;
};
