import React, {Component} from 'react';
import "./style.scss";
import {withTranslation} from "react-i18next";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import {orderServices} from "../_store/services";
import {notificationConstants} from "../../CommonUI/Notification/_store/constants"; // Import css
import {notificationActions} from "CommonUI/Notification/_store/actions";
import {connect} from "react-redux";
import {orderConstants} from "../_store/constants";

class OrderConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.dispatch = props.dispatch;
        this.getParams();
    }

    getParams() {
        let confirmationData = window.location.href.split("/").slice(-2);
        let orderToken = confirmationData[0];
        let orderStatus = confirmationData[1];


        this.loadAlertConfirmation(orderToken, orderStatus);

    }

    loadAlertConfirmation = (orderToken, orderStatus) => {
        let orderNumber = "";
        let initOrderStatus = "";
        let title = "";
        let message = "";
        if (orderStatus === orderConstants.CONFIRM_STATUS || orderStatus === orderConstants.REJECT_STATUS) {

            orderServices.getOrderDataByToken(orderToken)
                .then(
                    (response) => {
                        if (response) {
                            if (response.type === notificationConstants.SUCCESS_RESPONSE) {
                                orderNumber = response.result.order.details.number;
                                initOrderStatus = response.result.order.details.status_id;
                                if (parseInt(initOrderStatus) === orderConstants.WAITING_ID) {
                                    if (orderStatus === orderConstants.CONFIRM_STATUS) {
                                        title = this.props.t("order_confirmation.confirmation_alert.validation_title");
                                        message = this.props.t("order_confirmation.confirmation_alert.validation_body_part1") + orderNumber + this.props.t("order_confirmation.confirmation_alert.validation_body_part2")
                                    } else {
                                        title = this.props.t("order_confirmation.confirmation_alert.reject_title");
                                        message = this.props.t("order_confirmation.confirmation_alert.reject_body_part1") + orderNumber + this.props.t("order_confirmation.confirmation_alert.reject_body_part2")
                                    }

                                    confirmAlert({
                                        title: title,
                                        message: message,
                                        buttons: [
                                            {
                                                label: this.props.t("order_confirmation.button.confirm"),
                                                onClick: () => orderServices.confirmationOrder(orderToken, orderStatus).then(
                                                    (response) => {
                                                        if (response) {
                                                            if (response.result === true) {
                                                                this.dispatch(notificationActions.add(response, this.props.t("order_confirmation.notification.success.order_confirmation")));
                                                            } else {
                                                                this.dispatch(notificationActions.add(response));
                                                            }
                                                        }
                                                    }
                                                )
                                            },
                                            {
                                                label: this.props.t("order_confirmation.button.reject"),
                                                onClick: () => this.dispatch(notificationActions.add(response,this.props.t("order_confirmation.notification.reject_message"), 10000))
                                            }
                                        ]
                                    })
                                }
                                if (parseInt(initOrderStatus) === orderConstants.VALIDATED_ID || parseInt(initOrderStatus) === orderConstants.CANCELED_ID) {
                                    title = this.props.t("order_confirmation.confirmation_alert.impossible_title");
                                    message = this.props.t("order_confirmation.confirmation_alert.impossible_body_part1") + orderNumber + this.props.t("order_confirmation.confirmation_alert.impossible_body_part2");
                                    confirmAlert({
                                        title: title,
                                        message: message,
                                        buttons: []
                                    })
                                }
                            }
                        }
                    }
                );

        } else {
           return confirmAlert({
               title: this.props.t("order_confirmation.notification.incorrect_page"),
               message: this.props.t("order_confirmation.notification.try_again"),
               buttons: []
           })
        }
    };

    render() {
        return (
            <div className="page page--orders">
                {/*<Header/>*/}
            </div>
        );
    }
}

export default connect()(withTranslation('orders')(OrderConfirmation));
