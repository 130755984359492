import React from 'react';
import { Field } from 'formik';
import FormField from '../FormField';
import Select from 'react-select';

const ChooserTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
        ...theme.colors,
        primary: '#0086cd',
    },
});

const ChooserStyle = {
    container: (base) => ({
        ...base,
        width: '100%',
    }),
    control: (base) => ({
        ...base,
        width: '100%',
        height: 36,
        minHeight: 36,
        borderColor: '#ddd',
        borderRadius: 2,
        borderWidth: 1,
        boxShadow: 'none',
        fontSize: '1.6rem',
        "&:hover": {
            borderColor: '#ddd'
        }
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: 4
    }),
    indicatorSeparator: (base) => ({
        ...base,
        background: 'transparent'
    }),
    option: (provided, state) => ({
        ...provided,
        padding: '4px 8px',
        fontSize: '1.6rem',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: '1px 8px',
    }),
};

const Chooser = ({touched, name, options, className, isLoading, validate, onChange, ...props}) => {
    const defaultCName = 'chooser-container';
    let [cName, setCName] = React.useState(defaultCName);
    let [fName, setFName] = React.useState(className);

    return (
        <FormField
            fieldType="select"
            className={fName}
            isLoading={isLoading}
            {...props}
        >
            <Field name={name} validate={validate} className="asdasd">
                {({field, form}) => {
                    return (
                        <Select
                            name={field.name}
                            onChange={(value) => {
                                form.setFieldValue(field.name, value);
                                if (!!onChange) onChange(value);
                            }}
                            onMenuOpen={() => {
                                setCName(defaultCName + ' z-superior');
                                setFName((className || '') + ' z-superior');
                            }}
                            onMenuClose={() => {setCName(defaultCName); setFName(className || '')}}
                            {...props}
                            options={options}
                            value={options ? options.find(option => option.value === field.value) : null}
                            styles={ChooserStyle}
                            theme={ChooserTheme}
                            className={cName}
                            classNamePrefix="chooser"
                            enableReinitialize={true}
                            placeholder="Sélectionner"
                            noOptionsMessage={() => "Pas d'options"}
                            touched={touched ? touched.name : false}
                        />
                    )
                }
                }
            </Field>
        </FormField>
    )
};

export default Chooser;