import React from 'react';
import FormField from '../FormField';
import './style.scss';
import { Trans } from 'react-i18next';
import uniqid from 'uniqid';

import Button from 'CommonUI/Button';

class FileInput extends React.Component {

    constructor() {
        super();

        this.state = {
            files: []
        };
    }

    handleFileChoosen = (event) => {
        const { onChange } = this.props;
        const data = event.currentTarget.files;

        if (!data || !data.length) { return; }

        const selectedFiles = [];
        for (const file of data) {
            selectedFiles.push(file.name);
        }

        this.setState({ files: selectedFiles });

        // preserve input onChange
        if (onChange && onChange instanceof Function) onChange(data);
    }

    openFileManager = (event) => {
        this.refs.fileInput.click();
    }

    render() {
        const { t, touched, onChange, toggleIcon, ...rest } = this.props;
        const { files } = this.state;
        const uid = uniqid('input-');

        return (
            <FormField fieldType="file" touched={touched} {...rest}>
                <div className="file-input">
                    <div className="file-input__control">
                        <input onChange={this.handleFileChoosen} type="file" {...rest} ref="fileInput" id={uid} />
                        <Button 
                            className="circle" 
                            icon={toggleIcon ? toggleIcon : 'plus'}
                            onClick={this.openFileManager}
                        />
                    </div>
                    <div className="file-input__description">
                        <label htmlFor={uid}>
                            <Trans i18nKey={`commonui:form_fields.file_input.add_file`}>Add a file</Trans>
                        </label>
                        <p>
                            {files.length 
                                ? files.join(', ') 
                                : <Trans i18nKey={`commonui:form_fields.file_input.no_files_choosen`}>No files selected</Trans>
                            }
                        </p>
                    </div>
                </div>
            </FormField>
        );
    }
}

export default FileInput;