import { notificationConstants } from './constants';
import { isString } from 'formik';

class NotificationActions {
    add( response, message, duration = notificationConstants.NOTIFICATION_TIME ) {
        let item = {
            message: (response.type === notificationConstants.SUCCESS_RESPONSE ? message : isString(response.result) ? response.result : ''),
            type: (response.type === notificationConstants.SUCCESS_RESPONSE ? notificationConstants.SUCCESS_RESPONSE : notificationConstants.ERROR_RESPONSE),
            time: duration,
        };

        return dispatch => dispatch({ type: notificationConstants.ADD_NOTIFICATION, item });
    }

    closeAll() {
        return dispatch => dispatch({ type: notificationConstants.REMOVE_ALL_NOTIFICATION });
    }
}

export const notificationActions = new NotificationActions();
