import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { usersServices } from '../_store/services';
import { confirmAlert } from 'react-confirm-alert';
import { usersActions } from 'Administration/Users/_store/actions';
import { notificationConstants } from 'CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import Progress from 'react-progress-2';
import { connect } from 'react-redux';
import Actions from './Actions';

class Row extends React.Component {

    deleteUser() {
        const {t, item, dispatch} = this.props;
        confirmAlert({
            title: '',
            message: t('users.actions.delete.confirm'),
            buttons: [
                {
                    label: t('layout:yes'),
                    onClick: () => {
                        usersServices.deleteUser(item.id).then((response) => {
                                if (response) {
                                    dispatch(notificationActions.add(response, t('users.actions.delete.success')));
                                    if (response.type === notificationConstants.TYPE_SUCCESS) {
                                        Progress.show();
                                        dispatch(usersActions.getList()).finally(() => Progress.hide());
                                    }
                                }
                            }
                        );
                    }
                },
                {
                    label: t('layout:no')
                }
            ]
        });
    }

    editUser() {
        const {item, editUser} = this.props;
        editUser(item);
    }

    render() {
        const {t, item, loggedUser} = this.props;

        return (
            <tr>
                <td data-label={t('users.list.firstname')}>{item.firstname}</td>
                <td data-label={t('users.list.lastname')}>{item.lastname}</td>
                <td data-label={t('users.list.email')}>{item.email}</td>
                <td data-label={t('users.list.phone')}>{item.phone}</td>
                <td data-label={t('users.list.orderlimit')}>{item.orderlimit}</td>
                <td data-label={t('users.list.role')}>{item.role}</td>
                <td className="actions">
                    <Actions user={loggedUser} userToRemove={item} editUser={this.editUser.bind(this)}
                             deleteUser={this.deleteUser.bind(this)}/>
                </td>
            </tr>
        );
    }
}

Row.propTypes = {
    item: PropTypes.object.isRequired
};

export default connect((state) => {
    const loggedUser = state.profile.user;
    return {
        loggedUser
    }
})(withTranslation('admin')(Row));
