import React from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Search from '../Item/Search';
import Button from '../../CommonUI/Button';
import { connectedRoutes } from '../../Layout/Connected/constants';
import SupplierList from './SupplierList';
import { history } from '../../_helpers/history';
import { Redirect } from 'react-router';
import Progress from 'react-progress-2';
import { businessServices } from '../_store/services';
import { PageHead, PageHeadTitle } from '../../CommonUI/PageHead';
import { notificationConstants } from 'CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import { Screen } from '../../_constants';
import { ResponsiveContext } from '../../_store/responsive-context';


class SuppliersAddresses extends React.Component {

    constructor(props) {
        super(props);
        this.dispatch = props.dispatch;
        this.state = {
            suppliersAddresses: null,
            redirect: false,
            businessId: null,
            cartId: null,
            cartData: null,
            business: null,
        };

        this.cartData = null;
    }

    componentDidMount() {
        if (!this.checkInputData()) {
            this.setState({
                redirect: true,
                businessId: this.props.match.params.id,
            });

            return;
        }

        businessServices.getBusiness(this.props.match.params.id).then(response => {
            this.setState({
                business: response.result,
            });
        }).catch((response) => {
            const result = {
                type: notificationConstants.ERROR_RESPONSE,
                result: response.result
            };

            this.props.dispatch(notificationActions.add(result, response.result));
        });

        this.setState({
            businessId: this.props.match.params.id,
            cartId: history.location.state.cartId,
        });

        this.fetchListData();
    }

    checkInputData() {
        return history.location.hasOwnProperty('state') &&
            history.location.state &&
            history.location.state.hasOwnProperty('cartId') &&
            this.props.match.params.id;
    }

    fetchListData() {
        Progress.show();
        businessServices.getListFromCart(this.props.match.params.id)
            .then(
                (response) => {
                    if (response && response.type === 'success') {
                        this.setState({
                            cartData: response.result,
                        });

                        Progress.hide();
                    }
                }
            );
    }

    findBusinessName = () => {
        if (this.state.business) {
            return this.state.business.name;
        }

        return '';
    }

    render() {
        const {t, nbCarts} = this.props;
        const businessName = this.findBusinessName();
        if (this.state.redirect) {
            return <Redirect to={`/dashboard/mes-affaires/${this.state.businessId}/cart`}/>;
        }

        return (this.state.businessId &&
            <ResponsiveContext.Consumer>
                {({size}) => (
                    <div className="page page--business">
                        <PageHead>
                            <PageHeadTitle>
                                <h2>{t('business_invoice')} {businessName}</h2>
                            </PageHeadTitle>
                        </PageHead>
                        <div className="bg-white">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <Search businessId={this.state.businessId}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3 mt-1">
                                        <Button
                                            to={`${connectedRoutes.BUSINESS}/${this.state.businessId}`}
                                            className={`secondary ${size < Screen.md ? 'responsive-width' : ''} ${this.props.className || ''}`}
                                            type="button"
                                            label={this.props.t('browse_product_families')}
                                        />
                                    </div>

                                    <div className="col-12 col-md-3 mt-1">
                                        { nbCarts !== 0 &&
                                        <Button
                                            to={`${connectedRoutes.BUSINESS}/${this.state.businessId}/cart`}
                                            className={`tertiary ${size < Screen.md ? 'responsive-width' : ''}`}
                                            type="button"
                                            label={t('my_cart')}
                                        />
                                        }
                                    </div>
                                </div>

                                <div className="container">
                                    <SupplierList cartData={this.state.cartData} businessId={this.state.businessId}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ResponsiveContext.Consumer>
        );
    }
}

export default connect((state) => {
    const {basket} = state;
    return {nbCarts: basket.nbCarts};
})(withTranslation('business')(SuppliersAddresses));
