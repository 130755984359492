import React from 'react';
import './style.scss';
import DatePicker from "react-datepicker";
import {registerLocale} from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

const CustomDatePicker = ({name, value, onChange, touched, error, ...props}) => {
    const className = touched && error ? 'has-error' : '';
    return (
        <div className={` ${className} custom-date-picker form-field`}>
            <DatePicker
                selected={(value && new Date(value)) || null}
                onChange={val => {
                    onChange(name, val);
                }}
                dateFormat="dd/MM/yyyy"
                {...props}
            />
            {touched && error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default CustomDatePicker;
