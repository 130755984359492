import React from 'react';
import PropTypes from 'prop-types';
import Button from 'CommonUI/Button';
import { withTranslation } from 'react-i18next';
import { connectedRoutes } from 'Layout/Connected/constants';
import { NavLink } from 'react-router-dom';
import { history } from '../_helpers/history';

class OrderRow extends React.Component {

    render() {
        const {t, item: {order, supplier, business}} = this.props;

        return (
            <tr className="onClickEvent" onClick={() => { history.push(`${connectedRoutes.ORDER_ARCHIVES}/${order.id}`)}}>
                <td data-label={ t('list.id') }>{order.id}</td>
                <td data-label={ t('list.number') }>{order.number}</td>
                <td data-label={ t('list.provider') }>{supplier.company}</td>
                <td data-label={ t('list.business') }>{business.name}</td>
                <td data-label={ t('list.price') } ><strong>{Math.round(order.total_price * 100) / 100} €</strong></td>
                <td data-label={ t('list.date') }>{order.date}</td>
                <td data-label={ t('list.status') }>{order.status}</td>
                <td className="actions">
                    <NavLink to={`${connectedRoutes.ORDER_ARCHIVES}/${order.id}`}>
                        <Button className="action circle" icon="preview"/>
                    </NavLink>
                </td>
            </tr>
        );
    }
}

OrderRow.propTypes = {
    item: PropTypes.object.isRequired
};

export default withTranslation('orders')(OrderRow);
