import {businessConstants} from "./constants";
import {businessServices} from "./services";
import {notificationConstants} from "CommonUI/Notification/_store/constants";
import {getBusinessesNames, getBusinessesLocalisations} from './helper';
import _ from 'lodash';

class BusinessActions {

    getList(filters = {}) {
        return (dispatch) => {
            if (_.isObject(JSON.parse(sessionStorage.getItem('business')))) {
                let response = JSON.parse(sessionStorage.getItem('business'));
                let list = response.result;
                if (list) {
                    dispatch({type: businessConstants.SET_LIST, list});
                }
                if (_.isEmpty(filters)) {
                    const names = getBusinessesNames(list);
                    const localisations = getBusinessesLocalisations(list);
                    dispatch({type: businessConstants.SET_FILTER_NAMES, names});
                    dispatch({type: businessConstants.SET_FILTER_LOCALISATIONS, localisations});
                }
                dispatch({type: businessConstants.SET_LIST_IS_FILTERED, filtered: !_.isEmpty(filters)});
                return response;
            } else {
                return businessServices.getList(filters)
                    .then((response) => {
                        if (response.type === notificationConstants.SUCCESS_RESPONSE) {
                            let list = response.result;
                            if (list) {
                                dispatch({type: businessConstants.SET_LIST, list});
                            }
                            if (_.isEmpty(filters)) {
                                const names = getBusinessesNames(list);
                                const localisations = getBusinessesLocalisations(list);
                                dispatch({type: businessConstants.SET_FILTER_NAMES, names});
                                dispatch({type: businessConstants.SET_FILTER_LOCALISATIONS, localisations});
                            }
                            dispatch({type: businessConstants.SET_LIST_IS_FILTERED, filtered: !_.isEmpty(filters)});
                        }
                        return response;
                    })
            }
        }
    }

    getCategories() {
        return (dispatch) => {
            if (_.isArray(JSON.parse(sessionStorage.getItem('categories')))) {
                dispatch({
                    type: businessConstants.SET_CATEGORIES,
                    categories: JSON.parse(sessionStorage.getItem('categories'))
                });
                let newResponse = {};
                newResponse.type = "success";
                newResponse.result = JSON.parse(sessionStorage.getItem('categories'));
                return newResponse
            } else {
                return businessServices.getCategories().then(
                    (response) => {
                        if (response && response.type === notificationConstants.SUCCESS_RESPONSE) {
                            dispatch({type: businessConstants.SET_CATEGORIES, categories: response.result});
                        }
                        return response
                    })
            }
        }
    }

    setChosenBusiness(id) {
        return ((dispatch) => {
            if (_.isObject(JSON.parse(sessionStorage.getItem('business')))) {
                let response = JSON.parse(sessionStorage.getItem('business'));
                let list = response.result;
                if (list && typeof list !== "string") {
                    const item = list.find((item) => {
                        return item.id === parseInt(id);
                    });
                    if (item) {
                        dispatch({type: businessConstants.SET_CHOSEN_BUSINESS, item});
                    }
                }
                return response;
            } else {
                return businessServices.getList()
                    .then(
                        (response) => {
                            if (response) {
                                let list = response.result;
                                if (list && typeof list !== "string") {
                                    const item = list.find((item) => {
                                        return item.id === parseInt(id);
                                    });
                                    if (item) {
                                        dispatch({type: businessConstants.SET_CHOSEN_BUSINESS, item});
                                    }
                                }
                                return response;
                            }
                        })
            }
        });

    }

    setChosenCategory(id) {
        return ((dispatch) => {
            if (_.isArray(JSON.parse(sessionStorage.getItem('categories')))) {
                let categoriesResponse = JSON.parse(sessionStorage.getItem('categories'));
                categoriesResponse.forEach((item) => {
                    let chosenSubcategory = item.children.find((subcategory) => {
                        return subcategory.id === parseInt(id);
                    });
                    if (chosenSubcategory) {
                        dispatch({type: businessConstants.SET_CHOSEN_CATEGORY, item})
                    }
                });
            } else {
                return businessServices.getCategories().then((response) => {
                    if (response && response.type === notificationConstants.SUCCESS_RESPONSE) {
                        response.result.forEach((item) => {
                            let chosenSubcategory = item.children.find((subcategory) => {
                                return subcategory.id === parseInt(id);
                            });
                            if (chosenSubcategory) {
                                dispatch({type: businessConstants.SET_CHOSEN_CATEGORY, item})
                            }
                        });
                    }
                })
            }
        });

    }

    setChosenSubcategory(id) {
        return ((dispatch) => {
            if (_.isArray(JSON.parse(sessionStorage.getItem('categories')))) {
                let item = null;
                let categoriesResponse = JSON.parse(sessionStorage.getItem('categories'));
                categoriesResponse.forEach((category) => {
                    item = category.children.find((subcategory) => {
                        return subcategory.id === parseInt(id);
                    });
                    if (item) {
                        dispatch({type: businessConstants.SET_CHOSEN_SUBCATEGORY, item});

                    }
                });
            } else {
                return businessServices.getCategories().then((response) => {
                        if (response && response.type === notificationConstants.SUCCESS_RESPONSE) {
                            let item = null;
                            response.result.forEach((category) => {
                                item = category.children.find((subcategory) => {
                                    return subcategory.value === parseInt(id);
                                });
                                if (item) {
                                    dispatch({type: businessConstants.SET_CHOSEN_SUBCATEGORY, item})
                                }
                            });
                        }
                    }
                )
            }
        });
    }

    setChosenProduct(id) {
        return ((dispatch) => {
            return businessServices.getProductData(id)
                .then((response) => {
                    if (response) {
                        let item = response.result;
                        if (item) {
                            dispatch({type: businessConstants.SET_CHOSEN_PRODUCT, item})
                        }
                    }

                    return response;
                })
        });
    }

    setSearchedProducts(filter) {
        return ((dispatch) => {
            return businessServices.searchProducts(filter)
                .then((response) => {
                    if (response) {
                        let item = response.result;
                        if (item) {
                            dispatch({type: businessConstants.SET_SEARCHED_PRODUCTS, item})
                        }
                    }

                    return response;
                })
        });
    }
}

export const businessActions = new BusinessActions();
