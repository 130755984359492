import React from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { connectedRoutes } from '../../../Layout/Connected/constants';
import noImage from '_assets/images/noImage.png';
import Icon from '../../../CommonUI/Icon';
import Suppliers from './Suppliers';
import { businessServices } from '../../_store/services';
import { notificationConstants } from '../../../CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import Button from '../../../CommonUI/Button';
import { Screen } from '../../../_constants';
import { ResponsiveContext } from '../../../_store/responsive-context';

class Product extends React.Component {

    constructor(props) {
        super(props);
        this.dispatch = props.dispatch;
        this.state = {
            business: {},
            product: {},
        };
        this.categories = null;
    }

    componentDidMount() {
        businessServices.getProductData(this.props.match.params.productId).then(
            response => {
                if (response) {
                    if (response.type === notificationConstants.TYPE_SUCCESS) {
                        this.setState({
                            productData: response.result,
                        })
                    } else {
                        this.dispatch(notificationActions.add(response));
                    }
                }
            }
        );
        this.setState({
            business: this.props.chosenBusiness,
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        businessServices.getProductData(nextProps.match.params.productId).then(
            response => {
                if (response) {
                    if (response.type === notificationConstants.TYPE_SUCCESS) {
                        this.setState({
                            productData: response.result,
                        })
                    } else {
                        this.dispatch(notificationActions.add(response));
                    }
                }
            }
        );

        this.setState({
            business: nextProps.chosenBusiness,
        })
    }

    newPdfTab() {
        window.open(this.state.productData.product.pdf, '_blank');
    }

    render() {
        let businessImageSrc = noImage;
        let reference = 'N.C.';
        if (this.state.productData && this.state.productData.product) {
            if (this.state.productData.product.imageUrl !== null) {
                businessImageSrc = this.state.productData.product.imageUrl;
            }

            const productRef = this.state.productData.product.reference;
            if (productRef && !productRef.startsWith('N.C.')) {
                reference = productRef;
            }
        }

        return (
            <ResponsiveContext.Consumer>
                {({size}) => (
                    <>
                        <div className="container row pb-0">
                            <div className="col-12">
                                {
                                    this.state.business && this.state.productData && this.state.productData.category &&
                                    <>
                                        <span className="color-gray">{this.props.t('welcome')} / </span>
                                        <NavLink activeClassName="text-decoration-none"
                                                 to={`${connectedRoutes.BUSINESS}/${this.state.business.id}`}
                                                 className="color-20527a">
                                            {this.state.business.name}&nbsp;/&nbsp;
                                        </NavLink>
                                        {this.state.productData.category.map((category, index) => {
                                            return (
                                                <span key={index}>
                                                {index === 0 ?
                                                    <NavLink
                                                        to={`${connectedRoutes.BUSINESS}/${this.state.business.id}/${category.id}`}
                                                        className="color-20527a text-decoration-none">
                                                        {category.name}&nbsp;/&nbsp;
                                                    </NavLink>
                                                    :
                                                    <NavLink
                                                        to={`${connectedRoutes.BUSINESS}/${this.state.business.id}/catalogue/${category.id}`}
                                                        className="color-20527a text-decoration-none">
                                                        {category.name}&nbsp;/&nbsp;
                                                    </NavLink>
                                                }
                                                </span>
                                            )
                                        })}
                                        <span>
                                            {this.state.productData.product.name}
                                        </span>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="container">
                            {this.state.business && this.state.productData &&
                            <>
                                <div className="product-container row">
                                    {size >= Screen.md &&
                                    <div className="col-12 col-sm-12 col-md-3">
                                        <img className="width-100per"
                                             src={businessImageSrc}
                                             title={this.state.productData.name}
                                             alt={this.state.productData.name}
                                        />
                                        <div className="text-center" hidden>
                                            <NavLink to="#" className="zoom">
                                                <Icon name="zoom-in"/>
                                                {this.props.t('product.zoom')}
                                            </NavLink>
                                        </div>
                                    </div>
                                    }
                                    <div className="col-12 col-sm-12 col-md-9">
                                        <p className="product-title">{this.state.productData.product.name}</p>
                                        <div>
                                            <span className="supplier-reference">
                                                <span className="bold">{`${this.props.t('supplier.reference')}: `}</span>
                                                {reference}
                                            </span>
                                            <span className="product-reference">
                                                <span className="bold">{`${this.props.t('product.reference')}: `}</span>
                                                {this.state.productData.product.reference_additional}
                                            </span>
                                            <span className="product-marque">
                                                <span className="bold">{`${this.props.t('product.marque')}: `}</span>
                                                {this.state.productData.brand ? this.state.productData.brand.name : '--' }
                                            </span>
                                        </div>
                                        <hr/>
                                        <div className="padding-0-20-r">
                                            <p className="product-info-title">
                                                {this.props.t('product.info_title')}
                                            </p>
                                            <p className="product-info-text">
                                                {this.state.productData.product.information}
                                            </p>
                                            {this.state.productData.product.pdf && <div>
                                                <Button className="primary mt-2" onClick={() => {this.newPdfTab()}} label={this.props.t('product.find_more')} />
                                            </div>}
                                        </div>
                                    </div>
                                    {size < Screen.md &&
                                    <div className="col-12">
                                        <img className="width-100per"
                                             src={businessImageSrc}
                                             title={this.state.productData.name}
                                             alt={this.state.productData.name}
                                        />
                                        <div className="text-center" hidden>
                                            <NavLink to="#" className="zoom">
                                                <Icon name="zoom-in"/>
                                                {this.props.t('product.zoom')}
                                            </NavLink>
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div className="padding-0-20">
                                    <hr/>
                                </div>

                                <div className="padding-0-20">
                                    <Suppliers variants={this.state.productData.variants}/>
                                </div>

                                <div className="padding-0-20 mt-20">
                                    <hr/>
                                </div>

                            </>}
                        </div>
                    </>
                )}
            </ResponsiveContext.Consumer>
        );
    }
}

export default connect((state) => {
    const {categories, chosenBusiness} = state.business;
    return {categories, chosenBusiness};
})(withTranslation('business')(Product));
