import React from 'react';
import {Form, Formik} from "formik";
import { withTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {connect} from "react-redux";
import {TextInput} from "CommonUI/FormFields";
import Button from "CommonUI/Button";
import {NavLink} from "react-router-dom";
import {authServices} from '../../Auth/_store/services';
import {notificationActions} from "CommonUI/Notification/_store/actions";
import {history} from "_helpers/history";

class ResetPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.dispatch = props.dispatch;
    }

    render() {
        const {t} = this.props;
        return (
            <Formik
                initialValues={{
                    password: '',
                    repeated_password: ''
                }}
                validationSchema={
                    Yup.object().shape({
                        password: Yup.string().required('layout:form.error.required'),
                        repeated_password: Yup.string()
                            .oneOf([Yup.ref('password'), null], t('layout:form.error.match_password'))
                    })
                }

                onSubmit={(values, {resetForm, setErrors, setSubmitting}) => {
                    const {password, repeated_password} = values;
                    let url  = window.location.href.split("/");
                    let token = url[url.length-1];
                    if (password && repeated_password) {
                        authServices.firstPasswordReset(token, password)
                            .then(
                                (response) => {
                                    if (response.result === true) {
                                        setTimeout(() => {
                                            history.push('/auth/signout');
                                        });
                                    } else {
                                        this.dispatch(notificationActions.add(response));
                                    }
                                }
                            );
                        setSubmitting(false);
                    }
                }}
            >
                {({errors, touched, isSubmitting}) => (
                    <Form>
                        <TextInput
                            type="password"
                            name="password"
                            error={errors.password}
                            touched={touched.password}
                            placeholder={t('common.password')}
                        />

                        <TextInput
                            type="password"
                            name="repeated_password"
                            error={errors.repeated_password}
                            touched={touched.repeated_password}
                            placeholder={t('common.repeated_password')}
                        />

                        <div className="text-right form-field">
                            <NavLink to='/auth'>{t('signin.title')}</NavLink>
                        </div>

                        <Button className="primary block" isLoading={isSubmitting} label={t('reset.submit')}
                                type="submit"/>
                    </Form>
                )}
            </Formik>
        );
    };
}

export default connect()(withTranslation('auth')( ResetPasswordForm));
