import { notificationConstants } from './constants';
import md5 from 'md5';

const initialState = { notificationList: {} };

export const notification = ((state = initialState, action) => {
    let response;
    switch (action.type) {

        case notificationConstants.ADD_NOTIFICATION:
            const key = md5(new Date() + action.item.message);
            response = {
                notificationList: {
                    ...state.notificationList,
                    [key]: action.item
                }
            };
            break;

        case notificationConstants.REMOVE_ALL_NOTIFICATION:
            response = {
                notificationList: {}
            };
            break;
        default:
            response = state;
            break;
    }

    return response;
});
