import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.scss';
import logo from '_assets/images/logo.png';
import UserMenu from 'Layout/Header/UserMenu';
import ShoppingCart from 'Layout/Header/ShoppingCart';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Notification from '../../CommonUI/Notification';
import { Screen } from '../../_constants';
import { Dropdown } from '../../CommonUI/Dropdown';
import DropdownTrigger from '../../CommonUI/Dropdown/DropdownTrigger';
import DropdownMenu from '../../CommonUI/Dropdown/DropdownMenu';
import Icon from '../../CommonUI/Icon';
import { ResponsiveContext } from '../../_store/responsive-context';

const Header = () => {
    const { t } = useTranslation('layout');
    let isRouteActive = (cart = false) => {
        const url = window.location.toString();
        let match = url.includes('/dashboard/mes-affaires');
        if (cart) {
            return (match && url.includes('cart')) || url.includes('/dashboard/mes-paniers') ;
        }

        return match && !url.includes('cart');
    };

    return (
        <ResponsiveContext.Consumer>
            {({size}) => (
                <header className="header">

                    <nav className="navbar navbar-collapse">

                        <div className="logo">
                            <NavLink to="/">
                                <img src={logo} alt={t("site_name")}  />
                            </NavLink>
                        </div>

                        { (size < Screen.md) &&
                            <div className="main-menu">
                                <Dropdown>
                                    <DropdownTrigger>
                                        <Icon name="menu" />
                                    </DropdownTrigger>

                                    <DropdownMenu className="dropdown-menu-left">
                                        <ul className="menu-dropdown">

                                            <li>
                                                <NavLink
                                                    to="/dashboard/mes-affaires"
                                                    activeClassName="is-active"
                                                    isActive={() => isRouteActive(false)}
                                                >
                                                    <span>{ t('main_menu.my_businesses') }</span>
                                                </NavLink>
                                            </li>

                                            <div className="dropdown-divider"></div>

                                            <li>
                                                <ShoppingCart isActive={() => isRouteActive(true)} />
                                            </li>

                                            <div className="dropdown-divider"></div>

                                            <li>
                                                <NavLink
                                                    to="/dashboard/mes-commandes"
                                                    activeClassName="is-active"
                                                >
                                                    <span>{ t('main_menu.my_orders') }</span>
                                                </NavLink>
                                            </li>

                                            <div className="dropdown-divider"></div>

                                            <li>
                                                <NavLink
                                                    to="/dashboard/mes-archives-commandes"
                                                    activeClassName="is-active"
                                                >
                                                    <span>{ t('main_menu.my_archives_orders') }</span>
                                                </NavLink>
                                            </li>

                                        </ul>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>}
                        { (size >= Screen.md) &&
                        <ul className="main-menu">
                            <li>
                                <NavLink
                                    to="/dashboard/mes-affaires"
                                    activeClassName="is-active"
                                    isActive={() => isRouteActive(false)}
                                >
                                    <span>{ t('main_menu.my_businesses') }</span>
                                </NavLink>
                            </li>
                            <li>
                                <ShoppingCart isActive={() => isRouteActive(true)} />
                            </li>
                            <li>
                                <NavLink
                                    to="/dashboard/mes-commandes"
                                    activeClassName="is-active"
                                >
                                    <span>{ t('main_menu.my_orders') }</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/dashboard/mes-archives-commandes"
                                    activeClassName="is-active"
                                >
                                    <span>{ t('main_menu.my_archives_orders') }</span>
                                </NavLink>
                            </li>
                        </ul>}

                        <UserMenu />
                        <Notification className="col-md-4" />
                    </nav>

                </header>)}
        </ResponsiveContext.Consumer>
    );
}

export default connect(state => state)(Header);
