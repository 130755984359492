import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';

export default ({title, person, className}) => {
    const {t} = useTranslation('orders');
    return (
        person !== undefined &&
        <div className={`info-block ${className}`}>
            <p className="block-title">{title}</p>
            <div className="block-content">
                <div className="row">
                    <div className="col-6 col-md-2">{t('layout:name')}:</div>
                    <div className="value col-6 col-md-4">{person.full_name}</div>
                    <div className="col-6 col-md-3">{t('layout:company')}:</div>
                    <div className="value col-6 col-md-3">{person.company}</div>
                </div>
                <div className="row">
                    <div className="col-6 col-md-2">{t('layout:address')}:</div>
                    <div className="value col-6 col-md-4">{person.street}</div>
                    <div className="col-6 col-md-3">{t('layout:city')}:</div>
                    <div className="value col-6 col-md-3">{person.city}</div>
                </div>
                <div className="row">
                    <div className="col-6 col-md-2">{t('layout:country')}:</div>
                    <div className="value col-6 col-md-4">{person.country}</div>
                    <div className="col-6 col-md-3">{t('layout:zipcode')}:</div>
                    <div className="value col-6 col-md-3">{person.postal_code}</div>
                </div>
            </div>
        </div>
    );
};
