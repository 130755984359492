import {usersConstants} from "./constants";

const initialState = {users: []};

export const admin = (state = initialState, action) => {
    let response;

    switch (action.type) {
        case usersConstants.SET_LIST:
            response = {
                ...state,
                users: action.users
            };
            break;
        default:
            response = state;
            break;
    }

    return response;
};
