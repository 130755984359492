import { httpService } from '../../_network/http/service';

class TarifeoService {
    
    /**
     * @param httpService
     */
    constructor(httpService) {
        this.httpService = httpService;
    }
    
    iframeUrl(business) {
        return this.httpService.post('/tarifeo/open', {business});
    }
    
    validateIframe(uniqueId, cartId) {
        return this.httpService.post('/tarifeo/validate', {uniqueId, cartId});
    }
}

export const tarifeoService = new TarifeoService(httpService);
