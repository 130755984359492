import React, {Component} from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import './style.scss';

class Modal extends Component {

    constructor() {
        super();

        this.state = {
            isShowModal: false
        };
    }

    componentWillMount() {
        this.root = document.createElement('div');
        document.body.classList.add('no-scroll');
        document.body.appendChild(this.root);
    }

    componentWillUnmount() {
        document.body.classList.remove('no-scroll');
        document.body.removeChild(this.root);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({isShowModal: nextProps.showModal});
    }

    closeModal(e) {
        const {onClose} = this.props;
        e.preventDefault();

        this.setState({isShowModal: false});
        if (onClose) onClose();
    }

    closeOverlayModal(e) {
        const {disableOverlayClick} = this.props;

        if (typeof e.target.className === 'string' && e.target.className.indexOf('shown') > 0 && !disableOverlayClick) {
            this.closeModal(e);
        }
    }

    render() {
        const {title, closeButton, children, overflow, width} = this.props;
        const className = (typeof this.props.className === 'undefined') ? '' : this.props.className;
        return ReactDom.createPortal(
            <div
                role="dialog"
                className={`modal ${this.state.isShowModal ? ' shown' : ''} ${className}`}
                onClick={(e) => this.closeOverlayModal(e)}
                data-keyboard="false" data-backdrop="false"
            >
                <div
                    className={"modal-content" + (overflow ? ' overflow' : '')}
                    style={{width: width ? width + 'px' : '640px'}}
                >
                    <div className="modal-header">
                        {title && <div className="modal-title">{title}</div>}
                        {closeButton && <div className="close" onClick={(e) => this.closeModal(e)}/>}
                    </div>

                    <div className="modal-body">
                        {children}
                    </div>
                </div>
            </div>
            , this.root
        );
    }
}

Modal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    title: PropTypes.string,
    disableOverlayClick: PropTypes.bool,
    closeButton: PropTypes.bool
};

Modal.defaultProps = {
    showModal: false,
    title: '',
    disableOverlayClick: false,
    closeButton: true
};

export default Modal;
