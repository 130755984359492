import React from 'react';
import {Formik, Form} from 'formik';
import {TextInput} from 'CommonUI/FormFields';
import {withTranslation} from 'react-i18next';
import Button from 'CommonUI/Button';
import {usersServices} from '../_store/services';
import {usersActions} from '../_store/actions';
import schema from './schema';
import Progress from 'react-progress-2';
import {notificationConstants} from "CommonUI/Notification/_store/constants";
import {notificationActions} from "CommonUI/Notification/_store/actions";
import "../style.scss";
import {connect} from "react-redux";
import {roles} from '_rbac/roles';
import {profileConstants} from 'Profile/_store/constants';
import {profileServices} from 'Profile/_store/services'


class CreateForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products: []
        };
        this.formik = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const {showModal} = this.props;
        if (showModal !== prevProps.showModal && showModal === false) {
            this.formik.current.resetForm();
        }
    }

    saveData(values, {setSubmitting}) {
        const {closeModal, dispatch, t, loggedUser} = this.props;
        let {user} = this.props;
        let saveUser, alertMessage;
        if (user !== null && typeof user !== 'undefined') {
            alertMessage = t('users.actions.update.success');
            user = {...user, ...values};
            if (user.id === loggedUser.id) {
                saveUser = profileServices.update;
            } else {
                saveUser = usersServices.update;
            }
        } else {
            saveUser = usersServices.create;
            alertMessage = t('users.actions.create.success');
            user = values;
        }
        saveUser(user).then((response) => {
            if (response) {
                dispatch(notificationActions.add(response, alertMessage));
                if (response.type === notificationConstants.TYPE_SUCCESS) {
                    Progress.show();
                    dispatch(usersActions.getList()).finally(() => Progress.hide());
                    if (user !== null && typeof user !== 'undefined' && user.id === loggedUser.id) {
                        dispatch({type: profileConstants.SET_PROFILE, user: response.result});
                    }
                }
            }
            setSubmitting(false);
            if (closeModal) closeModal();
        })
    }

    getInitialValues() {
        const {user} = this.props;
        let initialValues = {firstname: '', lastname: '', email: '', phone: '', orderlimit: ''};
        if (user !== null && typeof user !== 'undefined') {
            for (let property in initialValues) {
                if (user.hasOwnProperty(property) && user[property] !== null) {
                    initialValues[property] = user[property];
                }
            }
        }
        return initialValues;
    }

    render() {
        const {t, user, type} = this.props;
        let initialValues = this.getInitialValues();
        const showOrderLimit = (type === 'create') || (user !== null && typeof user !== 'undefined' && user.role !== roles.MANAGER);
        return (<Formik
            initialValues={initialValues}
            onSubmit={this.saveData.bind(this)}
            ref={this.formik}
            validationSchema={schema}
            enableReinitialize={true}
            render={({errors, touched, isSubmitting}) => (
                <Form className="form-inline validation-form">
                    <div className="row">
                        <div className="col-3">
                            <label>{t('users.list.firstname')}</label>
                        </div>
                        <div className="col-9">
                            <TextInput
                                type="text"
                                name="firstname"
                                error={errors.firstname ? t(errors.firstname) : ''}
                                touched={touched.firstname}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <label>{t('users.list.lastname')}</label>
                        </div>
                        <div className="col-9">
                            <TextInput
                                type="text"
                                name="lastname"
                                error={errors.lastname ? t(errors.lastname) : ''}
                                touched={touched.lastname}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <label>{t('users.list.email')}</label>
                        </div>
                        <div className="col-9">
                            <TextInput
                                type="email"
                                name="email"
                                error={errors.email ? t(errors.email) : ''}
                                touched={touched.email}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <label>{t('users.list.phone')}</label>
                        </div>
                        <div className="col-9">
                            <TextInput
                                type="text"
                                name="phone"
                                error={errors.phone ? t(errors.phone) : ''}
                                touched={touched.phone}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <label>{t('users.list.orderlimit')}</label>
                        </div>
                        <div className="col-9">
                            {showOrderLimit ? <TextInput
                                type="text"
                                name="orderlimit"
                                error={errors.orderlimit ? t(errors.orderlimit) : ''}
                                touched={touched.orderlimit}
                            /> : <TextInput
                                type="text"
                                name="orderlimit"
                                disabled
                            />}
                        </div>
                    </div>
                    <div className="text-right">
                        <Button
                            className="primary"
                            isLoading={isSubmitting}
                            label={t("layout:confirm")}
                            type="submit"
                        />
                    </div>
                </Form>
            )}
        />);
    }
}

export default connect((state) => {
    return {loggedUser: state.profile.user};
})(withTranslation('admin')(CreateForm));
