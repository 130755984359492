export const notificationConstants = {
    ADD_NOTIFICATION: 'ADD_NOTIFICATION',
    REMOVE_ALL_NOTIFICATION: 'REMOVE_ALL_NOTIFICATION',
    TYPE_SUCCESS: 'success',
    TYPE_DANGER: 'danger',
    TYPE_WARNING: 'warning',
    TYPE_INFO: 'info',
    NOTIFICATION_TIME: 7000,
    SUCCESS_RESPONSE: 'success',
    ERROR_RESPONSE: 'warning',
    ERROR_MESSAGE: "Une erreur sest produite, veuillez ressayer.",
};
