import React from 'react';
import FormField from '../FormField';
import { Field } from 'formik';
import './style.scss';

const TextInput = ({touched, parentClassName, className, ...props}) => (
    <FormField fieldType="text" touched={touched} className={parentClassName} {...props}>
        <Field className={className || 'form-control'} {...props} />
    </FormField>
);

export default TextInput;
