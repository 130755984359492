import React from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import SupplierAddress from './SupplierAddress';


class SupplierItem extends React.Component {

    constructor(props) {
        super(props);

        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.state = {
            loaded: false,
            isExpanded: this.props.expanded,
            chosenAddress: null,
        };
    }

    expand = () => {
        this.setState({isExpanded: !this.state.isExpanded});
    };

    getSupplierAddress() {
        let addr = null;
        this.props.addresses.suppliersAddress.forEach(address => {
            if (address.supplier === this.props.supplier.name) {
                addr = address.address;
            }
        });

        return addr;
    }

    getCustomerAddress() {
        return this.props.addresses.customerAddress.length ? this.props.addresses.customerAddress[0] : null;
    }

    getBusinessAddress() {
        return this.props.addresses.businessAddress;
    }

    getExpandClass() {
        return this.state.isExpanded ? 'expand-badge expanded' : 'expand-badge';
    }

    handleAddressChange(event, title) {
        let chosenAddress = {
            ...event,
            addressTitle: title,
        };

        this.setState({
            chosenAddress: chosenAddress,
            isExpanded: false,
        });

        this.props.updateAddress(event);
    }

    chosenAddress() {
        return this.state.chosenAddress ?
            (this.state.chosenAddress.addressTitle || '') :
            (this.getBusinessAddress()[0].addressTitle || '')
    }

    render() {
        const {t, supplier} = this.props;
        return (
            supplier.qty > 0 &&
            <>
                <div key={supplier.name} className="supplier-item pl-10" onClick={this.expand}>
                    <div>
                        <div>
                            {t('cart.addresses_page.order')} {supplier.name} |&nbsp;
                            {supplier.qty} {t('cart.addresses_page.articles')} |&nbsp;
                            <span className="bold"> {supplier.price.toFixed(2)} {t('cart.addresses_page.currency_symbol')}</span>
                        </div>
                        <div>
                            {t('cart.addresses_page.address')}
                            {
                                this.chosenAddress()
                            }
                        </div>
                    </div>
                    <div className={this.getExpandClass()}>V</div>
                </div>
                <div className="bordered p-5">
                    {this.state.isExpanded &&
                    <SupplierAddress
                        key='unique'
                        supplierAddress={this.getSupplierAddress()}
                        businessAddress={this.getBusinessAddress()}
                        customerAddress={this.getCustomerAddress()}
                        onAddressChange={this.handleAddressChange}
                    />}
                </div>
            </>
        );
    }
}

export default connect((state) => {
    return state;
})(withTranslation('business')(SupplierItem));
