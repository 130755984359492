import React, { cloneElement, Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import './style.scss';

import DropdownTrigger from './DropdownTrigger';
import DropdownMenu from './DropdownMenu';

class Dropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: false
        };

        this._onWindowClick = this._onWindowClick.bind(this);
        this._onToggleClick = this._onToggleClick.bind(this);
    }

    componentDidMount() {
        window.addEventListener('click', this._onWindowClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this._onWindowClick);
    }

    isActive() {
        return (typeof this.props.active === 'boolean') ?
            this.props.active :
            this.state.active;
    }

    hide() {
        this.setState({
            active: false
        }, () => {
            if (this.props.onHide) {
                this.props.onHide();
            }
        });
    }

    show() {
        this.setState({
            active: true
        }, () => {
            if (this.props.onShow) {
                this.props.onShow();
            }
        });
    }

    _onWindowClick(event) {
        const dropdownElement = findDOMNode(this);
        if (
            (event.target !== dropdownElement && !dropdownElement.contains(event.target) && this.isActive()) ||
            event.target.name === 'dropDown_item'
        ) {
            this.hide();
        }
    }

    _onToggleClick(event) {
        event.preventDefault();
        if (this.isActive()) {
            this.hide();
        } else {
            this.show();
        }
    }

    render() {
        const { children, className, disabled, removeElement } = this.props;

        // create component classes
        const active = this.isActive();

        let dropdownClasses = 'dropdown';
        if (className) {
            dropdownClasses += ' ' + className;
        }

        if (disabled) {
            dropdownClasses += ' dropdown--disabled';
        }

        if (active) {
            dropdownClasses += ' dropdown--active';
        }

        // stick callback on trigger element
        const boundChildren = React.Children.map(children, child => {
            if (child.type === DropdownTrigger) {
                const originalOnClick = child.props.onClick;
                child = cloneElement(child, {
                    ref: 'trigger',
                    onClick: (event) => {
                        if (!disabled) {
                            this._onToggleClick(event);
                            if (originalOnClick) {
                                originalOnClick.apply(child, arguments);
                            }
                        }
                    }
                });
            } else if (child.type === DropdownMenu && removeElement && !active) {
                child = null;
            }

            return child;
        });

        const cleanProps = { ...this.props };
        delete cleanProps.active;
        delete cleanProps.onShow;
        delete cleanProps.onHide;
        delete cleanProps.removeElement;

        return (
            <div
                {...cleanProps}
                className={dropdownClasses}>
                {boundChildren}
            </div>
        );
    }
}

Dropdown.propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    removeElement: PropTypes.bool,
    style: PropTypes.object
};

export {
    Dropdown,
    DropdownTrigger,
    DropdownMenu
};