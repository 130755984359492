import {authConstants} from './constants';
import {profileConstants} from 'Profile/_store/constants';
import {authServices} from './services';
import {history} from "../../_helpers/history";
import {notificationConstants} from "../../CommonUI/Notification/_store/constants";

class AuthActions {

    /**
     *
     * @param username
     * @param password
     * @param redirectRoute
     * @param rememberMe
     * @returns {function(*)}
     */
    login(username, password, rememberMe, redirectRoute = "/") {
        return (dispatch) => {
            return authServices.login(username, password, rememberMe)
                .then(
                    (response) => {
                        if (response) {
                            if (response.type === notificationConstants.ERROR_RESPONSE) {
                                dispatch({type: authConstants.LOGIN_FAILURE});
                                return response;
                            }
                            let user = response.result;
                            dispatch({type: authConstants.LOGIN_SUCCESS, user});
                            dispatch({type: profileConstants.SET_PROFILE, user});
                            history.push(redirectRoute);
                        }

                        return response;
                    }
                )
        };
    }

    saveRoute(route) {
        return (dispatch) => {
            dispatch({type: authConstants.ROUTE_NOT_ALLOWED, route});
        }
    }

    /**
     *
     * @returns {function(*)}
     */
    logout() {
        sessionStorage.removeItem('categories');
        sessionStorage.removeItem('business');
        authServices.logout();
        return dispatch => {
            dispatch({type: authConstants.LOGOUT});
            history.push('/auth');
        };
    }

    forgotPassword(email){
        return (dispatch) => {
            return authServices.forgotPassword(email)
                .then(
                    (response) => {
                        return response;
                    }
                )
        };
    }

}

export const authActions = new AuthActions();
