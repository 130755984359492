import React from 'react';
import './style.scss';
import { orderServices } from 'Orders/_store/services';
import Progress from 'react-progress-2';
import { withTranslation } from 'react-i18next';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import { notificationConstants } from '../../CommonUI/Notification/_store/constants';
import { connect } from 'react-redux';
import saveAs from 'file-saver';

class DownloadOrder extends React.Component {
    constructor(props) {
        super(props);
        this.dispatch = props.dispatch;
    }

    componentDidMount() {
        this.downloadOrderPdf();
    }

    downloadOrderPdf() {
        const token = this.props.match.params.token;
        Progress.show();
        orderServices.getOrderPdf(token)
            .then(
                (response) => {
                    if (response) {
                        if (response.type === notificationConstants.ERROR_RESPONSE) {
                            this.dispatch(notificationActions.add(response));
                        } else {
                            const file = new Blob([response.data], {type: 'application/pdf'});
                            //for save pdf
                            saveAs(file, this.props.t('pdf.title') + token);
                            //for open in browser
                            // window.location.href = URL.createObjectURL(file);
                        }
                        Progress.hide();

                    }
                }
            );
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <div className="align-vertical-center"> {t('actions.download.in_progress')} </div>
            </div>
        )
    }

}

export default connect()(withTranslation('orders')(DownloadOrder));
