import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { connectedRoutes } from 'Layout/Connected/constants';
import { history } from '_helpers/history';
import Button from 'CommonUI/Button';
import { Screen } from '../../_constants';
import { ResponsiveContext } from '../../_store/responsive-context';

export default ({className, buttonClassName}) => {
    const {t} = useTranslation('orders');
    return (
        <ResponsiveContext.Consumer>
            {({size}) => (
                <div className={`orders-back-container ${className || ''}`}>
                    {(size >= Screen.md) &&
                    <Button
                        className={`orders-back-btn primary btn ${buttonClassName || ''}`}
                        label={t("back_to_list")}
                        onClick={() => {
                            history.push(connectedRoutes.ORDER);
                        }}
                    />}
                    {(size < Screen.md) &&
                    <Button
                        className={`orders-back-btn primary btn min-width ${buttonClassName || ''}`}
                        icon="reply"
                        onClick={() => {
                            history.push(connectedRoutes.ORDER);
                        }}
                    />}
                </div>
            )}
        </ResponsiveContext.Consumer>
    );
};
