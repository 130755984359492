import React from 'react';
import routes from './routes';
import './style.scss';

import Logo from 'CommonUI/Logo';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

class Index extends React.Component {

    render(loggedIn, route) {
        if (loggedIn) {
            return (
                <Redirect to={{pathname: "/dashboard/mes-affaires"}}/>
            );
        }
        return (
            <div className="page page--auth">
                <div className="auth-card">
                    <div className="auth-card__logo">
                        <Logo />
                    </div>
                    <div className="auth-card__content">
                        {routes}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state) => {

    const {loggedIn, route} = state.auth;

    return {
        loggedIn,
        route
    };
})(withTranslation('auth')(Index))

