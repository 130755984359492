import React from 'react';
import Access from '_rbac/Access';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Progress from 'react-progress-2';
import UsersList from './List';
import Button from 'CommonUI/Button';
import Modal from "CommonUI/Modal";
import Form from "./Form";
import { usersActions } from "Administration/Users/_store/actions";
import "./style.scss";

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    toggleModal = () => {
        return this.setState((prevState) => {
            return {
                showModal: !prevState.showModal
            }
        });
    };

    fetchData() {
        const {dispatch} = this.props;
        Progress.show();
        dispatch(usersActions.getList()).finally(() => {
            Progress.hide();
        });
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        const {t, user, users} = this.props;
        return (
            <Access
                role={user.role}
                perform="users:list"
                yes={() => (
                    <div className="page page--users">
                        <div className="container">
                            <div className="row users-header">
                                <div className="col-6">
                                    <h2 className="page-title">{t('users.title')}</h2>
                                </div>
                                <div>
                                    <Button className="primary block" label={t('users.actions.create.title')}
                                            onClick={this.toggleModal}/>
                                </div>
                            </div>
                            <UsersList users={users}/>
                        </div>
                        {<Modal
                            showModal={this.state.showModal}
                            title={t('users.actions.create.title')}
                            children={<Form closeModal={this.toggleModal} type="create"  showModal={this.state.showModal}/>}
                            onClose={this.toggleModal}
                        />}
                    </div>
                )}
                no={() => <Redirect to="/dashboard/unauthorized"/>}
            />
        )

    }
}

export default connect(
    (state) => {
        const {user} = state.profile;
        const {users} = state.admin;
        return {
            user, users
        }
    }
)(withTranslation('admin')(Users));
