import React from 'react';
import {Formik, Form} from 'formik';
import {TextInput} from 'CommonUI/FormFields';
import {withTranslation} from 'react-i18next';
import Button from 'CommonUI/Button';
import {connect} from "react-redux";
import {profileActions} from "Profile/_store/actions";
import PersonalInfoFormSchema from "./schema";
import {notificationConstants} from "../../CommonUI/Notification/_store/constants";
import {notificationActions} from "CommonUI/Notification/_store/actions";
import Progress from "react-progress-2";

class PersonalInfoForm extends React.Component {
    constructor(props) {
        super(props);
        this.dispatch = props.dispatch;
    }

    handleSubmit(values, {setSubmitting}) {
        Progress.show();
        const {user} = this.props;
        let data = {...user, ...values};
        this.props.dispatch(profileActions.update(data))
            .then(
                (response) => {
                    if (response) {
                        if (response.type === notificationConstants.SUCCESS_RESPONSE) {
                            this.dispatch(notificationActions.add(response, this.props.t("actions.update.success")));
                        } else {
                            this.dispatch(notificationActions.add(response));
                        }
                        Progress.hide();
                    }
                }
            );
        setSubmitting(false);
    }

    render() {
        const {t, user} = this.props;
        const initialValues = {};
        initialValues.firstname = user.firstname ? user.firstname : '';
        initialValues.lastname = user.lastname ? user.lastname : '';
        initialValues.email = user.email ? user.email : '';
        initialValues.phone = user.phone ? user.phone : '';

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={this.handleSubmit.bind(this)}
                validationSchema={PersonalInfoFormSchema}
            >
                {({errors, touched, isSubmitting}) => (
                    <Form className="form-inline user-form">
                        <h3 className="area-title">
                            <span>
                                {t("personal_info")}
                            </span>
                        </h3>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="form-label">{t("firstname")}</label>
                            </div>
                            <div className="col-md-9">
                                <TextInput
                                    type="text"
                                    name="firstname"
                                    error={errors.firstname ? t(errors.firstname) : ''}
                                    touched={touched.firstname}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="form-label">{t("lastname")}</label>
                            </div>
                            <div className="col-md-9">
                                <TextInput
                                    type="text"
                                    name="lastname"
                                    error={errors.lastname ? t(errors.lastname) : ''}
                                    touched={touched.lastname}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="form-label">{t("email")}</label>
                            </div>
                            <div className="col-md-9">
                                <TextInput
                                    type="email"
                                    name="email"
                                    error={errors.email ? t(errors.email) : ''}
                                    touched={touched.email}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <label className="form-label">{t("phone")}</label>
                            </div>
                            <div className="col-md-9">
                                <TextInput
                                    type="tel"
                                    name="phone"
                                    error={errors.phone ? t(errors.phone) : ''}
                                    touched={touched.phone}
                                />
                            </div>
                        </div>
                        <div className="text-right">
                            <Button
                                className="primary"
                                isLoading={isSubmitting}
                                label={t("layout:confirm")}
                                type="submit"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default connect((state) => {
    return {user: state.profile.user};
})(withTranslation('user')(PersonalInfoForm));
