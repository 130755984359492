import { breadcrumbConstants } from './constants';

const initialState = {parts: []};
export const breadcrumbs = ((state = initialState, action) => {
    let response;
    let parts;
    switch (action.type) {
        case breadcrumbConstants.PUSH_PART:
            response = {
                    ...state,
                parts: [
                    ...state.parts,
                    action.item,
                    ],
            };

            break;
        case breadcrumbConstants.POP_PART:
            parts = state.parts;
            if (parts.length > 1) {
                parts.pop();
            }

            response = {
                ...state,
               parts,
            };

            break;

        case breadcrumbConstants.CLEAR:
            console.log(state);
            parts = state.parts.length ? state.parts[0] : [];
            response = {
                ...state,
                parts,
            };

            break;

        case breadcrumbConstants.SET:
            response = {
                ...state,
                parts: action.item,
            };

            break;
        default:
            response = state;
            break;
    }

    return response;
});
