import React, { Component } from 'react';
import './style.scss';
import List from './list'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Progress from 'react-progress-2'
import _ from 'lodash'
import { businessServices } from '../../_store/services';
import { NavLink } from 'react-router-dom';
import { connectedRoutes } from '../../../Layout/Connected/constants';
import Search from '../Search';
import { notificationConstants } from 'CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import { PageHead, PageHeadTitle } from '../../../CommonUI/PageHead';
import { Screen } from '../../../_constants';
import Button from '../../../CommonUI/Button';
import { ResponsiveContext } from '../../../_store/responsive-context';


class Cart extends Component {
    constructor(props) {
        super(props);
        this.dispatch = props.dispatch;
        this.state = {
            businessId: props.match.params.id,
            businessName: '',
            cartId: null,
            listFromCart: {},
            totalPrice: 0,
            loadedList: false,
            addresses: null
        };
    }

    getBusinessName() {
        if (this.state.businessId) {
            if (_.isObject(JSON.parse(sessionStorage.getItem('business')))) {
                let response = JSON.parse(sessionStorage.getItem('business'));
                let list = response.result;
                if (list && typeof list !== "string") {
                    const item = list.find((item) => {
                        return item.id === parseInt(this.state.businessId);
                    });
                    if (item) {
                        this.setState({
                            businessName: item.name
                        })
                    }
                }
            } else {
                businessServices.getList().then((response) => {
                    let list = response.result;
                    if (list && typeof list !== "string") {
                        const item = list.find((item) => {
                            return item.id === parseInt(this.state.businessId);
                        });
                        if (item) {
                            this.setState({
                                businessName: item.name
                            })
                        }
                    }
                });
            }
        }
    }

    fetchListData() {
        Progress.show();
        businessServices.getListFromCart(this.state.businessId)
            .then(
                (response) => {
                    if (response) {
                        if (response.type === notificationConstants.SUCCESS_RESPONSE && response.result.cart && response.result.cart.products) {
                            this.processResponse(response.result);
                        } else {
                            this.dispatch(notificationActions.add(response));
                        }
                        Progress.hide();
                    }
                }
            );
    }

    componentDidMount() {
        this.getBusinessName();
        this.fetchListData();
    }

    groupResponse(response) {
        let list = [];
        let productList = _.groupBy(response.cart.products, 'supplier');
        Object.keys(productList).forEach(function (groups) {
            let listGrouped = productList[groups];
            list.push(listGrouped);
        });
        return list;
    }

    processResponse = (response) => {
        let list = this.groupResponse(response);
        let total = this.getTotalPrice(response.cart.products);
        this.setState({
            addresses: response.cart.addresses,
            listFromCart: list,
            cartId: response.cart.id,
            totalPrice: total,
            nextPageUrl: this.redirectAfterRemoveProduct(response.cart.products),
            loadedList: true
        });
        return {list, totalPrice: total};
    };

    redirectAfterRemoveProduct = (listOfProducts) => {
        let nextPage = '';
        if (listOfProducts.length === 1) {
            nextPage = '';
        } else {
            nextPage = '/cart';
        }

        return nextPage;
    };

    getTotalPrice = (listFromCart) => {
        let total = 0;
        let finalPrice = 0;
        if (listFromCart) {
            listFromCart.forEach((item) => {
                total += parseFloat(Math.round(item.price * 100) / 100) * parseFloat(item.qty);
            });
        }

        if (Number.isInteger(total)) {
            finalPrice = total.toFixed(2);
        } else {
            if (total.toString().split(".")[1].length > 2) {
                finalPrice = total;
            } else {
                finalPrice = total.toFixed(2);
            }

        }
        return finalPrice;
    };


    render() {
        const {t} = this.props;
        return (
            <ResponsiveContext.Consumer>
                {({size}) => (
                    <div className="page page--business">
                        <PageHead>
                            <PageHeadTitle>
                                <h2>{t('business_cart')} {this.state.businessName}</h2>
                            </PageHeadTitle>
                        </PageHead>
                        <div className="bg-white">
                            <div className="container">
                                <div className="row mt-2">
                                    <div className="col-12 col-sm-12">
                                        <Search businessId={this.state.businessId}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3 mt-1">
                                        <Button
                                            to={`${connectedRoutes.BUSINESS}/${this.state.businessId}`}
                                            className={`secondary ${size < Screen.md ? 'responsive-width' : ''} ${this.props.className || ''}`}
                                            type="button"
                                            label={this.props.t('browse_product_families')}
                                        />
                                    </div>

                                    <div className="col-12 col-md-3 mt-1">
                                        <Button
                                            to={`${connectedRoutes.BUSINESS}/${this.state.businessId}/cart`}
                                            className={`tertiary ${size < Screen.md ? 'responsive-width' : ''}`}
                                            type="button"
                                            label={t('my_cart')}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                        {
                            this.state.businessName &&
                            <div className="container">
                                <span className="color-gray">
                                    {this.props.t('welcome')} / &nbsp;
                                    <NavLink to={`${connectedRoutes.BUSINESS}/${this.state.businessId}`} className="color-20527a text-decoration-none">
                                    {this.state.businessName}</NavLink> / &nbsp;
                                    <b>{this.props.t('cart.cart')}</b>
                                </span>
                            </div>
                        }
                        {
                            this.state.loadedList &&
                            <List listFromCart={this.state.listFromCart}
                                  addresses={this.state.addresses}
                                  cartId={this.state.cartId}
                                  totalPrice={Math.round(this.state.totalPrice * 100) / 100}
                                  businessId={this.state.businessId}
                                  nextPageUrl={this.state.nextPageUrl}
                                  processResponse={this.processResponse.bind(this)}
                                  fetchListData={this.fetchListData.bind(this)}
                            />}
                    </div>
                )}
            </ResponsiveContext.Consumer>
        );
    }
}

export default connect((state) => {
    const {chosenBusiness} = state.business;
    return {
        chosenBusiness
    }
})(withTranslation('business')(Cart));
