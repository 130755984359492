import {basketConstants} from "./constants";
const initialState = {nbCarts: 0};
export const basket = ((state = initialState, action) => {
    let response;
    switch (action.type) {
        case basketConstants.UPDATE_COUNT_OF_CARTS:
            response = {
                    ...state,
                    nbCarts: action.item
            };
            break;
        default:
            response = state;
            break;
    }

    return response;
});
