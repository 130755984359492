import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Formik, Form, FieldArray, ErrorMessage } from 'formik';
import Button from "CommonUI/Button";
import { businessServices } from "../../_store/services";
import { connectedRoutes } from "Layout/Connected/constants";
import './style.scss';
import { history } from "_helpers/history";
import { notificationConstants } from "CommonUI/Notification/_store/constants";
import { notificationActions } from "CommonUI/Notification/_store/actions";
import Progress from 'react-progress-2';
import { basketServices } from "../../../Basket/_store/services";
import FormRow from "./FormRow";
import "../style.scss";
import { orderConstants } from "../../../Orders/_store/constants";
import { Redirect } from 'react-router';

class cart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            totalPrice: parseFloat(Math.round(this.props.totalPrice * 100) / 100),
            buttonIsLoading: false,
            listFromCart: this.props.listFromCart,
            sortDirection: null,
            enableSort: {label: null, direction: null},
            showModal: false,
            addresses: this.props.addresses,
            redirect: false,
        };

        this.initialStatusFilter = [
            orderConstants.WAITING_ID,
            orderConstants.VALIDATED_ID,
            orderConstants.CANCELED_ID,
            orderConstants.PARTIALLY_RECEIVED_ID
        ];
    }

    updateCountOfCarts = () => {
        basketServices.fillCountOfCarts(this.props.dispatch);
    }

    getStyles(products, groupIndex, productIndex) {
        const {listFromCart} = this.props;
        let borderTop = "0";
        let borderBottom = "0";
        if (productIndex === 0) {
            borderTop = "1px solid black";
        }
        if (products && listFromCart) {
            if (productIndex === (products.length - 1) && groupIndex === (listFromCart.length - 1)) {
                borderBottom = "1px solid black";
            }
        }
        return {borderTop, borderBottom};
    };

    removeProduct(item) {
        const {t, dispatch, cartId, processResponse, businessId} = this.props;
        Progress.show();
        businessServices.removeProductFromCart(cartId, item.item_id)
            .then(
                (response) => {
                    if (response && response.type === notificationConstants.SUCCESS_RESPONSE) {
                        dispatch(notificationActions.add(response, t("notification.success.remove_product")));
                        this.updateCountOfCarts();
                        let {list: newList, totalPrice} = processResponse(response.result);
                        this.setState({
                            totalPrice,
                            listFromCart: newList
                        });
                        if (newList && newList.length === 0) {
                            history.push({
                                pathname: `${connectedRoutes.BUSINESS}/${businessId}`,
                                state: {orderStatus: 'removed'}
                            });
                        }
                    } else {
                        this.dispatch(notificationActions.add(response));
                    }
                }
            ).finally(() => {
            Progress.hide();
        });
    }

    saveProductQuantity(cartItemId, quantity) {

        Progress.show();
        const {cartId, dispatch} = this.props;
        return businessServices.updateCart(cartId, cartItemId, {quantity}).then((response) => {
            if (response && response.type === notificationConstants.ERROR_RESPONSE) {
                dispatch(notificationActions.add(response));
                return Promise.reject(response.result);
            }
            return response;
        })
            .finally(() => {
                Progress.hide();
            });
    }

    recalculateTotalPrice(products) {
        let totalPrice = 0;
        if (products) {
            products.forEach((product) => {
                if (product && product.quantity > 0) {
                    totalPrice += parseFloat(Math.round(product.price * 100) / 100) * parseFloat(product.quantity);
                }
            });
        }
        this.setState({totalPrice: Math.round(totalPrice * 100) / 100});
    }

    getInitialValues() {
        const {listFromCart, user} = this.props;
        let initialValues = {};
        initialValues.products = [];
        listFromCart.forEach((group) => {
            group.forEach((product) => {
                initialValues.products[product.variant_id] = {
                    cartItemId: product.item_id,
                    quantity: product.qty,
                    price: product.price,
                    orderLimit: user.orderlimit ? user.orderlimit : 0
                };
            });

        });
        return initialValues;
    }

    sortRecords = (label, sortDirection) => {
        let listProducts = this.props.listFromCart;
        let nextDirection = '';
        if (sortDirection === null || sortDirection !== 'up') {
            nextDirection = 'up';
            for (let i = 0; i < listProducts.length; i++) {
                switch (label) {
                    case 'name':
                        listProducts[i].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
                        break;
                    // case 'supplier':
                    //     listProducts[i].sort((a, b) => (a.supplier.toLowerCase() > b.supplier.toLowerCase()) ? 1 : -1);
                    //     break;
                    case 'price':
                        listProducts[i].sort((a, b) => (a.price > b.price) ? 1 : -1);
                        break;
                    case 'quantity':
                        listProducts[i].sort((a, b) => (a.qty > b.qty) ? 1 : -1);
                        break;
                    default:
                        break;
                }
            }
        } else {
            nextDirection = 'down';
            for (let i = 0; i < listProducts.length; i++) {
                switch (label) {
                    case 'name':
                        listProducts[i].sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? 1 : -1);
                        break;
                    // case 'supplier':
                    //     listProducts[i].sort((a, b) => (a.supplier.toLowerCase() < b.supplier.toLowerCase()) ? 1 : -1);
                    //     break;
                    case 'price':
                        listProducts[i].sort((a, b) => (a.price < b.price) ? 1 : -1);
                        break;
                    case 'quantity':
                        listProducts[i].sort((a, b) => (a.qty < b.qty) ? 1 : -1);
                        break;
                    default:
                        break;
                }
            }

        }

        let direction = '';
        if (nextDirection === 'up') {
            direction = 'down';
        } else {
            direction = 'up';
        }
        this.setState({
            sortDirection: nextDirection,
            listFromCart: listProducts,
            enableSort: {label, direction}
        })
    };

    routeChange = () => {
        const {dispatch, fetchListData, processResponse} = this.props;
        Progress.show();
        return businessServices.verifyTarifeoProducts(this.props.businessId).then((response) => {
            if (response && response.type === notificationConstants.ERROR_RESPONSE) {
                dispatch(notificationActions.add(response));
                return Promise.reject(response.result);
            }
            let {list: newList, totalPrice} = processResponse(response.result);
            this.setState({
                totalPrice,
                listFromCart: newList
            });
            if (response.result.cart.redirect) {
                history.push({
                    pathname: `/dashboard/mes-affaires/${this.props.businessId}/cart/addresses`,
                    state: {cartId: this.props.cartId}
                });
            }
            return response;
        })
            .finally(() => {
                Progress.hide();
            });
    };

    render() {
        const {t, cartId, businessId, user} = this.props;
        const {totalPrice, listFromCart, sortDirection, enableSort} = this.state;
        const initialValues = this.getInitialValues();

        if (this.state.redirect) {
            return <Redirect to={`/dashboard/mes-affaires/${businessId}/cart/addresses`}/>;
        }

        return (
            <div className="container">
                {
                    listFromCart && cartId && listFromCart.length > 0 ?
                        <Formik
                            initialValues={initialValues}
                            // validationSchema={schema}    // don't need to check limit
                            enableReinitialize={true}
                            validationOnChange={true}
                            validationOnBlur={true}
                            isInitialValid={true}
                            render={({errors, touched, isSubmitting, setFieldValue, values, isValid}) => (
                                <Form>
                                    <div className="cart-table table-responsive">
                                        <div className="row cart-table-header text-left">
                                            <div className="col-5"
                                                 onClick={() => {
                                                     this.sortRecords('name', sortDirection)
                                                 }}>
                                                <Button
                                                    className="link"
                                                    label={t('cart.table.table_title_product')}
                                                    icon={`chevron-${enableSort.label === 'name' ? enableSort.direction : 'right'}`}
                                                />
                                            </div>
                                            <div className="col-3"
                                                 onClick={() => {
                                                     this.sortRecords('supplier', sortDirection)
                                                 }}>
                                                <Button
                                                    className="link"
                                                    label={t('cart.table.table_title_supplier')}
                                                />
                                            </div>
                                            <div className="col-2"
                                                 onClick={() => {
                                                     this.sortRecords('price', sortDirection)
                                                 }}>
                                                <Button
                                                    className="link"
                                                    label={t('cart.table.table_title_price')}
                                                    icon={`chevron-${enableSort.label === 'price' ? enableSort.direction : 'right'}`}
                                                />
                                            </div>
                                            <div
                                                className="col-2"
                                                onClick={() => {
                                                    this.sortRecords('quantity', sortDirection)
                                                }}>
                                                <Button
                                                    className="link"
                                                    label={t('cart.table.table_title_quantity')}
                                                    icon={`chevron-${enableSort.label === 'quantity' ? enableSort.direction : 'right'}`}
                                                />
                                            </div>
                                        </div>
                                        <FieldArray
                                            name="products"
                                            render={arrayHelpers => (
                                                <div>
                                                    {listFromCart.map((group, groupIndex) => (
                                                        group.map((product, productIndex) => {
                                                            return <FormRow key={productIndex}
                                                                            product={product}
                                                                            index={product.variant_id}
                                                                            errors={errors}
                                                                            touched={touched}
                                                                            values={values}
                                                                            setFieldValue={setFieldValue}
                                                                            businessId={businessId}
                                                                            cartId={cartId}
                                                                            removeProduct={this.removeProduct.bind(this)}
                                                                            styles={this.getStyles(group, groupIndex, productIndex)}
                                                                            onChangeQuantity={this.saveProductQuantity.bind(this)}
                                                                            isValid={isValid}
                                                                            recalculateTotalPrice={this.recalculateTotalPrice.bind(this)}
                                                            />
                                                        })
                                                    ))}
                                                </div>
                                            )}
                                        />
                                        {typeof errors.products === 'string' ?
                                            <ErrorMessage name="products"
                                                          render={msg => <div
                                                              className="error">{t(msg, {orderLimit: user.orderlimit})}</div>}/> : ''}

                                    </div>
                                    <div className="container">
                                        <div>
                                            {t('cart.table.total')}
                                            <span id="totalPrice">
                                                {Math.round(totalPrice * 100) / 100}
                                            </span>
                                            {t('cart.table.currency_symbol')}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <Button className="btn default primary responsive-width m-10-0"
                                                    label={t('cart.button.order')}
                                                    isLoading={this.state.buttonIsLoading}
                                                    onClick={this.routeChange}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )}/>
                        :
                        <h1 className="container-fluid  text-center"><b>
                            <div>{t('cart.nonexistent')}</div>
                        </b></h1>
                }
            </div>
        );
    }
}

export default connect(
    (state) => {
        const {user} = state.profile;
        return {
            user
        }
    })(withTranslation('business')(cart));
