import React from 'react';
import '../style.scss';
import { withTranslation } from 'react-i18next';
import Alert from 'CommonUI/Alert';
import Button from 'CommonUI/Button';
import Modal from 'CommonUI/Modal';
import ValidationForm from './ValidationForm';

class Validation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    render() {
        const { t, products, orderId, fetchOrder } = this.props;
        const { showModal } = this.state;
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <Alert type="warning" message={t("actions.validate.success")} />
                    <Button
                        className="orders-back-btn primary btn responsive-width"
                        label={t('insert_products_quantity')}
                        onClick={this.toggleModal}
                    />
                    <Modal
                        className="validation-order-modal"
                        showModal={this.state.showModal}
                        title={t('received_quantities')}
                        children={<ValidationForm
                            products={products}
                            orderId={orderId}
                            closeModal={this.toggleModal}
                            fetchOrder={fetchOrder}
                            showModal={showModal}
                        />}
                        onClose={this.toggleModal}
                    />
                </div>

            </div>
        );
    }
}

export default withTranslation('orders')(Validation);
