import React from 'react';
import { NavLink } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { TextInput, Checkbox } from 'CommonUI/FormFields';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import 'CommonUI/FormFields/FormField/style.scss';

import Button from 'CommonUI/Button';
import { connect } from 'react-redux';
import { authActions } from '../_store/actions';
import { notificationConstants } from 'CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';

const SiginSchema = Yup.object().shape({
    email: Yup.string()
        .email('layout:form.error.invalid_email')
        .required('layout:form.error.required'),
    password: Yup.string()
        .required('layout:form.error.required')
});

const SiginForm = ({t, dispatch}) => {

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                rememberMe: false
            }}
            validationSchema={SiginSchema}

            onSubmit={(values, {resetForm, setErrors, setSubmitting}) => {
                const {email, password, rememberMe} = values;
                if (email && password) {
                    dispatch(authActions.login(email, password, rememberMe))
                        .then(
                            (response) => {
                                if (response) {
                                    if (response.type === notificationConstants.ERROR_RESPONSE) {
                                        dispatch(notificationActions.add(response, t('notification.fail.unknown_error')));
                                    }
                                }
                            }
                        );
                    setSubmitting(false);
                }
            }}
        >
            {({errors, touched, isSubmitting}) => (
                <Form className="form form-group">
                    <TextInput
                        type="email"
                        name="email"
                        error={errors.email ? t(errors.email) : ''}
                        touched={touched.email}
                        placeholder={t('common.email')}
                    />

                    <TextInput
                        type="password"
                        name="password"
                        error={errors.password ? t(errors.password) : ''}
                        touched={touched.password}
                        placeholder={t('common.password')}
                    />

                    <div className="row">
                        <div className="col-md-6">
                            <Checkbox
                                name="rememberMe"
                                label={t('signin.remember_me')}
                            />
                        </div>
                        <div className="col-md-6 text-right">
                            <NavLink to='/auth/mot-de-passe-oubile'>{t('signin.forgot_password')}</NavLink>
                        </div>
                    </div>

                    <Button className="primary round" isLoading={isSubmitting} label={t('signin.submit')}
                            type="submit"/>
                </Form>
            )}
        </Formik>
    );
};

export default connect()(withTranslation('auth')(SiginForm));
