import React from 'react';
import { Chooser } from 'CommonUI/FormFields';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Button from 'CommonUI/Button';
import _ from 'lodash';
import { notificationConstants } from 'CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import Progress from 'react-progress-2';
import CustomDateRange from 'CommonUI/CustomDateRange';
import { businessServices } from '../../_store/services';
import { businessConstants } from '../../_store/constants';
import { getBusinessesLocalisations, getBusinessesNames } from '../../_store/helper';

class Filters extends React.Component {

    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            filter: {
                name: { id: 0, name: t('layout:all') },
                localisation: { id: 0, name: t('layout:all') },
                startDate: null,
                endDate: null,
            },
            showDateRange: false
        }
    }

    handleChooserChange = (name, option) => {
        const { filter } = this.state;

        this.setState({
            filter: {
                ...filter,
                [name]: option,
            },
        });
    };

    selectDate = (startDate, endDate) => {
        let filter = this.state.filter;
        filter.startDate = startDate;
        filter.endDate = endDate;
        this.setState({ filter });
    };

    handleSubmit = (values, { setSubmitting }) => {
        this.setState({
            showDateRange: false,
        });
        const { dispatch } = this.props;
        let filters = this.createFilters(values);

        Progress.show();
        const filtersObj = {
            filter: filters
        };

        this.props.filtersUpdated(filtersObj);

        businessServices.getList(filtersObj)
            .then((response) => {
                if (response.type === notificationConstants.SUCCESS_RESPONSE) {
                    let list = response.result;
                    if (list) {
                        dispatch({type: businessConstants.SET_LIST, list});
                    }
                    if (_.isEmpty(filters)) {
                        const names = getBusinessesNames(list);
                        const localisations = getBusinessesLocalisations(list);
                        dispatch({type: businessConstants.SET_FILTER_NAMES, names});
                        dispatch({type: businessConstants.SET_FILTER_LOCALISATIONS, localisations});
                    }
                    dispatch({type: businessConstants.SET_LIST_IS_FILTERED, filtered: !_.isEmpty(filters)});
                } else {
                    dispatch(notificationActions.add(response));
                }
            })
            .finally(() => {
                setSubmitting(false);
                Progress.hide();
                // don't hide filter and don't clear the inputs
                // toggleFilter();
            });
    };

    updateDateChange = (showDateRange) => {
        this.setState({
            showDateRange,
        });
    }

    createFilters() {
        const filter = {};
        const { filter: { name, localisation, startDate, endDate } } = this.state;

        if (name.id !== 0) {
            filter.name = name.name;
        }

        if (localisation.id !== 0) {
            filter.localisation = localisation.name;
        }

        if (startDate) {
            filter.start_date = startDate.split('/').join('-');
        }

        if (endDate) {
            filter.end_date = endDate.split('/').join('-');
        }

        return filter;
    }


    render() {
        const { t, filterNames, filterLocalisations } = this.props;
        const { filter: { name, localisation, startDate, endDate }, showDateRange } = this.state;

        const namesOptions = [{ id: 0, name: t('layout:all') }, ...filterNames];
        const localisationOptions = [{ id: 0, name: t('layout:all') }, ...filterLocalisations];

        return (
            <Formik
                onSubmit={this.handleSubmit}
                enableReinitialize={true}
                initialValues={{name, localisation, startDate, endDate}}
                render={({ errors, touched, isSubmitting }) => (
                    <Form className="row">
                        <div className="col-md-3 filters__field responsive-width">
                            <Chooser
                                name="name"
                                label={t('filters.name')}
                                options={namesOptions}
                                value={name}
                                onChange={(option) => this.handleChooserChange('name', option)}
                                getOptionLabel={({name}) => name}
                                getOptionValue={({id}) => id}
                                error={errors.name}
                                touched={touched.name}
                            />
                        </div>

                        <div className="col-md-4 filters__field responsive-width">
                            <CustomDateRange
                                showDateRangeHandler={this.updateDateChange}
                                showDateRange={showDateRange}
                                onChange={this.selectDate}
                                label={t('filters.date')}
                                placeholder={t('layout:placeholder.date_range')}
                            />
                        </div>

                        <div className="col-md-3 filters__field responsive-width">
                            <Chooser
                                name="localisation"
                                label={t('filters.location')}
                                options={localisationOptions}
                                value={localisation}
                                onChange={(option) => this.handleChooserChange('localisation', option)}
                                getOptionLabel={({name}) => name}
                                getOptionValue={({id}) => id}
                                error={errors.localisation}
                                touched={touched.localisation}
                            />
                        </div>

                        <div className="col-md-2 filters__actions responsive-width">
                            <Button
                                className="block"
                                isLoading={isSubmitting}
                                label={t('save_business')}
                                type="submit"
                            />
                        </div>
                    </Form>
                )}
            />
        );
    }
}

export default connect((state) => {
    const { openedFilters, filterNames, filterLocalisations } = state.business;

    return {
        openedFilters,
        filterNames,
        filterLocalisations,
    };
})(withTranslation('business')(Filters));


