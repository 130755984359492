import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Filters from './Filters';
import Entries from './Entries';
import Button from 'CommonUI/Button';
import { PageHead, PageHeadTitle, PageHeadActions, PageHeadFilters } from 'CommonUI/PageHead';
import CreateBusinessForm from './CreateBusinessForm';
import Modal from 'CommonUI/Modal';
import { Screen } from '../../_constants';
import { ResponsiveContext } from '../../_store/responsive-context';
import { BusinessStatus } from '../_store/constants';
import { businessActions } from '../_store/actions';

class List extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showFilters: false,
            showModal: false,
            list: [],
            status: BusinessStatus.ACTIVE,
            filters: {}
        };
    }

    toggleModal = () => {
        this.setState({ showModal: !this.state.showModal });
    };

    toggleFilters = () => {
        this.setState({ showFilters: !this.state.showFilters });
    };

    componentDidMount() {
        this.setState({
            list: this.props.list,
        });
    }

    toggleStatus = () => {
        this.setState({
            status: this.state.status === BusinessStatus.ACTIVE ? BusinessStatus.CLOSED : BusinessStatus.ACTIVE,
        });
    }

    handleStatusChange = () => {
        this.props.dispatch(businessActions.getList(this.state.filters));
    };

    handleFiltersUpdated = (value) => {
        this.setState({
            filters: value,
        });
    }

    render() {
        const { t } = this.props;
        const { showFilters, showModal, status } = this.state;
        const formData = {
            name: '',
            localisation: '',
            illustrationPath: '',
            startDate: '',
            country: '',
            city: '',
            addressTitle: '',
            address: '',
            postalCode: '',
            additionalInfo: '',
            secondCountry: '',
            secondCity: '',
            secondAddressTitle: '',
            secondAddress: '',
            secondPostalCode: '',
            secondAdditionalInfo: '',
        };

        return (
            <ResponsiveContext.Consumer>
                {({size}) => (
                    <div className="page page--business">
                        <PageHead>
                            <PageHeadTitle>
                                <h2>{t('main_title')}</h2>
                                <p>Veuillez selectionner/creer une affaire pour acceder au catalogue et commander des produits.</p>
                            </PageHeadTitle>
                            <PageHeadActions>
                                <p>
                                    {(size >= Screen.md) && <Button
                                        className="primary round"
                                        label={status === BusinessStatus.ACTIVE ? t('status.show_closed') : t('status.hide_closed')}
                                        onClick={this.toggleStatus}
                                    />}
                                    {(size < Screen.md) && <Button
                                        className="primary round"
                                        icon={status === BusinessStatus.ACTIVE ? 'cross' : 'preview'}
                                        onClick={this.toggleStatus}
                                    />}
                                    &nbsp;
                                    {(size >= Screen.md) && <Button
                                        className="primary round"
                                        label={t('new_business')}
                                        onClick={this.toggleModal}
                                    />}
                                    {(size < Screen.md) && <Button
                                        className="primary round"
                                        label="+"
                                        onClick={this.toggleModal}
                                    />}
                                </p>
                                <Button
                                    className="link"
                                    label={t('filters.title')}
                                    onClick={this.toggleFilters}
                                    icon={`chevron-${showFilters ? 'up' : 'down'}`}
                                />
                            </PageHeadActions>

                            <PageHeadFilters isOpened={showFilters}>
                                <Filters filtersUpdated={this.handleFiltersUpdated} toggleFilter={this.toggleFilters} />
                            </PageHeadFilters>
                        </PageHead>

                        <div className="container">
                            <Entries filterStatus={status} forceRefresh={this.handleStatusChange} />
                        </div>

                        <Modal
                            showModal={showModal}
                            overflow={true}
                            title={this.props.t('new_business_title')}
                            children={<CreateBusinessForm businessData={formData} closeModal={this.toggleModal} />}
                            onClose={() => this.toggleModal(formData)}
                        />
                    </div>
                )}
            </ResponsiveContext.Consumer>
        );
    }
}

export default connect()(withTranslation('business')(List));
