import React from 'react';
import './style.scss';
import 'react-progress-2/main.css';
import Progress from 'react-progress-2';
import Notification from '../CommonUI/Notification';
import { ResponsiveContext } from '../_store/responsive-context';

const App = ({ children }) => {
    const [size, setSize] = React.useState(window.outerWidth);

    React.useEffect(() => {
        function handleResize() {
            setSize(window.outerWidth);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    });

    return (
        <ResponsiveContext.Provider value={{size, setSize}}>
            <div className="App">
                <Progress.Component/>
                <Notification className="col-md-4" />
                {children}
            </div>
        </ResponsiveContext.Provider>
    );
};

export default App;